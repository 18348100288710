<template>
  <button :class="{ [`btn-${size}`]: size, [ClassName]: ClassName, [cursor]: cursor }"
    class="border border-primary border-solid bg-transparent text-text-primary-light dark:text-text-primary-dark transition-all hover:shadow-sm dark:hover:shadow-darkSm flex items-center justify-center gap-[8px] hover:bg-background-main-light dark:hover:bg-background-main-dark">
    <span>
      <slot />
    </span>

    <slot name="icon" />
  </button>
</template>

<script>
import { computed } from "vue";


export default {
  name: "ButtonTertiary",
  props: ["size", "class", "cursor"],
  setup(props) {
    const ClassName = computed(() => props.class);

    return {
      ClassName
    }
  }
};
</script>
