import { api, authApi } from "@/service/Config";

const prefix = "/clan-wars";
const matchPrefix = `${prefix}/matches`;

export const getUserClanWarMatchesApi = async () => {
  return authApi.get(`${prefix}`).then((res) => res.data);
};

export const getUserClanWarMatcheHistoriesApi = async (page = 1) => {
  return authApi
    .get(`${prefix}/histories`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const createClanWarApi = async (data) => {
  return authApi.post(`${prefix}`, data).then((res) => res.data);
};

export const joinClanWarApi = async (clanWarId, data) => {
  return authApi
    .post(`${prefix}/${clanWarId}/join`, data)
    .then((res) => res.data);
};

export const getClanWarApi = async (clanWarId) => {
  return authApi.get(`${prefix}/${clanWarId}/detail`).then((res) => res.data);
};

export const acceptClanWarDirectChallengeApi = async (clanWarId) => {
  return authApi
    .patch(`${prefix}/${clanWarId}/direct-challenge/accept`)
    .then((res) => res.data);
};

export const rejectClanWarDirectChallengeApi = async (clanWarId) => {
  return authApi
    .patch(`${prefix}/${clanWarId}/direct-challenge/reject`)
    .then((res) => res.data);
};

export const getClanWarMatchApi = async (clanWarMatchId) => {
  return authApi.get(`${prefix}/${clanWarMatchId}`).then((res) => res.data);
};

export const readyClanWarMatchApi = async (clanWarMatchId) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/ready`)
    .then((res) => res.data);
};

export const forceMembersToBeReadyClanWarMatchApi = async (
  clanWarMatchId,
  data
) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/force-members-to-be-ready`, data)
    .then((res) => res.data);
};

export const extraTimeRequestApi = async (clanWarMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/extra-time-request`, data)
    .then((res) => res.data);
};

export const scoreEnterApi = async (clanWarMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/score/enter`, data)
    .then((res) => res.data);
};

export const scoreApproveApi = async (clanWarMatchId, data) => {
  return authApi
    .patch(`${matchPrefix}/${clanWarMatchId}/score/approve`, data)
    .then((res) => res.data);
};

export const scoreResubmitApi = async (clanWarMatchId, data) => {
  return authApi
    .patch(`${matchPrefix}/${clanWarMatchId}/score/resubmit`, data)
    .then((res) => res.data);
};

export const callDisputeApi = async (clanWarMatchId, data) => {
  return authApi
    .patch(`${matchPrefix}/${clanWarMatchId}/score/dispute-call`, data)
    .then((res) => res.data);
};

export const scoreDisputeApi = async (clanWarMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/score/dispute`, data)
    .then((res) => res.data);
};

export const createForfeitApi = async (clanWarMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/create-forfeit`, data)
    .then((res) => res.data);
};

export const submitForfeitApi = async (clanWarMatchId, forfeitId, data) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/forfeit/${forfeitId}/submit`, data)
    .then((res) => res.data);
};

export const createCancelMatchApi = async (clanWarMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/create-cancel-match`, data)
    .then((res) => res.data);
};

export const submitCancelMatchOpinionApi = async (clanWarMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${clanWarMatchId}/submit-cancel-match-opinion`, data)
    .then((res) => res.data);
};
