<template>
  <div :class="{
    [`${classColor}`]: classColor,
    'h-[35px]': size == 'sm'
  }" :id="name"
    class="relative text-text-primary-light dark:text-text-primary-dark bg-transparent rounded border-text-primary-light dark:border-text-primary-dark border-[1px]">
    <div class="flex items-center">
      <input :tabindex="tabIndex" :id="id" :type="showPass ? 'text' : type" :disabled="disabled" :class="{
        [`${placeholderColor}`]: placeholderColor,
        'cursor-not-allowed opacity-30': disabled,
        '!p-[5px]  !pt-[2px]': size == 'sm',
      }
        "
        class="block p-[12px] pt-4 w-full appearance-none bg-transparent focus:outline-none focus:ring-0 focus:!border-Btn-blue peer text-lg "
        :placeholder="placeholder" :name="placeholder" :value="modelValue" @keypress="checkInteger($event)"
        @focusin="inputFocusIn" @focusout="checkValidation()" @input="sendData($event)" />
      <label :for="id" :class="labelClass" v-if="showLabel"
        class="text-lg cursor-text select-none absolute text-text-tertiary-light dark:text-text-tertiary-dark transform -translate-y-4 scale-75 top-1 z-[1] origin-[0] px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 transition-all">
        {{ placeholder }}
      </label>
      <transition name="fade">
        <label :class="{
          '!block': (showErrorInInput && customShowError && hasError) || apiErrors,
          [labelClass]: labelClass
        }" :for="id" class="hidden transition-all text-sm cursor-text select-none absolute text-alert z-[1] px-2
      -bottom-2 right-2">
          {{ apiErrors ?? errors[0] }}
        </label>
      </transition>
      <button type="button" @click="showPass = !showPass" v-if="type === 'password'" :class="{
        '!px-1': size == 'sm'
      }
        " class="px-[20px] text-xl grid place-items-center">
        <i v-if="!showPass" class="icon-Eye-Stroke"></i>
        <i v-else class="icon-Hide-Eye-Stroke"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, onMounted } from "vue";
import { v4 as uuid } from "uuid";
import { toast } from "@/utilities/Toaster";
import { filled, isInteger, inputsLang } from "@/helper";
import { useStore } from "vuex";

export default {
  name: "inputForm",
  props: {
    //sm
    placeholderColor: {
      default: 'placeholder:text-transparent'
    },
    size: {},
    tabIndex: {},
    labelClass: {
      default: "bg-background-white-light dark:bg-background-white-dark ",
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
    },
    dynamic: {
      default: false,
    },
    type: {
      type: String,
      validator(value) {
        return ["text", "email", "number", "password", "tel"].includes(value);
      },
    },
    validation: {
      type: String,
      validator(value) {
        return !value
          .split("|")
          .some(
            (r) => !["required", "nullable", "email", "confirmed", "limited"].includes(r)
          );
      },
    },
    modelValue: {
      required: false,
    },
    password: {
      type: String,
      required: false,
    },
    disabled: {
      default: false,
    },
    showError: {
      default: false
    },
    showLabel: {
      default: true
    },
    showErrorInInput: {
      default: false
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    }
  },
  emits: [
    "update:modelValue",
    "hasError",
    "updateData",
    'changeShowError'
  ],
  setup(props, { emit }) {

    const store = useStore();
    const id = ref(uuid());
    const isDisabled = computed(() => props.disabled);
    const hasError = ref(false);
    const showPass = ref(false);
    const focus = ref(false);
    const classColor = ref();
    const errors = ref([]);
    const customValue = computed(() => props.modelValue);
    const apiErrors = computed(() => {
      return store.state.Common.apiErrors?.[props.name] ? typeof (store.state.Common.apiErrors?.[props.name]) == 'object' ? store.state.Common.apiErrors?.[props.name]?.[0] : store.state.Common.apiErrors?.[props.name] : null
    });
    const customShowError = ref(false);

    onMounted(() => {
      if (!isDisabled.value) {
        checkValidation(true);
      }
    });

    watch(customValue, () => {
      if (!isDisabled.value) {
        checkValidation(false);
      }
    });
    watch(apiErrors, () => {
      if (filled(apiErrors.value)) {
        classColor.value = "changeColorWhenInvalid";
      } else {
        classColor.value = "";

      }
    });

    watch(() => props.showError, () => {
      if (props.showError && hasError.value) {
        customShowError.value = true;
        emit("changeShowError", false);
        classColor.value = "changeColorWhenInvalid";
        if (!props.showErrorInInput) {
          errors.value.forEach(msg => toast(msg, 'error'));
        }
      }
    });


    const inputFocusIn = () => {
      customShowError.value = false;
      classColor.value = 'changeColorWhenActive';
      if (props.name) store.dispatch('removeApiErrorsItems', props.name)
    }

    const checkInteger = (e) => {
      inputsLang(e);
      if (props.type == "tel") {
        isInteger(e);
      }
    };

    const checkValidation = (showError, e) => {
      props.validation?.split("|").forEach((v) => {
        if (v === "required") {
          checkNotEmpty(showError);
        }
        if (v == "email") {
          checkIsEmailFormat(showError);
        }
        if (v === "confirmed") {
          checkPasswordConfirmed(showError);
        }
        if (v === "limited") {
          checkLimited(showError);
        }
        if (v === "nullable") {
          return;
        }
      });
    };

    const checkNotEmpty = (showError) => {
      return changeClassName(
        filled(props.modelValue),
        " is required",
        showError

      );
    };

    const checkIsEmailFormat = (showError) => {
      if (filled(props.modelValue)) {
        return changeClassName(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(props.modelValue),
          " format is not correct",
          showError
        );
      }
    };

    const checkPassword = (showError) => {
      return changeClassName(
        !/^\s|\t/.test(props.modelValue),
        "",
        showError
      );
    };

    const checkPasswordConfirmed = (showError) => {
      return changeClassName(
        filled(props.modelValue) &&
        props.password === props.modelValue,
        " is not same as password",
        showError
      );
    };

    const checkLimited = (showError) => {
      return changeClassName(
        !(
          (props.min && props.modelValue?.length < props.min) ||
          (props.max && props.modelValue?.length > props.max)
        ),
        ` must be between ${props.min} to ${props.max} character`,
        showError
      );

    };

    const changeClassName = (value, msg, showError = true) => {
      if (value) {
        hasError.value = false;
        if (showError) {
          classColor.value = "";
        }
        emit("hasError", { id: id.value, value: false });
      } else {
        if (props.modelValue) {
        }
        if (showError) {
          hasError.value = true;
          var camelPlaceholder =
            props.placeholder.charAt(0).toUpperCase() +
            props.placeholder.slice(1);

          errors.value = [];
          errors.value.push(camelPlaceholder + msg)
        }
        emit("hasError", { id: id.value, value: true });
      }
    };

    const sendData = (e) => {
      if (props.dynamic) {
        emit("updateData", {
          name: props.name,
          value: e.target.value,
        });
      } else {
        emit("update:modelValue", e.target.value);
      }
    };


    return {
      apiErrors,
      id,
      customShowError,
      isDisabled,
      hasError,
      showPass,
      focus,
      classColor,
      errors,
      checkInteger,
      sendData,
      checkValidation,
      inputFocusIn
    };
  },
};
</script>

<style lang="scss">
.changeColorWhenActive {
  @apply border-primary #{!important};
}

.changeColorWhenInvalid {
  @apply border-alert #{!important};
}

.changeColorWhenValid {
  @apply border-success #{!important};
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
