import { api, authApi } from "@/service/Config";

const prefix = "/battles";

export const getUserBattleMatchesApi = async () => {
  return authApi.get(`${prefix}`).then((res) => res.data);
};

export const createBattleMatchApi = async (data) => {
  return authApi.post(`${prefix}`, data).then((res) => res.data);
};

export const getBattleJoinRequestsApi = async (battleId, page = 1) => {
  return authApi
    .get(`${prefix}/${battleId}/join-request-notifications`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const getBattleDirectChallengeRequestApi = async (battleId) => {
  return authApi
    .get(`${prefix}/${battleId}/direct-challenge-notification`)
    .then((res) => res.data);
};

export const getBattleMatchApi = async (battleMatchId) => {
  return authApi.get(`${prefix}/${battleMatchId}`).then((res) => res.data);
};

export const joinBattleApi = async (battleId, data) => {
  return authApi
    .post(`${prefix}/${battleId}/join`, data)
    .then((res) => res.data);
};

export const acceptBattleApi = async (battleId, matchRequestId) => {
  return authApi
    .patch(`${prefix}/${battleId}/requests/${matchRequestId}/accept`)
    .then((res) => res.data);
};

export const acceptBattleInviteApi = async (battleId) => {
  return authApi
    .post(`${prefix}/${battleId}/accept-invitation`)
    .then((res) => res.data);
};

export const rejectBattleApi = async (battleId, matchRequestId) => {
  return authApi
    .patch(`${prefix}/${battleId}/requests/${matchRequestId}/reject`)
    .then((res) => res.data);
};

export const acceptBattleDirectChallengeApi = async (battleId) => {
  return authApi
    .patch(`${prefix}/${battleId}/direct-challenge/accept`)
    .then((res) => res.data);
};

export const rejectBattleDirectChallengeApi = async (battleId) => {
  return authApi
    .patch(`${prefix}/${battleId}/direct-challenge/reject`)
    .then((res) => res.data);
};

export const cancelBattleRequestApi = async (battleId, matchRequestId) => {
  return authApi
    .patch(`${prefix}/${battleId}/requests/${matchRequestId}/cancel`)
    .then((res) => res.data);
};

export const readyBattleApi = async (battleId, battleMatchId) => {
  return authApi
    .patch(`${prefix}/${battleId}/members/${battleMatchId}/ready`)
    .then((res) => res.data);
};

export const extraTimeRequestApi = async (battleMatchId) => {
  return authApi
    .post(`${prefix}/matches/${battleMatchId}/extra-time-request`)
    .then((res) => res.data);
};

export const scoreEnterApi = async (battleMatchId, data) => {
  return authApi
    .post(`${prefix}/matches/${battleMatchId}/score/enter`, data)
    .then((res) => res.data);
};

export const scoreApproveApi = async (battleMatchId) => {
  return authApi
    .patch(`${prefix}/matches/${battleMatchId}/score/approve`)
    .then((res) => res.data);
};

export const scoreResubmitApi = async (battleMatchId, data) => {
  return authApi
    .patch(`${prefix}/matches/${battleMatchId}/score/resubmit`, data)
    .then((res) => res.data);
};

export const callDisputeApi = async (battleMatchId, data) => {
  return authApi
    .patch(`${prefix}/matches/${battleMatchId}/score/dispute-call`, data)
    .then((res) => res.data);
};

export const scoreDisputeApi = async (battleMatchId, data) => {
  return authApi
    .post(`${prefix}/matches/${battleMatchId}/score/dispute`, data)
    .then((res) => res.data);
};

export const compeleteDisputeApi = async (battleMatchId, data) => {
  return authApi
    .post(`${prefix}/matches/${battleMatchId}/complete/dispute`, data)
    .then((res) => res.data);
};

export const createForfeitApi = async (battleMatchId) => {
  return authApi
    .post(`${prefix}/matches/${battleMatchId}/create-forfeit`)
    .then((res) => res.data);
};

export const createCancelMatchApi = async (battleMatchId) => {
  return authApi
    .post(`${prefix}/matches/${battleMatchId}/create-cancel-match`)
    .then((res) => res.data);
};

export const submitCancelMatchOpinionApi = async (battleMatchId, data) => {
  return authApi
    .post(
      `${prefix}/matches/${battleMatchId}/submit-cancel-match-opinion`,
      data
    )
    .then((res) => res.data);
};
