import { api, authApi } from "@/service/Config";

const prefix = "tournaments";
const matchPrefix = `${prefix}/matches`;

export const getUserTournamentOrganizerApi = async () => {
  return authApi.get(`${prefix}/organized-by-user`).then((res) => res.data);
};

export const getUserTournamentMatchesApi = async () => {
  return authApi.get(`${prefix}`).then((res) => res.data);
};

export const getUserTournamentMatcheHistoriesApi = async (page = 1) => {
  return authApi
    .get(`${prefix}/histories`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const createTournamentApi = async (unit, data) => {
  return authApi.post(`${prefix}/${unit}`, data).then((res) => res.data);
};

export const joinTournamentApi = async (tournamentId, data) => {
  return authApi
    .post(`${prefix}/${tournamentId}/join`, data)
    .then((res) => res.data);
};
export const cancelJoinoinTournamentApi = async (tournamentId, data) => {
  return authApi
    .post(`${prefix}/${tournamentId}/cancel-join`, data)
    .then((res) => res.data);
};

export const tournamentChartApi = async (tournamentId) => {
  return authApi.get(`${prefix}/chart/${tournamentId}`).then((res) => res.data);
};

export const tournamentDetailApi = async (tournamentId) => {
  return authApi.get(`${prefix}/detail/${tournamentId}`).then((res) => res.data);
};

export const getUserTournamentDetailApi = async (tournamentId) => {
  return authApi.get(`${prefix}/${tournamentId}/user-matches`).then((res) => res.data);
};

export const getTournamentMatchApi = async (tournamentMatchId) => {
  return authApi
    .get(`${matchPrefix}/${tournamentMatchId}`)
    .then((res) => res.data);
};

export const readyApi = async (tournamentMatchId) => {
  return authApi
    .patch(`${matchPrefix}/members/${tournamentMatchId}/ready`)
    .then((res) => res.data);
};
export const extraTimeRequestApi = async (tournamentMatchId) => {
  return authApi
    .post(`${matchPrefix}/${tournamentMatchId}/extra-time-request`)
    .then((res) => res.data);
};

export const scoreEnterApi = async (tournamentMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${tournamentMatchId}/score/enter`, data)
    .then((res) => res.data);
};

export const scoreApproveApi = async (tournamentMatchId) => {
  return authApi
    .patch(`${matchPrefix}/${tournamentMatchId}/score/approve`)
    .then((res) => res.data);
};

export const scoreResubmitApi = async (tournamentMatchId, data) => {
  return authApi
    .patch(`${matchPrefix}/${tournamentMatchId}/score/resubmit`, data)
    .then((res) => res.data);
};

export const callDisputeApi = async (tournamentMatchId, data) => {
  return authApi
    .patch(`${matchPrefix}/${tournamentMatchId}/score/dispute-call`, data)
    .then((res) => res.data);
};

export const scoreDisputeApi = async (tournamentMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${tournamentMatchId}/score/dispute`, data)
    .then((res) => res.data);
};

export const compeleteDisputeApi = async (tournamentMatchId, data) => {
  return authApi
    .post(`${matchPrefix}/${tournamentMatchId}/complete/dispute`, data)
    .then((res) => res.data);
};

export const createForfeitApi = async (tournamentMatchId) => {
  return authApi
    .post(`${matchPrefix}/${tournamentMatchId}/create-forfeit`)
    .then((res) => res.data);
};
