<template>
  <Teleport to="body">
    <VueFinalModal class="text-text-white-dark dark:text-text-white-light" name="Register" v-model="showRegister">
      <ModalLayout modalName="Register" :has-btn-on-bottom="true" class="!z-[16]">
        <template #title>
          Welcome to GameClude
        </template>
        <div v-if="loading" class="h-[331px] grid items-center">
          <div class="w-[70px] h-[100px] ml-[20%]">
            <PacManLoading />
          </div>
        </div>
        <div v-show="!loading">
          <div class="mb-[20px]">
            <span class="text-text-secondary-light dark:text-text-secondary-dark block text-sm md:text-md">
              Build your gaming career by playing E-sport games
            </span>
          </div>

          <div class="flex flex-col gap-[12px]">
            <LoginWithGoogle @set-loading="(data) => loading = data" title="Google Signup" type="REGISTER" />

            <!--           <ButtonTertiary size="md" class="w-full !bg-background-main-light dark:!bg-background-main-dark border border-tertiary-light dark:border-tertiary-dark" :disabled="!data.policy_accepted">-->
            <!--            <span class="flex items-center justify-center gap-[20px] sm:text-xl">-->
            <!--              <i class="icon-Apple text-[24px]"></i>-->
            <!--              Apple Signup-->
            <!--            </span>-->
            <!--          </ButtonTertiary>-->
            <!--or-->
            <div class="flex items-center justify-center relative">
              <span class="absolute left-0 right-0 h-[1px] bg-border-light dark:bg-border-dark"></span>
              <span
                class="block relative z-[1] w-fit px-3 bg-background-white-light dark:bg-background-white-dark text-text-secondary-light dark:text-text-secondary-dark text-sm">Or</span>
            </div>

            <!--  -->

            <!--  -->
            <form class="flex flex-col gap-[12px]" @submit.prevent="doRegister">
              <Input placeholder="email" :validation="'required|email'" v-model="data.email" name="email"
                :show-error="showError" @has-error="(data) => storeError(data)"
                @change-show-error="(data) => { if (showError !== data) showError = data }" :tab-index="1"
                :showErrorInInput="true" />

              <Input placeholder="password" :validation="'required'" type="password" v-model="data.password"
                name="password" :show-error="showError" @has-error="(data) => storeError(data)"
                @change-show-error="(data) => { if (showError !== data) showError = data }" :tab-index="2"
                :showErrorInInput="true" />

              <Input placeholder="repeat password" :validation="'confirmed'" type="password" :show-error="showError"
                v-model="data.password_confirmation" :password="data.password" @has-error="(data) => storeError(data)"
                @change-show-error="(data) => { if (showError !== data) showError = data }" :tab-index="3"
                :showErrorInInput="true" />

              <div class="text-text-secondary-light dark:text-text-secondary-dark font-[400] text-sm mt-[5px]">
                By signing up, you agree to the
                <router-link :to="{
                  name: 'service-terms'
                }" target="_blank" title="Terms of Service"
                  class="text-secondary border-b border-transparent hover:border-b-secondary transition-all">Terms of
                  Service
                </router-link>
                and
                <router-link :to="{
                  name: 'policy'
                }" target="_blank" title="Privacy Policy"
                  class="text-secondary border-b border-transparent hover:border-b-secondary transition-all">Privacy
                  Policy</router-link>
                , including
                <router-link :to="{
                  name: 'policy'
                }" target="_blank"
                  class="text-secondary border-b border-transparent hover:border-b-secondary transition-all"
                  title="Cookies">Cookies</router-link>
                .
              </div>
              <div
                class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
                <div class="max-sm:grid max-sm:grid-cols-2 gap-[8px] sm:flex sm:items-center sm:justify-end">
                  <ButtonTertiary size="sm" type="button"
                    class="sm:min-w-[68px] !bg-background-white-light !max-h-[37px] dark:!bg-background-white-dark border border-primary"
                    @click="$vfm.hide('Register')" tabindex="6">
                    Cancel
                  </ButtonTertiary>
                  <ButtonPrimary size="sm" class="sm:min-w-[68px]" :disabled="submitDisabled" tabindex="4">
                    Signup
                  </ButtonPrimary>
                </div>
              </div>
            </form>

          </div>
          <!-- =========== -->

          <button @click="() => {
            $vfm.hide('Register');
            $vfm.show('login');
          }
            " type="button"
            class="w-full mt-[20px] block text-center text-text-secondary-light dark:text-text-secondary-dark transition-all hover:!text-secondary text-sm">
            Already Have an Account?
          </button>
        </div>
      </ModalLayout>
    </VueFinalModal>
  </Teleport>
</template>

<script>
import { ref, computed } from "vue";
import ModalLayout from "@/components/modal/ModalLayout.vue";
import Input from "@/components/form-component/input.vue";
import SwitchForm from "@/components/form-component/switch.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import { $vfm, VueFinalModal, ModalsContainer } from "vue-final-modal";
// === icon
import GoogleIcon from "@/assets/icon-svg/google.svg";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import PacManLoading from "@/components/layout/common/utility/PacManLoading.vue";
import toaster from "@/utilities/Toaster";
import LoginWithGoogle from "./LoginWithGoogle.vue"
import { filled } from "@/helper";

export default {
  name: "RegisterModal",
  components: {
    ModalLayout,
    Input,
    ButtonPrimary,
    ButtonTertiary,
    VueFinalModal,
    PacManLoading,
    LoginWithGoogle
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const icons = ref({
      GoogleIcon,
    });
    const showRegister = ref(false);
    const loading = ref(false);
    const showLogin = ref(false);
    const showForgetPass = ref(false);
    const inputErrors = ref([]);
    const submitDisabled = ref(false);
    const refferaledToken = computed(() => store.state.User.refferaledToken);
    const data = ref({
      email: "",
      password: "",
      password_confirmation: "",
      policy_accepted: ref(true),
    });
    const showError = ref(false);


    const close = () => {
      $vfm.hide("login");
    };

    const storeError = (data) => {
      inputErrors.value = inputErrors.value.filter(err => err?.id !== data.id);
      inputErrors.value.push(data);
    };

    const checkError = () => {
      return inputErrors.value.filter(err => err.value === true).length !== 0;
    };

    const doRegister = async () => {
      if (checkError()) {
        return showError.value = true;
      }
      if (filled(refferaledToken.value)) {
        data.value.user_referral_number = refferaledToken.value
      }

      submitDisabled.value = true;
      loading.value = true;
      await store.dispatch("register", data.value);
      loading.value = false;
      submitDisabled.value = false;
    };

    const goToPolicies = () => {
      $vfm.hide("Register");
      router.push({
        name: "policy",
      });
    };

    return {
      icons,
      showRegister,
      loading,
      showLogin,
      showForgetPass,
      inputErrors,
      submitDisabled,
      data,
      showError,
      goToPolicies,
      close,
      storeError,
      doRegister,
    };
  },
};
</script>
