<template>
  <div class="grid place-items-center h-screen text-center">
    <div>
      <h1 class="text-[100px] text-alert">404</h1>
      <p class="text-xl text-alert">not found</p>
      <p class="text-md">
        <RouterLink to="/panel/dashboard" v-if="isAuthenticated">Redirect to dashboard</RouterLink>
        <RouterLink to="/" v-else>Redirect to home page</RouterLink>
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "NotFound",
  setup() {
    const store = useStore();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    return {
      isAuthenticated
    }
  }
};
</script>
