import { pluck } from "@/helper";
import { getUserTagsApi } from "@/service/api/UserTagApi";

const state = {
  apiErrors: null,
  userTagsServiceId: [],
  serviceIdForUserTag: null,
  tournamentUserId: null,
  newWindow: null,
};

const getters = {};

const actions = {
  async setApiErrors({ commit, dispatch }, data) {
    commit("_setApiErrors", data);
  },
  async removeApiErrorsItems({ commit, dispatch }, data) {
    commit("_removeApiErrorsItems", data);
  },
  async setUserTags({ commit, getters }) {
    if (getters.isAuthenticated) {
      const response = await getUserTagsApi();
      let serviceIds = pluck(response.data, "service_id");
      commit("_setUserTags", serviceIds);
    }
  },
  async pushUserTags({ commit, getters }, data) {
    if (getters.isAuthenticated) {
      commit("_pushUserTags", data);
    }
  },
  async setServiceIdForUserTag({ commit }, data) {
    commit("_setServiceIdForUserTag", data);
  },
  async setTournamentUser({ commit }, data) {
    commit("_setTournamentUser", data);
  },
  async setNewWindow({ commit }, data) {
    commit("_setNewWindow", data);
  },
};

const mutations = {
  _setApiErrors: (state, data) => {
    state.apiErrors = data;
  },
  _removeApiErrorsItems: (state, data) => {
    let error = state.apiErrors;
    delete error?.[data];
    state.apiErrors = error;
  },
  _setUserTags: (state, data) => {
    state.userTagsServiceId = data;
  },
  _pushUserTags: (state, data) => {
    state.userTagsServiceId.push(data.id);
  },
  _setServiceIdForUserTag: (state, data) => {
    state.serviceIdForUserTag = data;
  },
  _setTournamentUser: (state, data) => {
    state.tournamentUserId = data;
  },
  _setNewWindow: (state, data) => {
    state.newWindow = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
