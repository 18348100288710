<template>
  <button
    :class="{ [`btn-${size}`]: size, [ClassName]: ClassName, [cursor]: cursor }"
    class="bg-blurSuccess text-success transition-all  hover:shadow-sm flex items-center justify-center gap-[8px]"
  >
    <span>
      <slot />
    </span>

    <slot name="icon" />
  </button>
</template>

<script>
export default {
  name: "ButtonSuccess",
  props: ["size", "class", "cursor"],
  data() {
    return {
      ClassName: this.class,
    };
  },
};
</script>
