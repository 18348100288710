<template>
  <Teleport to="body">
    <VueFinalModal name="bannedModal" v-model="bannedModal">
      <ModalLayout modalName="bannedModal" :has-btn-on-bottom="true" class="!z-[18]">
        <template #title>
          <div class="flex items-center gap-2">
            <LimitAccessIcon light-color="#0c0e1a" dark-color="#fff" />
            <div class="text-md font-[500] text-text-primary-light dark:text-text-primary-dark">Limited access</div>
          </div>
        </template>
        <div class="text-md text-text-primary-light dark:text-text-primary-dark">Gameclude policy violations occurred.
        </div>
        <div
          class="bg-background-main-light dark:bg-background-main-dark rounded-[4px] border border-tertiary-light dark:border-tertiary-dark px-[12px] py-[8px] mt-[12px]">
          <div class="text-md text-text-primary-light dark:text-text-primary-dark">
            We regret to inform you that your access to Gameclude services has been limited as a result of our policy
            violation. You are now unable to:
          </div>
          <div class="flex items-center gap-2">
            <div class="w-[3px] min-w-[3px] h-[3px] rounded-full bg-text-primary-light dark:bg-text-primary-dark"></div>
            <div class="ext-md text-text-primary-light dark:text-text-primary-dark">Create/Join matches</div>
          </div>
          <div class="flex items-center gap-2">
            <div class="w-[3px] min-w-[3px] h-[3px] rounded-full bg-text-primary-light dark:bg-text-primary-dark"></div>
            <div class="ext-md text-text-primary-light dark:text-text-primary-dark">Create/Join tournaments</div>
          </div>
          <div class="flex items-center gap-2">
            <div class="w-[3px] min-w-[3px] h-[3px] rounded-full bg-text-primary-light dark:bg-text-primary-dark"></div>
            <div class="ext-md text-text-primary-light dark:text-text-primary-dark">Chat rooms</div>
          </div>
        </div>
        <div
          class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
          <ButtonTertiary @click="showOnlineSupport" size="sm" class="sm:ml-auto max-sm:!w-full suportChatBtn"
            span-class="suportChatBtn">
            Contact support
          </ButtonTertiary>
        </div>
      </ModalLayout>
    </VueFinalModal>
  </Teleport>
</template>

<script setup>
import { $vfm, VueFinalModal } from 'vue-final-modal'
import ModalLayout from "@/components/modal/ModalLayout.vue";
import { ref, nextTick } from "vue";
import { useStore } from "vuex";
import LimitAccessIcon from "@/components/Icons/LimitAccessIcon.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";

// data
const bannedModal = ref(false)
const store = useStore();


const showOnlineSupport = () => {
  $vfm.hideAll();
  store.dispatch('openChat', true);
};

</script>
