import PublicLayout from "@/components/layout/public/PublicLayout.vue";
import PanelLayout from "@/components/layout/panel/PanelLayout.vue";

const Home = async () => import("@/views/public/home/HomeView.vue");
const CluderProfile = async () =>
  import("@/views/public/PublicProfile/cluder/CluderProfile.vue");
const SquadProfile = async () =>
  import("@/views/public/PublicProfile/squad/SquadProfile");
const Arena1v1GameView = async () =>
  import("@/views/public/arena/Arena1v1GameView.vue");
const SquadGamesView = async () =>
  import("@/views/public/arena/SquadGamesView.vue");
const PolicyView = async () => import("@/views/public/auth/PolicyView.vue");
const TermService = async () => import("@/views/public/auth/TermService.vue");
const AboutUs = async () => import("@/views/public/auth/AboutUs.vue");
const LeaderboardPage = async () => import("@/views/public/LeaderboardPage");

const FAQ = async () => import("@/views/public/Faq");
// ===
const Blog = async () => import("@/views/public/blog");
const BlogDetail = async () => import("@/views/public/blog/Detail.vue");
const ResetPassword = async () =>
  import("@/views/public/auth/ResetPassword.vue");

export default [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      hideWhenAuthenticated: false,
      requiresAuth: false,
      layout: PublicLayout,
      seoName: "home",
      useStaticMetaData: true,
    },
  },
  {
    path: "/arena",
    meta: {
      hideWhenAuthenticated: false,
      requiresAuth: false,
      layout: PublicLayout,
      title: "Arena",
    },
    children: [
      {
        path: "1v1game",
        name: "Arena-1v1-game",
        component: Arena1v1GameView,
        meta: {
          seoName: "arena1v1Game",
          useStaticMetaData: true,
        },
      },
      {
        path: "squad-games",
        name: "arena-squad-games",
        component: SquadGamesView,
        meta: {
          seoName: "arenaSquadGame",
          useStaticMetaData: true,
        },
      },
    ],
  },
  {
    path: "/privacy-policies",
    name: "policy",
    component: PolicyView,
    meta: {
      hideWhenAuthenticated: false,
      requiresAuth: false,
      layout: PublicLayout,
      title: "Privacy policies",
      useStaticMetaData: true,
    },
  },
  {
    path: "/term-of-service",
    name: "service-terms",
    component: TermService,
    meta: {
      hideWhenAuthenticated: false,
      requiresAuth: false,
      layout: PublicLayout,
      title: "Term of service",
      useStaticMetaData: true,
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
    meta: {
      hideWhenAuthenticated: false,
      requiresAuth: false,
      layout: PublicLayout,
      title: "About us",
      useStaticMetaData: true,
    },
  },

  // public profile

  {
    path: "/profile/cluder/:id",
    name: "cluder-profile",
    component: CluderProfile,
    meta: {
      layout: PanelLayout,
      requiresAuth: false,
      seoName: "cluderProfile",
      useStaticMetaData: false,
    },
  },
  {
    path: "/profile/squad/:id",
    name: "squad-profile",
    component: SquadProfile,
    meta: {
      layout: PanelLayout,
      requiresAuth: false,
      seoName: "cluderProfile",
      useStaticMetaData: false,
    },
  },
  // ============
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
    meta: {
      layout: PublicLayout,
      requiresAuth: false,
      title: "Faq",
      useStaticMetaData: true,
    },
  },
  // ============
  {
    path: "/blogs",
    name: "blogs",
    component: Blog,
    meta: {
      layout: PublicLayout,
      requiresAuth: false,
      seoName: "blogs",
      useStaticMetaData: true,
    },
  },
  // ============
  {
    path: "/blogs/:slug",
    name: "blog-detail",
    component: BlogDetail,
    meta: {
      layout: PublicLayout,
      requiresAuth: false,
      seoName: "blog",
    },
  },
  {
    path: "/leaderboards",
    name: "leaderboard",
    component: LeaderboardPage,
    meta: {
      layout: PublicLayout,
      requiresAuth: false,
      seoName: "leaderboard",
      useStaticMetaData: true,
    },
  },
  {
    path: "/forget-password/:token",
    name: "forgetpassword",
    component: ResetPassword,
    meta: {
      layout: PublicLayout,
      requiresAuth: false,
      hideWhenAuthenticated: true,
    },
  },
];
