<template>
  <div
    :class="isOpen ? 'left-0 ' : '-left-full'"
    class="transition-all z-[13] fixed top-0 lg:hidden w-screen h-screen"
  >
    <div
      class="PanelSideBar w-[184px] h-screen overflow-y-auto pb-[20px] bg-background-white-light dark:bg-background-white-dark"
      v-click-outside="closeWheneFocusOut"
    >
      <button
        @click="close"
        class="transition-all hover:shadow-sm hover:text-secondary hover:bg-background-main-light dark:hover:bg-secondary ml-auto mb-[20px] grid place-items-center w-[40px] h-[40px] rounded-bl rounded-none bg-tertiary-light dark:bg-text-tertiary-dark"
      >
        <i class="icon-Close-Stroke text-[22px] dark:text-text-white-light"></i>
      </button>
      <div class="py-[8px] px-[10px] dark:text-text-primary-dark">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";

export default {
  name: "SidebarMoblie",
  props: ["open"],
  emits: ["onClose"],
  methods: {
    close() {
      this.$emit("onClose");
    },

    closeWheneFocusOut(e) {
      if (e?.target?.id !== "sidebarMobileIcon") {
        this.$emit("onClose");
      }
    },
  },
  setup(props) {
    const isOpen = computed(() => props.open);

    return {
      isOpen,
    };
  },
};
</script>
