<template>
  <div class="z-[22222] fixed bottom-[80px] md:bottom-[2rem] right-[1.5rem]">
    <div class="relative">
      <div class="bg-gradient-to-r from-[#1CAEFF] to-[#18FAB6] p-[2px] rounded">
        <button class="bg-secondary rounded group p-[8px] text-white suportChatBtn" @click="setShowChat(!showChat)">
          <div v-if="showChat" class="flex justify-center items-center gap-2 rounded suportChatBtn relative">
            <img class="w-[24px] h-[24px] suportChatBtn" src="@/assets/icon-svg/chat-support.svg" />
          </div>
          <div v-else class="flex justify-center items-center gap-2 rounded suportChatBtn relative">
            <img class="w-[20px] h-[20px] suportChatBtn" src="@/assets/icon-svg/chatIcon.svg" />
            <span v-if="unreadCount"
              class="absolute -top-[23%] left-[9%] grid place-items-center w-[16px] h-[16px] rounded bg-alert text-background-white-light text-[10px] font-bold">
              {{ unreadCountPrettified }}
            </span>
            <span class="hidden md:block suportChatBtn">Need help?</span>
          </div>
        </button>
      </div>
      <transition name="fade" mode="out-in">
        <div v-click-outside="onClose" v-if="showChat" id="suportChat" :class="{
          '!block': showChat
        }" class="transition-all max-lg:!fixed max-lg:!inset-0 max-lg:!w-[unset] max-lg:!h-[unset] absolute card-md rounded shadow-lg hidden
       group-hover:animate-fade  bottom-[-18px] md:bottom-[3rem] right-[-24px] md:right-0 !mb-0
       w-[100vw] md:w-[330px] h-[87vh] md:h-[500px] z-[22222] bg-gradient-to-r from-[#1CAEFF] to-[#18FAB6] !p-[2px]">
          <div
            class="bg-background-white-light dark:bg-background-white-dark h-full w-full px-[20px] py-[12px] rounded">
            <div class="flex items-center justify-between mb-[20px] ">
              <div class="flex items-center gap-[12px]">
                <button class="block md:hidden" @click="onClose">
                  <i class="icon-Arrow-Stroke text-2xl rotate-180 block" />
                </button>
                <i class="text-3xl icon-Chat-1 hidden md:block" />
                <span class="font-medium text-md">
                  Online support
                </span>
              </div>
            </div>
            <div class="w-full bg-orange rounded py-4 px-3 mb-2">
              <span class="text-[12px]">
                Welcome to Gameclude We’re here to assist almost 24/7 with Quick replies, usually within minutes! Got
                questions? Drop us a message! We’re here to assist.
              </span>
            </div>

            <Card size="md"
              class="!mb-[30px] md:!mb-0 flex flex-col gap-[8px] flex-grow overflow-y-auto max-h-[60vh] md:max-h-[280px] scrollbar-hide !p-0">
              <div ref="container"
                class="overflow-y-scroll max-h-full scrollbar-hide h-[70vh] md:!h-[262px] !mb-[30px] md:!mb-0"
                v-observe-visibility-top="loadMore">
                <Card size="md" class="flex flex-col gap-[8px] flex-grow h-full !px-0">
                  <div v-show="loading"
                    class="flex flex-col gap-[8px] flex-grow h-full justify-center items-center !pr-[60%]">
                    <PacManLoading />
                  </div>
                  <div v-show="!loading" class="flex flex-col gap-[8px]">
                    <transition name="fade" mode="out-in">
                      <div class="grid justify-center items-center" v-if="scrollLoading">
                        <SpinLoading />
                      </div>
                    </transition>

                    <div v-for="message in messages" :key="message.id" class="flex">
                      <div class="w-[10px] overflow-hidden inline-block relative">
                        <div
                          class="h-[50px] rotate-[55deg] transform origin-bottom-left absolute bottom-0 w-full left-[2px]"
                          :class="{
                            'bg-background-main-light dark:bg-background-main-dark':
                              message.message.sender !== 'user',
                            'bg-tertiary-light dark:bg-tertiary-dark':
                              message.message.sender == 'user',
                          }"></div>
                      </div>
                      <div class="w-11/12">
                        <div
                          class="mb-[4px] flex items-center justify-between text-[10px] text-text-secondary-light dark:text-text-secondary-dark">
                          <span v-show="message.message.sender == 'user'">You</span>
                          <div v-show="message.message.sender == 'supporter'" class="flex gap-2">
                            <img src="@/assets/icon-svg/admin.svg" class="w-[12px] h-[12px]" alt="x" />
                            <span>
                              {{ message.message.supporter_name }}
                            </span>
                          </div>
                          <span>{{ calculateTime(message.message?.created_at) }}</span>
                        </div>
                        <div class="px-[12px] py-[5px] rounded rounded-bl-none flex" :class="{
                          'bg-background-main-light dark:bg-background-main-dark':
                            message.message.sender !== 'user',
                          'bg-tertiary-light dark:bg-tertiary-dark':
                            message.message.sender == 'user',
                        }">
                          <div v-if="message.message.image_url" class="block border-2 border-solid border-background-white-light dark:border-background-white-dark object-cover rounded basis-[40px] h-[40px] bg-background-white-light
                          dark:bg-background-white-dark p-[2.5px] shrink-0 mr-1" :style="{
                            backgroundImage: `url('${message.message.image_url}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                          }">
                          </div>
                          <span class="break-words" v-show="message.message.sender == 'supporter'"
                            v-html="message.message?.message">
                          </span>
                          <span v-show="message.message.sender == 'user'">
                            {{ message.message?.message }}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <transition name="fade" mode="out-in">
                        <div class="flex flex-col gap-[8px]">
                          <div v-for="message in pushMessages" :key="message.id" class="flex">
                            <div class="w-[10px] overflow-hidden inline-block relative">
                              <div
                                class="h-[50px] rotate-[55deg] transform origin-bottom-left absolute bottom-0 w-full left-[2px]"
                                :class="{
                                  'bg-background-main-light dark:bg-background-main-dark':
                                    message.sender !== 'user',
                                  ' bg-tertiary-light dark:bg-tertiary-dark':
                                    message.sender == 'user',
                                }"></div>
                            </div>
                            <div class="w-11/12">
                              <div
                                class="mb-[4px] flex items-center justify-between text-[10px] text-text-secondary-light dark:text-text-secondary-dark">
                                <span v-show="message.sender == 'user'">You</span>
                                <div v-show="message.sender == 'supporter'" class="flex gap-2">
                                  <img src="@/assets/icon-svg/admin.svg" class="w-[12px] h-[12px]" alt="x" />
                                  <span>
                                    {{ message.supporter_name }}
                                  </span>
                                </div>
                                <span>{{ calculateTime(message?.created_at) }}</span>
                              </div>
                              <div class="px-[12px] py-[5px] rounded rounded-bl-none flex" :class="{
                                'bg-background-main-light dark:bg-background-main-dark':
                                  message.sender !== 'user',
                                ' bg-tertiary-light dark:bg-tertiary-dark':
                                  message.sender == 'user',
                              }">
                                <div v-if="message.image_url" class="block border-2 border-solid border-background-white-light dark:border-background-white-dark object-cover rounded w-[40px] h-[40px] bg-background-white-light
                                 dark:bg-background-white-dark p-[2.5px] shrink-0  mr-1" :style="{
                                  backgroundImage: `url('${message.image_url}')`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                  backgroundSize: 'contain',
                                }">
                                </div>
                                <span class="break-words " v-show="message.sender == 'supporter'"
                                  v-html="message?.message">
                                </span>
                                <span v-show="message.sender == 'user'">
                                  {{ message?.message }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>

                    <Card v-if="adminLoading" size="md" class="flex flex-col gap-[8px] flex-grow !px-0">
                      <div class="flex flex-col gap-[8px] flex-grow h-[45px] justify-center !pr-[60%]">
                        <PacManLoading />
                      </div>
                      <div class="text-[12px] grid justify-center items-center text-center mt-[45px]">
                        <span class="text-text-secondary-light dark:text-text-secondary-dark">
                          Please be patient, we are connecting you to an Admin
                        </span>
                        <span class="text-text-tertiary-light dark:text-text-tertiary-dark">
                          Estimated wait 5 to 10 minutes
                        </span>
                      </div>
                    </Card>
                  </div>
                </Card>

              </div>
            </Card>


            <Card class="absolute bottom-[2%] left-[2%] w-[97%]  !mb-0 ">
              <form @submit.prevent="sendMessage" class="w-[99%] p-[2px] !bg-primary flex items-center rounded ">
                <input :placeholder="disableChat ? disableMessage : 'Drop your question'" v-model="newMessage"
                  :disabled="disableChat"
                  class="flex-grow bg-background-white-light dark:bg-background-white-dark py-[8px] px-[12px] w-[87%]" />
                <button type="button" class="flex items-center justify-center w-[13%] relative" @click="sendMessage"
                  :disabled="disableChat">
                  <div v-if="sendMessageLoading" class="absolute">
                    <SpinLoading />
                  </div>
                  <i class="icon-Send text-3xl text-white" />
                </button>
              </form>
            </Card>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  computed,
  inject,
  reactive,
  nextTick
} from "vue";
import Card from "@/components/card/index.vue";
import { getSupportMessagesApi } from "@/service/api/ChatApi";
import { useStore } from "vuex";
import { supportChat } from "@/service/Ws";
import { cluderCustomAvatar, converUtcTimezoneToDesiredTimezone, convertToFromNow, filled } from "@/helper";
import SpinLoading from "@/components/layout/common/utility/SpinLoading.vue";
import PacManLoading from "@/components/layout/common/utility/PacManLoading.vue";

export default {
  name: "OnlineSupport",
  components: {
    Card,
    SpinLoading,
    PacManLoading,
  },
  setup() {
    const container = ref(null);
    const moment = inject("moment");
    const userTimeZone = computed(() => store.getters.userTimeZone);
    const customAvatar = ref(cluderCustomAvatar());
    const store = useStore();
    const showChat = ref(false);
    const loading = ref(false);
    const messages = ref([]);
    const support = computed(() => store.getters.onlineSupport);
    const roomUuid = computed(() => support.value?.uuid);
    const disableChat = computed(() => sendMessageLoading.value || roomIsClosed.value);
    const disableMessage = ref('room is lock');
    const supportChatWs = ref();
    const senMessageIsOpened = ref(false);
    const newMessage = ref(null);
    const roomIsClosed = ref(null);
    const pushMessages = ref([]);
    const scrollLoading = ref(false);
    const meta = reactive({
      currentPage: 1,
      perPage: 30,
      total: 1,
    });
    const unreadCount = ref(store.state.User.onlineSupportUnreadCount);
    const unreadCountPrettified = computed(() => unreadCount.value > 9 ? '+9' : unreadCount.value);
    const onlineSupportHasAdmin = computed(() => store.state.User.onlineSupportHasAdmin);
    const adminLoading = computed(() => roomUuid.value && !onlineSupportHasAdmin.value && (messages.value.length || pushMessages.value.length));
    const sendMessageLoading = ref(false);
    const sendFristMessageInterval = ref(null);
    const openChat = computed(() => store.state.Chat.openOnlineChat);


    onMounted(async () => {
      if (filled(roomUuid.value)) {
        initWs();
        getMessages();
      }
    });

    watch(roomUuid, async () => {
      if (filled(roomUuid.value)) {
        initWs();
        getMessages();
      }
    });

    watch(openChat, async () => {
      if (openChat.value) {
        showChat.value = true
      }
    });


    const openRoomChat = async () => {
      sendMessageLoading.value = true;
      await store.dispatch('setSupport');
      initWs();
      sendFristMessageInterval.value = setInterval(() => {
        if (senMessageIsOpened.value) {
          sendFirstMessage();
          clearInterval(sendFristMessageInterval.value);
          sendMessageLoading.value = false;
        }
      }, 3000);
    };

    const initWs = () => {
      if (supportChatWs.value) supportChatWs.value.close();
      supportChatWs.value = supportChat();
      supportChatWs.value.addEventListener('open', (event) => {
        const data = JSON.stringify({
          uuid: String(roomUuid.value),
        });
        supportChatWs.value.send(data);
        senMessageIsOpened.value = true;
      });
      supportChatWs.value.addEventListener('message', (event) => {
        let data = JSON.parse(event.data);
        const message = data.message_detail;

        if (data.unread_detail && !showChat.value) {
          let [key, value] = Object.entries(data.unread_detail.unread_count)[0];
          unreadCount.value = value
          store.dispatch('setOnlineSupportUnreadCount', unreadCount.value)
        }

        if (filled(roomUuid.value) &&
          filled(message)
        ) {
          if (message.sender == 'supporter') {
            store.dispatch('setonlineSupportHasAdmin', true);
          }
          if (message.is_closed) {
            roomIsClosed.value = true;
            disableMessage.value = 'room is close';
          }
          pushMessages.value.push(message);
          scrollToBottom();
        }
      });
    };

    const getMessages = async () => {
      pushMessages.value = [];
      loading.value = true;
      const response = await getSupportMessagesApi(roomUuid.value);
      if (response) {
        messages.value = response.data?.reverse();
        setMeta(response.meta);
      }
      else {
        store.dispatch("removeSupport");
      }
      loading.value = false;
      scrollToBottom();
    };

    const loadMore = async () => {
      if (messages.value.length < meta.total && !scrollLoading.value && filled(roomUuid.value)) {
        meta.currentPage += 1;
        scrollLoading.value = true;
        const response = await getSupportMessagesApi(
          roomUuid.value,
          meta.currentPage
        );
        let data = response.data.reverse();
        messages.value.unshift(...data);
        scrollLoading.value = false;
      }
    };

    const setMeta = (metaData) => {
      meta.total = metaData.total;
    };

    const sendFirstMessage = () => {
      const data = JSON.stringify({
        uuid: String(roomUuid.value),
        message: String(newMessage.value),
      });
      if (
        filled(newMessage.value) &&
        senMessageIsOpened.value
      ) {
        supportChatWs.value.send(data);
        newMessage.value = "";
      }
    };

    const sendMessage = () => {
      if (!filled(newMessage.value)) return;
      if (!filled(roomUuid.value)) return openRoomChat()
      setMessagesToRead();

      const data = JSON.stringify({
        uuid: String(roomUuid.value),
        message: String(newMessage.value),
      });
      if (
        filled(newMessage.value) &&
        senMessageIsOpened.value
      ) {
        supportChatWs.value.send(data);
        newMessage.value = "";
      }
    };

    const calculateTime = (time) => {
      if (moment().utc().isSame(time, "day")) {
        return convertToFromNow(time, userTimeZone.value, 'YYYY-MM-DD HH:mm:ss');
      }
      return converUtcTimezoneToDesiredTimezone(
        time,
        userTimeZone.value,
        "MMMM/Do/YYYY HH:mm"
      );
    };

    const scrollToBottom = async () => {
      await nextTick();
      if (container.value) {
        container.value.scrollTop = container.value?.scrollHeight ?? 0;
      }
    };



    const setShowChat = async (show) => {

      showChat.value = show;
      if (show && !roomUuid.value) {
        store.dispatch("checkOnlineSupportExist");
      }
      if (show && roomUuid.value) {
        if (show == 1 && (messages.value.length || pushMessages.value.length) && !roomIsClosed.value) {
          await store.dispatch('setSupport');
          roomIsClosed.value = support.value?.is_closed;
        }
        scrollToBottom();
        setMessagesToRead();
      }
      checkToVanishChatRoom(show);
    };

    const setMessagesToRead = () => {
      unreadCount.value = 0;
      store.dispatch('setOnlineSupportUnreadCount', unreadCount.value);
    };

    const onClose = (e) => {

      if (showChat.value && !e?.target?.classList?.contains("suportChatBtn")) {
        showChat.value = 0;
        checkToVanishChatRoom(0);
      }
    };

    const checkToVanishChatRoom = (show = 1) => {
      if (show == 0) {
        store.dispatch('openChat', false);
      }
      if (show == 0 && roomIsClosed.value) {
        store.dispatch('removeSupport');
        messages.value = [];
        pushMessages.value = [];
        roomIsClosed.value = false;
        store.dispatch('setOnlineSupportUnreadCount', 0)
      }
    };

    return {
      sendMessageLoading,
      disableMessage,
      showChat,
      messages,
      support,
      customAvatar,
      container,
      loading,
      newMessage,
      pushMessages,
      scrollLoading,
      senMessageIsOpened,
      unreadCount,
      unreadCountPrettified,
      adminLoading,
      disableChat,
      roomIsClosed,
      loadMore,
      sendMessage,
      calculateTime,
      filled,
      setShowChat,
      onClose,
    }
  }
}
</script>
