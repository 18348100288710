import { api } from "@/service/Config";

const prefix = "/auth";

export const loginApi = async (data) => {
  return api.post(`${prefix}/login`, data).then((res) => {
    return res.data;
  });
};

export const signupApi = async (data, timezone) => {
  return api
    .post(`${prefix}/signup`, data, {
      headers: {
        timezone: timezone,
      },
    })
    .then((res) => res.data);
};
export const oAuthRegisterApi = async (data, timezone) => {
  return api
    .post(`${prefix}/third-party/register`, data, {
      headers: {
        timezone: timezone,
      },
    })
    .then((res) => res.data);
};

export const googleLoginApi = async (data, timezone) => {
  return api
    .post(`${prefix}/third-party/google/callback`, data, {
      headers: {
        timezone: timezone,
      },
    })
    .then((res) => res.data);
};

export const forgotPasswordApi = async (data) => {
  return api.post(`${prefix}/password/forget`, data).then((res) => res.data);
};

export const resetPasswordApi = async (data) => {
  return api.patch(`${prefix}/password/reset`, data).then((res) => res.data);
};
