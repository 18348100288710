<template>
  <div class="pb-[60px] lg:pb-0">
    <PanelHeaderLayout v-if="isAuthenticated" />
    <PublicHeaderLayout v-else />
    <main class="min-h-screen">
      <slot />
    </main>
    <FooterLayout />
  </div>
</template>

<script>
import FooterLayout from "@/components/layout/Footer.vue";
import PublicHeaderLayout from "@/components/layout/public/header/HeaderLayout.vue";
import PanelHeaderLayout from "@/components/layout/panel/header/HeaderLayout.vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "PublicLayout",
  components: {
    FooterLayout,
    PublicHeaderLayout,
    PanelHeaderLayout,
  },

  setup() {
    const store = useStore();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    return {
      isAuthenticated,
    };
  },
};
</script>
