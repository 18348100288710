<template>
  <nav>
    <ul class="bg-background-main-light dark:bg-background-main-dark rounded px-[10px] text-lg">
      <li v-for="Item in menuItem" :key="Item.name"
        class="border-b-[1px] border-solid border-text-quaternary-light dark:border-text-quaternary-dark last:border-none">
        <span v-if="Item.child">
          <button class="group py-[12px] w-full flex items-center justify-between" type="button" data-te-collapse-init
            data-te-target="#collapseOne" aria-controls="collapseOne" aria-expanded="false" data-te-collapse-collapsed="">
            <span>{{ Item.name }}</span>
            <i class="icon-Down-Stroke transition-all group-[[data-te-collapse-collapsed]]:rotate-0 rotate-[-180deg]"></i>
          </button>

          <ul id="collapseOne" data-te-collapse-item aria-labelledby="headingOne" class="hidden">
            <li :key="I.name" v-for="I in Item.child"
              class="bg-background-white-light dark:bg-background-white-dark rounded mb-[8px]">
              <router-link :to="{ name: I.linkName }" @click="$emit('closeSidebar')" class="py-[8px] px-[8px] block">
                <span>{{ I.name }}</span>
              </router-link>
            </li>
          </ul>
        </span>
        <span v-else>
          <router-link :to="{ name: Item.linkName }" @click="$emit('closeSidebar')"
            class="py-[10px] flex items-center gap-[12px]">
            <i :class="Item.icon" class="text-3xl" />
            <span>{{ Item.name }}</span>
          </router-link>
        </span>
      </li>
      <li @click="doLogout"
        class="border-b-[1px] border-solid border-text-quaternary-light dark:border-text-quaternary-dark last:border-none py-[10px] flex items-center gap-[12px]">
        <i class="text-3xl icon-Logout-Stroke" />
        <span class="text-lg">Logout</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from "vue";
import { $vfm } from "vue-final-modal";

export default {
  name: "MobileNav",

  props: ["menuItem"],
  emits: [
    'closeSidebar'
  ],
  setup() {

    const store = useStore();
    const route = useRoute();
    const routeName = computed(() => route.name);
    const routeSection = computed(() => route.meta?.SectionName);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const doLogout = async () => {
      $vfm.show('LogoutConfirmModal');
    };


    return {
      routeName,
      routeSection,
      isAuthenticated,
      doLogout,
    }
  }
};
</script>
<style>
.disabled {
  opacity: 0.6;
  pointer-events: none;
}
</style>
