const state = {
  form: [],
  showCreateSponsorShip: false,
  files: [],
};

const getters = {};

const actions = {
  async setForm({ commit }, data) {
    commit("_setForm", data);
  },
  async clearForm({ commit }, data) {
    commit("_clearFrom", data);
  },
  async setShowCreateSponsorShip({ commit }, data) {
    commit("_setShowCreateSponsorShip", data);
  },
};

const mutations = {
  _setForm: (state, data) => {
    const newforms = state.form?.filter((f) => f.id !== data.id);
    newforms.push(data);
    state.form = newforms;
  },
  _clearFrom: (state, data) => {
    const newforms = state.form?.filter((f) => f.id !== data);
    state.form = newforms;
  },
  _setShowCreateSponsorShip: (state, data) => {
    state.showCreateSponsorShip = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
