<template>
  <div class="flex items-center" :class="className">
    <label
      class="flex items-center gap-[8px] hover:cursor-pointer text-sm text-text-secondary-light dark:text-text-secondary-dark">
      <input :tabindex="tabIndex" :checked="checked" name="switch"
        class="relative h-[15px] w-[38px] appearance-none rounded-full bg-text-secondary-light dark:bg-text-secondary-dark
        shadow-[inset_0px_0px_5px_rgba(0,_0,_0,_0.5)] after:absolute after:left-[1px] after:top-1/2 after:!translate-y-[-50%]
        after:z-[2] after:h-[13px] after:w-[13px] after:rounded-full after:border-none after:bg-neutral-100 dark:after:bg-background-white-dark
        after:transition-[background-color_0.2s,transform_0.2s] checked:after:left-[calc(100%-14px)] checked:bg-primary dark:checked:bg-primary
        checked:after:z-[2] checked:after:bg-background-white-light dark:checked:after:bg-background-white-light after:content-[''] hover:cursor-pointer" type="checkbox"
        role="switch" :value="modelValue" @input="$emit('update:modelValue', $event.target.checked)" />
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "SwitchForm",
  props: {
    checked: {
      default: false,
    },
    label: {},
    modelValue: {},
    class: {},
    tabIndex: {}
  },
  emits: ["update:modelValue"],
  data() {
    return {
      className: this.class,
    };
  },
};
</script>
