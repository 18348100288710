<template>
  <div :class="{ ['card-' + size]: size, '!p-0': hasBorder }"
    class="bg-background-white-light dark:bg-background-white-dark rounded mb-[20px]">

    <div v-if="name || icon || imgIcon || componentIcon || hasCustomHeader" :class="[{
      'border-b border-border-light dark:border-border-dark p-[8px] sm:p-[12px] !mb-[8px] sm:!mb-[12px]': hasBorder
    }, customClass]" class="flex items-center justify-between mb-[20px]">

      <div v-if="name || icon || imgIcon || componentIcon" :class="{
        'gap-[12px]': icon || componentIcon || imgIcon,
      }" class="flex items-center">
        <i :class="icon" v-if="icon" class="text-3xl" />
        <img v-if="imgIcon" :src="imgIcon" class="w-[24px] h-[24px]" />
        <FollowingIcon light-color="#000000" dark-color="#ffffff" v-if="componentIcon === 'FollowingIcon'" />

        <span class="font-medium text-md">
          {{ name }}
        </span>
      </div>
      <div v-if="hasCustomHeader">
        <slot name="header" />
      </div>

      <span v-if="subTitle" class="text-sm text-text-secondary-light dark:text-text-secondary-dark">
        {{ subTitle }}
      </span>

    </div>

    <div v-if="hasBorder" class="px-[8px] sm:px-[12px] pb-[8px] sm:pb-[12px]">
      <slot v-if="$slots" />
    </div>
    <template v-else>
      <slot v-if="$slots" />
    </template>
  </div>
</template>

<style lang="scss">
.card {
  &-sm {
    @apply p-[8px];
  }

  &-md {
    @apply py-[12px] px-[12px] sm:px-[20px];
  }

  &-lg {
    @apply p-[20px];
  }
}
</style>

<script>
import FollowingIcon from "@/components/Icons/FollowingIcon.vue";

export default {
  name: "CardVue",
  props: [
    'size',
    'name',
    'icon',
    'subTitle',
    'hasBorder',
    'imgIcon',
    'componentIcon',
    'hasCustomHeader',
    'customClass'
  ],
  components: {
    FollowingIcon
  }
};
</script>
