<template>
  <Teleport to="body">
    <VueFinalModal class="text-text-white-dark dark:text-text-white-light" name="login" v-model="showLogin">
      <ModalLayout modalName="login" :has-btn-on-bottom="true" class="!z-[16]">
        <template #title>
          Welcome to GameClude
        </template>
        <form v-show="!loading" @submit.prevent="doLogin">
          <div class="flex flex-col gap-[20px]">
            <div>
              <span class="text-text-secondary-light dark:text-text-secondary-dark block text-sm md:text-md">
                Build your gaming career by playing E-sport games
              </span>
            </div>

            <div class="flex items-center gap-[12px]">
              <LoginWithGoogle @set-loading="(data) => loading = data" title="Google Login" />
              <!--             <ButtonTertiary size="md" class="w-full !bg-background-main-light dark:!bg-background-main-dark border border-tertiary-light dark:border-tertiary-dark">-->
              <!--              <span class="flex items-center justify-center gap-[8px] sm:gap-[20px] text-sm sm:text-xl">-->
              <!--                <i class="icon-Apple text-[24px]"></i>-->
              <!--                Apple Login-->
              <!--              </span>-->
              <!--            </ButtonTertiary>-->
            </div>

            <!--or-->
            <div class="flex items-center justify-center relative">
              <span class="absolute left-0 right-0 h-[1px] bg-border-light dark:bg-border-dark"></span>
              <span
                class="block relative z-[1] w-fit px-3 bg-background-white-light dark:bg-background-white-dark text-text-secondary-light dark:text-text-secondary-dark text-sm">Or</span>
            </div>

            <div class="flex flex-col gap-[12px]">
              <Input placeholder="email" name="email" :validation="'required|email'" v-model="data.email"
                :show-error="showError" @has-error="(data) => storeError(data)"
                @change-show-error="(data) => { showError = data }" :tab-index="1" :showErrorInInput="true" />
              <Input placeholder="password" name="password" :validation="'required'" type="password"
                v-model="data.password" :show-error="showError" @has-error="(data) => storeError(data)"
                @change-show-error="(data) => { showError = data }" :tab-index="2" :showErrorInInput="true" />
            </div>
            <!--  -->
            <div class="grid justify-end">

              <button type="button" @click="() => {
                $vfm.hide('login');
                $vfm.show('forgetPass');
              }
                " class="text-primary transition-all hover:text-secondary text-right text-md -mt-3">
                forgot password?
              </button>
            </div>
            <div class="flex items-center justify-start">
              <button type="button" @click="() => {
                $vfm.hide('login');
                $vfm.show('Register');
              }
                " class="text-text-secondary-light dark:text-text-secondary-dark text-right text-md -mt-3">
                <span class="">
                  Don't have an account yet?
                </span>

              </button>
            </div>

            <!--  -->
            <div
              class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
              <div class="max-sm:grid max-sm:grid-cols-2 gap-[8px] sm:flex sm:items-center sm:justify-end">
                <ButtonTertiary @click="close" size="sm" type="button"
                  class="max-sm:w-full sm:min-w-[68px] max-h-[37px] !bg-background-white-light dark:!bg-background-white-dark border border-primary"
                  tabindex="4">
                  Cancel
                </ButtonTertiary>
                <ButtonPrimary size="sm" type="submit" class="max-sm:w-full sm:min-w-[68px]" :disabled="submitDisabled"
                  tabindex="3">
                  Login
                </ButtonPrimary>
              </div>
            </div>

          </div>
        </form>
        <div v-show="loading" class="h-[331px] grid items-center">
          <div class="w-[70px] h-[100px] ml-[20%]">
            <PacManLoading />
          </div>
        </div>
      </ModalLayout>
    </VueFinalModal>
  </Teleport>
</template>
<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import ModalLayout from "@/components/modal/ModalLayout.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import Input from "@/components/form-component/input.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import PacManLoading from "@/components/layout/common/utility/PacManLoading.vue";
// === icon
import GoogleIcon from "@/assets/icon-svg/google.svg";
import LoginWithGoogle from "./LoginWithGoogle.vue"

import { ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "LoginModal",
  components: {
    ButtonTertiary,
    Input,
    ButtonPrimary,
    VueFinalModal,
    ModalLayout,
    PacManLoading,
    LoginWithGoogle
  },


  setup() {
    const store = useStore();
    const icons = ref({
      GoogleIcon
    });
    const data = ref({
      email: "",
      password: "",
    });
    const loading = ref(false);
    const submitDisabled = ref(false);
    const showLogin = ref(false);
    const showForgetPass = ref(false);
    const inputErrors = ref([]);
    const showError = ref(false);


    const ForgetPass = (e) => {
      showForgetPass.value = e;
    };

    const close = () => {
      $vfm.hide("login");
    };

    const storeError = (data) => {
      inputErrors.value = inputErrors.value.filter(err => err?.id !== data.id);
      inputErrors.value.push(data);
    };

    const checkError = () => {
      return inputErrors.value.filter(err => err.value === true).length !== 0;
    };

    const doLogin = async (e) => {
      if (checkError()) {
        return showError.value = true;
      }
      loading.value = true;
      submitDisabled.value = true;
      await store.dispatch('login', data.value);
      loading.value = false;
      submitDisabled.value = false;
    };

    return {
      icons,
      data,
      submitDisabled,
      inputErrors,
      showLogin,
      showForgetPass,
      loading,
      showError,
      storeError,
      checkError,
      doLogin,
      ForgetPass,
      close
    }

  }

};

</script>
