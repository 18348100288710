import { authApi } from "@/service/Config";

const prefix = "/profile";

export const getProfileApi = async () => {
  return authApi.get(`${prefix}`).then((res) => res.data);
};

export const updateProfileApi = async (data) => {
  return authApi.put(`${prefix}`, data).then((res) => res.data);
};

export const updateProfileAvatarApi = async (data) => {
  return authApi
    .post(`${prefix}/avatar`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};

export const updateProfileNameApi = async (data) => {
  return authApi.patch(`${prefix}/name`, data).then((res) => res.data);
};

export const updateProfileSloganApi = async (data) => {
  return authApi.patch(`${prefix}/slogan`, data).then((res) => res.data);
};

export const updateProfileUsernameApi = async (data) => {
  return authApi.patch(`${prefix}/username`, data).then((res) => res.data);
};

export const updateProfileMobileApi = async (data) => {
  return authApi.patch(`${prefix}/mobile`, data).then((res) => res.data);
};

export const updateProfileCountryApi = async (data) => {
  return authApi.patch(`${prefix}/country`, data).then((res) => res.data);
};

export const updateProfileBornAtApi = async (data) => {
  return authApi.patch(`${prefix}/born-at`, data).then((res) => res.data);
};

export const updateProfileTimezoneApi = async (data) => {
  return authApi.patch(`${prefix}/timezone`, data).then((res) => res.data);
};

export const updateProfileLastOnlineApi = async () => {
  return authApi.put(`${prefix}/last-online`).then((res) => res.data);
};

export const updateProfileThemeApi = async (data) => {
  return authApi.put(`${prefix}/theme`, data).then((res) => res.data);
};

export const getUserPlayedGamesApi = async () => {
  return authApi.get(`${prefix}/played-games`).then((res) => res.data);
};

export const donateCasheApi = async (data) => {
  return authApi.post(`${prefix}/donate`, data).then((res) => res.data);
};

export const lastAchievementsApi = async (page = 1) => {
  return authApi
    .get(`${prefix}/last-achievements`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const getCluderPlayedWithBeforeApi = async (gameId, page = 1) => {
  return authApi
    .get(`${prefix}/cluder-played-with-before`, {
      params: {
        game_id: gameId,
        page,
      },
    })
    .then((res) => res.data);
};

export const updatePasswordApi = async (data) => {
  return authApi.put(`${prefix}/password`, data).then((res) => res.data);
};

export const deleteAccountApi = async (data) => {
  return authApi.post(`${prefix}/delete-account`, data).then((res) => res.data);
};
