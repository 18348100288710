import { api } from "@/service/Config";

const prefix = "/services";

export const getServicesApi = async (serviceIds) => {
  return api
    .get(`${prefix}`, {
      params: {
        service_ids: serviceIds,
      },
    })
    .then((res) => res.data);
};

export const getServiceWithShortNameApi = async (shortName) => {
  return api.get(`${prefix}/${shortName}/detail`).then((res) => res.data);
};
