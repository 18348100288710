<template>
  <div class="w-full rounded bg-background-white-light dark:bg-background-white-dark">
    <button data-te-collapse-init :data-te-collapse-collapsed="openAtFirstLook ? null : true" :data-te-target="'#' + id"
      aria-expanded="true" class="group p-[8px] md:p-[12px] flex items-center justify-between cursor-pointer w-full">
      <div class="flex items-center justify-between flex-grow pr-[8px]">
        <div :class="!footerAccordion && 'gap-[12px]'" class="flex items-center">
          <slot name="title" />
          <i :class="icon" class="text-3xl" />
          <img v-if="imgIcon" :src="imgIcon" class="w-[24px] h-[24px]" />
          <span class="font-medium text-md text-left">
            {{ name }}
          </span>
        </div>
        <span class="text-sm text-text-secondary-light dark:text-text-secondary-dark">
          {{ subTitle }}
        </span>
      </div>
      <i class="icon-Down-Stroke text-2xl rotate-180 group-[[data-te-collapse-collapsed]]:rotate-0 transition-all" />
    </button>

    <p v-if="openAtFirstLook" :id="id" data-te-collapse-item
      class="!visible border-t border-tertiary-light dark:border-tertiary-dark" data-te-collapse-show
      :class="contentClass">
      <slot />
    </p>
    <p v-else :id="id" data-te-collapse-item
      class="pt-0 hidden border-t border-tertiary-light dark:border-tertiary-dark" :class="contentClass">
      <slot />
    </p>
  </div>
</template>
<script>
import { v4 as uuid } from "uuid";

export default {
  name: "AccordionVue",
  props: {
    imgIcon: {},
    icon: {},
    name: {},
    subTitle: {},
    footerAccordion: {
      default: false
    },
    openAtFirstLook: {
      type: Boolean,
      default: false
    },
    contentClass: {}
  },
  data() {
    return {
      show: false,
      id: "x" + uuid(),
    };
  },
};
</script>
