<template>
  <GoogleLogin :callback="callback" class="w-full" popup-type="TOKEN">
    <ButtonTertiary size="md"
      class="w-full !bg-background-main-light dark:!bg-background-main-dark border border-tertiary-light dark:border-tertiary-dark"
      type="button" :disabled="loading || disabled">
      <span class="flex items-center justify-center gap-[3px] sm:gap-[10px] text-sm sm:text-xl">
        <img src="@/assets/icon-svg/google.svg" />
        {{ title }}
      </span>
    </ButtonTertiary>
  </GoogleLogin>
</template>
<script >
import { computed } from "vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import { useStore } from "vuex";
import { filled } from "@/helper";

export default {
  name: 'LoginWithGoogle',
  components: {
    ButtonTertiary
  },
  props: {
    disabled: {
      default: false
    },
    type: {
      default: 'LOGIN'
    },
    title: {
      type: String,
      default: 'Continue with Google'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  emits: ['setLoading'],
  setup(props, { emit }) {
    const store = useStore();
    const refferaledToken = computed(() => store.state.User.refferaledToken);

    const callback = async (response) => {
      emit('setLoading', true);

      await sendToken(response?.access_token);
    };

    const sendToken = async (token) => {
      let data = { token: token }
      if (filled(refferaledToken.value)) {
        data.user_referral_number = refferaledToken.value
      }

      await store.dispatch('googleLogin', data);
      emit('setLoading', false);
    };

    return {
      callback
    }
  }
}
</script>
