<template>
  <component :is="$route.meta.layout">
    <RouterView v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <div class="flex flex-col w-full" :key="route.name">
          <component :is="Component" :key="route.path" />
        </div>
      </transition>
    </RouterView>
    <CoockiesBox />
    <OnlineSupport />
  </component>
</template>
<script>
import { onMounted } from "vue";
import OnlineSupport from "@/components/layout/public/header/OnlineSupport.vue";
import CoockiesBox from "@/components/layout/public/header/CoockiesBox.vue";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    OnlineSupport,
    CoockiesBox
  },
};
</script>

<style>
@import "./assets/tailwind.css";
@import "./style/index.scss";

@font-face {
  font-family: "Audiowide-Regular";
  src: url("./assets/fonts/Audiowide-Regular.ttf");
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/Jost-regular.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Jost";
  font-style: bold;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/jost-medium.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.font-Audiowide {
  font-family: "Audiowide-Regular";
}

.loading-effect::after {
  content: " ";
  box-shadow: 0 0 50px 9px rgba(254, 254, 254);
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  animation: load 1s infinite;
}

@keyframes load {
  0% {
    left: -100%;
  }

  100% {
    left: 150%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.toast-bg-success {
  background-color: rgba(76, 175, 80, 70%) !important;
}

.Vue-Toastification__container{
  @apply !bottom-[9vh] md:!bottom-[1rem]
}

.toast-bg-error {
  background-color: rgba(255, 82, 82, 70%) !important;
}

.vc-time-picker {
  height: 69px !important;
  justify-content: center;
}

.vc-base-select select.vc-align-right,
.vc-base-select select.vc-align-left,
.vc-base-select .vc-focus {
  text-align: center !important;
  width: 60px;
  @apply scrollbar-hide dark:bg-[#334155] bg-[#f8fafc] mx-1;
}

.vs__dropdown-toggle {
  padding: 0 0 3px !important;
}

.Vue-Toastification__container {
  z-index: 99999 !important;
}
</style>
