<template>
  <Teleport to="body">
    <VueFinalModal name="DepositModal" class="text-text-white-dark dark:text-text-white-light" v-model="DepositModal">
      <ModalLayout modalName="DepositModal" :has-btn-on-bottom="true" class="!z-[18]">
        <template #title>
          <div class="flex items-center gap-2">
            <deposit-icon light-color="#0c0e1a" dark-color="#ffffff" />
            <div class="text-md !font-[500]">Deposit</div>
          </div>
        </template>
        <span class="text-text-primary-light dark:text-text-primary-dark text-md">
          Take the initial step into matches with a deposit.
        </span>

        <div
          class="p-[12px] sm:p-[20px] rounded-[4px] mt-[12px] border-tertiary-light dark:border-tertiary-dark border bg-background-main-light dark:bg-background-main-dark">
          <p class="text-sm text-text-secondary-light dark:text-text-secondary-dark">
            Choose Amount:
          </p>
          <div class="grid grid-cols-4 sm:grid-cols-6 gap-[12px]">
            <div class="w-full" v-for="p in depositAmounts" :key="p">
              <ButtonPrimary v-if="amount == p" class="w-full" size="sm">
                ${{ p }}
              </ButtonPrimary>
              <ButtonTertiary v-else @click="() => {
                amount = p;
                customAmount = null;
              }" class="w-full text-text-tertiary-light dark:text-text-tertiary-dark btn-hover" size="sm">
                ${{ p }}
              </ButtonTertiary>
            </div>
            <input name="custom_amount" placeholder="Custom Amount" v-model="customAmount"
              @change="amount = customAmount" :class="{
                '!bg-primary !text-text-white-light': customAmount == amount
              }" @keypress="checkInteger($event)" type="number"
              class="focus:btn-active col-span-4 sm:col-span-6 text-center py-[8px] px-[12px] rounded bg-tertiary-light dark:bg-tertiary-dark text-text-tertiary-light btn-hover focus:!text-text-white-dark dark:focus:!text-text-white-light" />
          </div>
          <div class="flex flex-col justify-between mt-[12px] gap-[12px]">
            <span class="font-sm text-text-tertiary-light dark:text-text-tertiary-dark">
              +2% payment fee included by crypto payment service
            </span>
          </div>
        </div>
        <div class="flex flex-col justify-between mt-[14px] gap-[12px]">
            <span class="font-medium"> Current Balance: ${{ userWallet }} </span>
          </div>

        <div
          class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
          <div class="grid grid-cols-2 gap-[8px] sm:flex sm:items-center sm:justify-end">
            <ButtonTertiary size="sm" @click="$vfm.hide('DepositModal')">
              cancel
            </ButtonTertiary>
            <ButtonPrimary size="sm" @click="doPay" :disabled="submitPaymentDisabled || submitPaymentLoading">
              <div class="flex items-center gap-[12px] relative">
                <span class="absolute left-[50%] -translate-x-[50%]" v-if="submitPaymentLoading">
                  <SpinLoadingVue />
                </span>
                <span> Confirm </span>
              </div>
            </ButtonPrimary>
          </div>
        </div>

      </ModalLayout>
    </VueFinalModal>
  </Teleport>
  <Teleport to="body">
    <VueFinalModal name="TransactionFailedModal" class="text-text-white-dark dark:text-text-white-light"
      v-model="TransactionFailedModal">
      <ModalLayout modalName="TransactionFailedModal" class="!z-[19] sm:!w-fit"
        :classContent="'md:!pb-0 !px-0 md:!pt-[45px]'">
        <template #title>
          <div class="flex items-center gap-2">
            <deposit-icon light-color="#0C0E1A" dark-color="#ffffff" />
            <div class="text-md font-[500]">Payment Details</div>
          </div>
        </template>
        <div class="container mx-auto">
          <div
            class="md:w-[650px] mx-auto bg-background-white-light dark:bg-background-white-dark rounded-[4px] max-md:bg-transparent md:py-[12px] md:pt-[15px]">
            <div
              class=" md:bg-background-main-light md:dark:bg-background-main-dark max-md:bg-transparent rounded grid grid-cols-1 md:grid-cols-9 md:gap-4 md:pl-[20px]">
              <div class="md:py-12 col-span-5">
                <div class="bg-background-white-light dark:bg-background-white-dark rounded-[4px] p-[12px]">

                  <div class="mt-[12px] flex flex-col gap-[8px]">
                    <div
                      class="bg-background-main-light dark:bg-background-main-dark rounded-[4px] py-[6px] px-[8px] flex items-center justify-between gap-3 md:order-1 max-md:order-3">
                      <div class="text-md font-[300] text-text-secondary-light dark:text-text-secondary-dark">Amount
                      </div>
                      <div class="text-md font-[500] text-text-primary-light dark:text-text-primary-dark">
                        ${{ transaction?.amount }}
                      </div>
                    </div>
                    <div
                      class="bg-background-main-light max-h-[45px] dark:bg-background-main-dark rounded-[4px] py-[0px] px-[8px] flex items-center justify-between gap-3 md:order-2 max-md:order-4">
                      <div class="text-md font-[300] text-text-secondary-light dark:text-text-secondary-dark">
                        Status
                      </div>
                      <div class="flex items-center gap-1">
                        <CircleCloseIcon class="w-[14px]" light-color="#d32029" dark-color="#d32029" />
                        <div class="text-alert text-[14px] font-[400]">Unsuccessful</div>
                      </div>
                    </div>
                    <div
                      class="bg-background-main-light dark:bg-background-main-dark rounded-[4px] py-[6px] px-[8px] flex items-center justify-between gap-3 md:order-3 max-md:order-5">
                      <div class="text-md font-[300] text-text-secondary-light dark:text-text-secondary-dark">Id</div>
                      <div
                        class="text-md whitespace-pre-wrap break-words font-[400] text-text-tertiary-light dark:text-text-tertiary-dark">
                        {{ transaction?.uuid }}
                      </div>
                    </div>
                    <div
                      class="bg-background-main-light dark:bg-background-main-dark rounded-[4px] py-[6px] px-[8px] flex items-center justify-between gap-3 md:order-4 max-md:order-6">
                      <div class="text-md font-[300] text-text-secondary-light dark:text-text-secondary-dark">Time</div>
                      <div class="text-sm font-[400] text-text-secondary-light dark:text-text-secondary-dark">
                        {{
                          converUtcTimezoneToDesiredTimezone(
                            transaction?.created_at,
                            userTimeZone,
                            "MMMM, Do, YYYY HH:mm"
                          )
                        }}
                      </div>
                    </div>
                    <div
                      class="text-text-tertiary-light dark:text-text-tertiary-dark text-sm font-[400] md:order-5 max-md:order-1">
                      Oops! we did not receive any payment to add to your wallet
                    </div>
                    <ButtonPrimary @click="rePay" :disabled="submitPaymentDisabled || submitPaymentLoading"
                      title="Find match" size="sm" class="md:ml-auto mt-[4px] md:order-6 max-md:order-2 max-md:w-full">
                      <div class="flex items-center gap-[12px] relative">
                        <span class="absolute left-[50%] -translate-x-[50%]" v-if="submitPaymentLoading">
                          <SpinLoadingVue />
                        </span>
                        <span> Try again</span>
                      </div>
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
              <div
                class="col-span-4 max-md:mt-[0.5rem] bg-background-main-light dark:bg-background-main-dark md:bg-transparent w-full h-[384px] md:h-full relative">
                <img src="/images/payment/failed-payment.png"
                  class=" w-[320px] md:w-full md:h-full h-[447px] object-bottom absolute bottom-0 right-[15px] md:right-[2.5rem]"
                  alt="failed payment" />
              </div>
            </div>
          </div>
        </div>
      </ModalLayout>
    </VueFinalModal>
  </Teleport>
  <Teleport to="body">
    <VueFinalModal name="TransactionSuccessModal" class="text-text-white-dark dark:text-text-white-light"
      v-model="TransactionSuccessModal">
      <ModalLayout modalName="TransactionSuccessModal" class="!z-[19] sm:!w-fit"
        :classContent="'md:!pb-0 !px-0 md:!pt-[45px]'">
        <template #title>
          <div class="flex items-center gap-2">
            <deposit-icon light-color="#0C0E1A" dark-color="#ffffff" />
            <div class="text-md font-[500]">Payment Details</div>
          </div>
        </template>
        <div class="container mx-auto">
          <div
            class="md:w-[650px] mx-auto bg-background-white-light dark:bg-background-white-dark max-md:bg-transparent md:py-[12px] md:pt-[15px]">
            <div
              class="md:bg-background-main-light md:dark:bg-background-main-dark max-md:bg-transparent rounded grid grid-cols-1 md:grid-cols-11 md:gap-4 md:pl-[20px]">
              <div class="md:py-12 col-span-6">
                <div class="bg-background-white-light dark:bg-background-white-dark rounded p-[12px]">
                  <!-- <div class="flex items-center gap-2 border-b border-border-light dark:border-border-dark pb-[12px]">
                    <deposit-icon light-color="#0C0E1A" dark-color="#ffffff" />
                    <div class="text-md font-[500]">Payment Details</div>
                  </div> -->
                  <div class="mt-[12px] flex flex-col gap-[8px]">
                    <div
                      class="bg-background-main-light dark:bg-background-main-dark rounded-[4px] py-[6px] px-[8px] flex items-center justify-between gap-3 md:order-1 max-md:order-3">
                      <div class="text-md font-[300] text-text-secondary-light dark:text-text-secondary-dark">Amount
                      </div>
                      <div class="text-md font-[500] text-text-primary-light dark:text-text-primary-dark">
                        ${{ transaction?.amount }}
                      </div>
                    </div>
                    <div
                      class="bg-background-main-light max-h-[45px] dark:bg-background-main-dark rounded-[4px] py-[0px] px-[8px] flex items-center justify-between gap-3 md:order-2 max-md:order-4">
                      <div class="text-md font-[300] text-text-secondary-light dark:text-text-secondary-dark">
                        Status
                      </div>
                      <div class="flex items-center gap-1">
                        <CircleCheckIcon class="w-[14px]" light-color="#17B569" dark-color="#17B569" />
                        <div class="text-success text-[14px] font-[400]">Success</div>
                      </div>
                    </div>
                    <div
                      class="bg-background-main-light dark:bg-background-main-dark rounded-[4px] py-[6px] px-[8px] flex items-center justify-between gap-3 md:order-3 max-md:order-5">
                      <div class="text-md font-[300] text-text-secondary-light dark:text-text-secondary-dark">Id</div>
                      <div
                        class="text-md whitespace-pre-wrap break-words font-[400] text-text-tertiary-light dark:text-text-tertiary-dark">
                        {{ transaction?.uuid }}
                      </div>
                    </div>
                    <div
                      class="bg-background-main-light dark:bg-background-main-dark rounded-[4px] py-[6px] px-[8px] flex items-center justify-between gap-3 md:order-4 max-md:order-6">
                      <div class="text-md font-[300] text-text-secondary-light dark:text-text-secondary-dark">Time</div>
                      <div class="text-sm font-[400] text-text-secondary-light dark:text-text-secondary-dark">
                        {{
                          converUtcTimezoneToDesiredTimezone(
                            transaction?.created_at,
                            userTimeZone,
                            "MMMM, Do, YYYY HH:mm"
                          )
                        }}
                      </div>
                    </div>
                    <div
                      class="text-text-tertiary-light dark:text-text-tertiary-dark text-sm font-[400] md:order-5 max-md:order-1">
                      Enjoy playing</div>
                    <ButtonPrimary v-if="isInCreateMatch" title="Find match" size="sm"
                      @click="$vfm.hide('TransactionSuccessModal')"
                      class="md:ml-auto mt-[4px] md:order-6 max-md:order-2 max-md:w-full">
                      continue creation
                    </ButtonPrimary>
                    <ButtonPrimary v-else @click="redirectToFindMatch" title="Find match" size="sm"
                      class="md:ml-auto mt-[4px] md:order-6 max-md:order-2 max-md:w-full">
                      Find match
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
              <div
                class="col-span-5 max-md:mt-[1.5rem] bg-background-main-light dark:bg-background-main-dark md:bg-transparent w-full h-[320px] md:h-full relative">
                <img src="/images/payment/success-payment.png"
                  class="w-[320px] md:w-full h-[399px] md:h-full object-bottom absolute bottom-0 right-0 md:right-[2.5rem]"
                  alt="success payment" />
              </div>
            </div>
          </div>
        </div>
      </ModalLayout>
    </VueFinalModal>
  </Teleport>
  <!-- <Teleport to="body">
    <VueFinalModal name="PaymentModal" class="text-text-white-dark dark:text-text-white-light" v-model="PaymentModal">
      <ModalLayout modalName="PaymentModal" :has-btn-on-bottom="true" class="!z-[18]">

        <template #title>
          <div class="flex items-center gap-2">
            <deposit-icon light-color="#0c0e1a" dark-color="#ffffff" />
            <div class="text-md !font-[500]">Deposit</div>
          </div>
        </template>
        <span class="text-md text-text-primary-light dark:text-text-primary-dark">${{ amount }} will be added to your
          account</span>

        <div
          class="bg-background-main-light dark:bg-background-main-dark border border-tertiary-light dark:border-tertiary-dark rounded-[4px] p-[12px] sm:p-[20px] mt-[12px]">
          <div class="mb-[8px] text-sm text-text-tertiary-light dark:text-text-tertiary-dark">Choose payment method:
          </div>
          <div class="flex flex-col gap-[8px]">
            <button v-for="paymentMethod in paymentMethods" :key="paymentMethod.network"
              class="flex items-center gap-[20px] p-[12px] rounded border-2 border-solid" :class="{
                'border-success bg-background-main-light dark:bg-background-main-dark':
                  selectedPaymentMethod?.network == paymentMethod.network,
                'border-primary': selectedPaymentMethod?.network !== paymentMethod.network,
              }" @click="() => selectedPaymentMethod = paymentMethod">
              <img :src="require('@/assets/icon-svg/' + paymentMethod.icon)" class="h-[25px]" alt="x" />
              <span>{{ paymentMethod.name }} </span>
            </button>
          </div>
        </div>

        <div
          class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
          <div class="grid grid-cols-2 gap-[8px] sm:flex sm:items-center sm:justify-end">
            <ButtonTertiary size="sm" @click="() => {
              $vfm.hide('PaymentModal');
              $vfm.show('DepositModal');
            }
              ">
              back
            </ButtonTertiary>
            <ButtonPrimary size="sm" @click="doPay" :disabled="submitPaymentDisabled || submitPaymentLoading">
              <div class="flex items-center gap-[12px] relative">
                <span class="absolute left-[50%] -translate-x-[50%]" v-if="submitPaymentLoading">
                  <SpinLoadingVue />
                </span>
                <span> Confirm </span>
              </div>
            </ButtonPrimary>
          </div>
        </div>
      </ModalLayout>
    </VueFinalModal>
  </Teleport> -->
</template>

<script>
import { ref, computed, watch, onMounted, inject, onUnmounted } from "vue";
import ModalLayout from "@/components/modal/ModalLayout.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import { isInteger, converUtcTimezoneToDesiredTimezone } from "@/helper";
import { useStore } from "vuex";
import { depositApi } from "@/service/api/WalletApi";
import { $vfm } from "vue-final-modal";
import SpinLoadingVue from "@/components/layout/common/utility/SpinLoading.vue";
import DepositIcon from "@/components/Icons/DepositIcon.vue";
import { useRoute, useRouter } from "vue-router";
import { TransactionIsDoneEvent } from "@/service/channels";
import CircleCheckIcon from "@/components/Icons/CircleCheckIcon.vue";
import CircleCloseIcon from "@/components/Icons/CircleCloseIcon.vue";

export default {
  name: "DepositWallet",
  components: {
    DepositIcon,
    ModalLayout,
    ButtonPrimary,
    ButtonTertiary,
    SpinLoadingVue,
    CircleCheckIcon,
    CircleCloseIcon
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const $pusher = inject("$pusher");
    const userWallet = computed(() => store.getters.userWalletAmount);
    const user = computed(() => store.state.User.user);
    const personalInfoIsCompeleted = computed(() => store.getters.personalInfoIsCompeleted);
    const userTimeZone = computed(() => store.getters.userTimeZone);
    const DepositModal = ref(null);
    // const PaymentModal = ref(null);
    const TransactionSuccessModal = ref(null);
    const TransactionFailedModal = ref(null);
    // const paymentMethods = ref([
    // { name: "Visa/Master Card", value: 'VISA_CARD', icon: "Card.svg" },
    // { name: "Paypal", value: 'PAYPAL', icon: "PayPal.svg" },
    // { name: "Bitcoin", network: 'BTC', to_currency: 'BTC', icon: "Bitcoin.svg" },
    // { name: "Etherium", network: 'ETH', icon: "Ethereum.svg" },
    // ]);
    const depositAmounts = ref([5, 10, 15, 20, 30, 50]);
    const defaultDeposit = computed(() => store.state.User.defaultDeposit);
    const amount = ref(5);
    const customAmount = ref(null);
    // const selectedPaymentMethod = ref(null);
    const submitPaymentDisabled = ref(false);
    const submitPaymentLoading = ref(false);
    const isEmailVerified = computed(() => store.getters.isEmailVerified);
    const transaction = ref(null);
    const newWindow = ref(null);
    const newWindowStore = computed(() => store.state.Common.newWindow);
    const isInCreateMatch = computed(() => route.name == 'game-matches' && route.query.active);


    onMounted(async () => {
      initTransactionPusher();
      // if (route.query?.redirect) {
      //   let query = { ...route.query }
      //   delete query.redirect
      //   router.push({
      //     name: route.name,
      //     query: query
      //   });
      // }
    });

    onUnmounted(() => {
      $pusher.leave(`transaction-is-done-user-id-${user.value?.id}`);
    });


    watch(defaultDeposit, () => {
      if (defaultDeposit.value) {
        const depositAmount = depositAmounts.value.filter(amount => amount == defaultDeposit.value);
        if (depositAmount.length) {
          amount.value = depositAmount[0];
          customAmount.value = null;
        } else {
          customAmount.value = defaultDeposit.value;
          amount.value = defaultDeposit.value;
        }
      } else {
        amount.value = 5;
        customAmount.value = null;
      }
    }, {
      immediate: true
    });

    const initTransactionPusher = () => {
      $pusher.private(
        `transaction-is-done-user-id-${user.value?.id}`
      )
        .listen(TransactionIsDoneEvent, async (data) => {
          newWindow.value?.close();
          newWindowStore.value?.close();
          transaction.value = data.transaction;
          if (transaction.value.status == 'paid' ||
            transaction.value.status == 'paid_over' ||
            transaction.value.status == 'wrong_amount' ||
            transaction.value.status == 'wrong_amount_waiting'
          ) {
            $vfm.show('TransactionSuccessModal');
          }
          if (
            transaction.value.status == 'fail' ||
            transaction.value.status == 'cancel' ||
            transaction.value.status == 'system_fail' ||
            transaction.value.status == 'refund_fail'
          ) {
            $vfm.show('TransactionFailedModal');
          }
        });
    };

    const checkInteger = (e) => {
      isInteger(e);
    };


    const doPay = async () => {
      if (!personalInfoIsCompeleted.value) {
        return $vfm.show('PersonalInfoNotCompeletedModal');
      }
      submitPaymentLoading.value = true;

      const response = await depositApi({
        amount: amount.value,
        url_return: window.location.href,
      });
      if (response) {
        // router.push({
        //   name: route.name,
        //   query: {
        //     redirect: true,
        //   }
        // });
        $vfm.hide('TransactionFailedModal');
        $vfm.hide('DepositModal');
        const y = window.top.outerHeight / 2 + window.top.screenY - (600 / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (800 / 2);
        newWindow.value = window.open(response.data.url, "cryptomus", `width=800,height=900,top=${y},left=${x}`);


        // window.location.href = response.data.url;
      }
      submitPaymentLoading.value = false;
    };

    const rePay = () => {
      amount.value = transaction.value?.amount;
      doPay();
    }

    // const goToPayment = () => {
    //   $vfm.hide('DepositModal');
    //   if (!isEmailVerified.value) {
    //     return $vfm.show('EmailIsNotVeriffiedModal');
    //   }
    //   $vfm.show('PaymentModal');
    // };

    const redirectToFindMatch = () => {
      $vfm.hide('TransactionSuccessModal');
      router.push({
        name: 'find-match'
      })
    };

    return {
      DepositModal,
      // PaymentModal,
      TransactionSuccessModal,
      TransactionFailedModal,
      // paymentMethods,
      amount,
      customAmount,
      userWallet,
      userTimeZone,
      // selectedPaymentMethod,
      submitPaymentDisabled,
      submitPaymentLoading,
      depositAmounts,
      isEmailVerified,
      transaction,
      isInCreateMatch,
      checkInteger,
      doPay,
      converUtcTimezoneToDesiredTimezone,
      redirectToFindMatch,
      rePay
      // goToPayment
    }
  }
}
</script>
