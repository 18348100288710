import { arraySum, filled } from "@/helper";
import { supportApi } from "@/service/api/ChatApi";

const state = {
  unreadArchive: [],
  unreadMessageArchive: [],
  newMessage: null,
  support: null,
  openOnlineChat: false,
};

const getters = {
  chatUnreadCount: (state) => {
    const count = arraySum(
      state.unreadArchive?.map((room) => room.unread_count)
    );
    return count > 9 ? "+9" : count;
  },
};

const actions = {
  async setChatUnreadArchiveItem({ commit }, data) {
    commit("_setChatUnreadArchiveItem", data);
  },
  async setUnreadMessageArchiveItem({ commit }, data) {
    commit("_setUnreadMessageArchiveItem", data);
  },
  async setChatUnreadArchive({ commit }, data) {
    commit("_setChatUnreadArchive", data);
  },
  async setNewMessage({ commit }, data) {
    commit("_setNewMessage", data);
  },
  async setSupport({ commit, getters, dispatch }, data) {
    const token = getters.isAuthenticated
      ? null
      : getters.onlineSupport?.support_token;
    const response = await supportApi(token);

    if (response) {
      dispatch("setOnlineSupportDetail", response.data);

      commit("_setSupport", response?.data);
    } else {
      dispatch("removeSupport");
    }
  },
  async removeSupport({ commit, getters, dispatch }, data) {
    dispatch("setOnlineSupportDetail", null);
    commit("_setSupport", null);
  },
  async openChat({ commit }, data) {
    commit("_openChat", data);
  },
};

const mutations = {
  _setNewMessage: (state, data) => {
    state.newMessage = data;
  },
  _setChatUnreadArchive: (state, data) => {
    state.unreadArchive =
      data
        ?.filter((d) => d.unread_count > 0)
        .map((d) => {
          return {
            room_uuid: d.room_uuid,
            unread_count: d.unread_count,
            unread_count_prettified: d.unread_count > 9 ? "+9" : d.unread_count,
            message: d.latest_message?.message,
            room_name: data.message_detail?.user_data?.username,
          };
        }) ?? [];
  },
  _setChatUnreadArchiveItem: (state, data) => {
    state.unreadArchive =
      state.unreadArchive?.filter(
        (r) => r.room_uuid !== data.unread_detail.room_uuid
      ) ?? [];

    const newData = {
      room_uuid: data.unread_detail.room_uuid,
      unread_count: data.unread_detail.unread_count,
      unread_count_prettified:
        data.unread_detail.unread_count > 9
          ? "+9"
          : data.unread_detail.unread_count,
      message: data.message_detail.message,
      room_name: data.message_detail?.user_data?.username,
    };
    state.unreadArchive.push(newData);
  },
  _setUnreadMessageArchiveItem: (state, data) => {
    state.unreadMessageArchive = state.unreadMessageArchive.filter(
      (r) => r.room_uuid !== data.room_uuid
    );
    state.unreadMessageArchive.push(data);
  },
  _setSupport: (state, data) => {
    state.support = data;
  },
  _openChat: (state, data) => {
    state.openOnlineChat = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
