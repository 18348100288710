import axios from "axios";
import { toast } from "@/utilities/Toaster";
import store from "@/store";
import { filled } from "@/helper";
import { $vfm } from "vue-final-modal";

let statusCodesErrors = [];
let timeout = null;

const configs = {
  baseURL: process.env.VUE_APP_API_SERVER,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "content-Type": "application/json",
  },
  validateStatus: (status) => {
    return status >= 200 && status < 400;
  },
  // paramsSerializer: {
  //     serialize: (params) =>
  //         Qs.stringify(params, { arrayFormat: "brackets", encode: false }),
  // },
};
const storageConfigs = {
  baseURL: process.env.VUE_APP_API_STORAGE,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "multipart/form-data",
  },
  validateStatus: (status) => {
    return status >= 200 && status < 400;
  },
  // paramsSerializer: {
  //     serialize: (params) =>
  //         Qs.stringify(params, { arrayFormat: "brackets", encode: false }),
  // },
};

const handleError = (error) => {
  return Promise.reject(error);
};

export const api = axios.create(configs);

export const authApi = axios.create(configs);

export const storageApi = axios.create(storageConfigs);

export const uploadApi = axios.create(configs);

api.interceptors.response.use(
  (response) => {
    showResponse(response);
    return response;
  },
  (error) => {
    showError(error);
    return error;
  }
);

const getinfo = () => {
  const token = store.state.User?.token;
  return {
    token: token ?? "",
  };
};
const getUploadToken = () => {
  const token = store.getters?.authToken;
  return {
    token: token ?? "",
  };
};

authApi.interceptors.request.use((config) => {
  const { token } = getinfo();
  config.headers = { ...config.headers, authorization: token };

  return config;
}, handleError);

authApi.interceptors.response.use(
  (response) => {
    showResponse(response);
    return response;
  },
  (error) => {
    showError(error);
    return error;
  }
);

storageApi.interceptors.request.use((config) => {
  const { token } = getUploadToken();
  config.headers = {
    ...config.headers,
    authorization: token,
    // "Access-Control-Allow-Headers": "Authorization, content-type",
    // "Access-Control-Allow-Origin": "*",
  };

  return config;
}, handleError);

storageApi.interceptors.response.use(
  (response) => {
    showResponse(response);
    return response;
  },
  (error) => {
    showError(error);
    return error;
  }
);

uploadApi.interceptors.request.use((config) => {
  const { token } = getinfo();
  config.headers = {
    ...config.headers,
    authorization: token,
    "Content-Type": "multipart/form-data",
  };

  return config;
}, handleError);

uploadApi.interceptors.response.use(
  (response) => {
    showResponse(response);
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch("logout");
    }
    showError(error);
    return error;
  }
);

const showResponse = (response) => {
  const messages = [];

  switch (response?.status) {
    case 401:
      store.dispatch("logout");
      break;
    case 200:
      response?.data?.message ? messages.push(response?.data?.message) : null;
      break;
    case 201:
      response?.data?.message ? messages.push(response?.data?.message) : null;
      break;
    default:
      messages.push(` ${response.status}`);
      break;
  }
  messages.map((message) => toast(message, "success"));
};

const showError = ({ response }) => {
  const messages = [];
  if (!filled(response)) {
    let error = "Network Error!";
    if (codeAlreadyExist(error)) return;
    statusCodesErrors.push(error);
    messages.push(error);
  } else {
    switch (response?.status) {
      case 422:
        if (response.data?.error) {
          messages.push(String(response.data?.error));
        }
        if (filled(response.data?.errors)) {
          let text = Object.values(response.data.errors);
          let keys = Object.keys(response.data.errors);

          store.dispatch("setApiErrors", response.data.errors);

          if (
            !keys.includes("email") &&
            !keys.includes("password") &&
            !keys.includes("mobile_number")
          ) {
            keys.forEach((k) => {
              document
                .getElementById(k)
                ?.classList.remove("changeColorWhenValid");
              document
                .getElementById(k)
                ?.classList.add("changeColorWhenInvalid");
            });

            text.forEach((t) => {
              messages.push(String(t));
            });
          }
        }
        break;
      case 400:
        if (response.data?.error) {
          messages.push(String(response.data?.error));
        }
        if (response?.data?.errors) {
          let keys = Object.keys(response.data.errors);
          keys.forEach((k) => {
            document
              .getElementById(k)
              ?.classList.remove("changeColorWhenValid");
            document.getElementById(k)?.classList.add("changeColorWhenInvalid");
          });
        }
        messages.push(response?.data?.message ?? `Error ${response.status}`);
        break;
      case 401:
        if (store.state.User.token) {
          store.dispatch("logout");
        }
        break;
      case 402:
        if (
          response?.data?.message?.includes(
            "You are restricted because of fine."
          )
        ) {
          store.dispatch("getProfile", response.data.errors);

          $vfm.show("suspendedModal");
        } else if (response?.data?.message) {
          messages.push(response?.data?.message);
        }
        break;
      case 403:
        if (response?.data?.message?.includes("You are banned.")) {
          $vfm.show("bannedModal");
        } else {
          if (codeAlreadyExist(response?.status)) return;
          messages.push(response?.data?.message ?? "Unauthorized");
        }
        break;
      case 419:
        if (codeAlreadyExist(response?.status)) return;
        messages.push(response?.data?.message ?? `Error ${response.status}`);
        break;
      case 409:
        if (codeAlreadyExist(response?.status)) return;
        messages.push(response?.data?.message ?? `Error ${response.status}`);
        break;
      case 413:
        if (codeAlreadyExist(response?.status)) return;
        messages.push(response?.data?.message ?? "Entity is too big");
        break;
      default:
        break;
    }
    statusCodesErrors.push(response?.status);
  }
  clearStatusCodesErrors();
  messages.map((message) => toast(message, "error"));
};

const codeAlreadyExist = (code) => {
  return statusCodesErrors.filter((c) => c == code).length;
};

const clearStatusCodesErrors = () => {
  if (filled(timeout)) return;
  timeout = setTimeout(() => {
    statusCodesErrors = [];
    timeout = null;
  }, 10000);
};
