<template>
  <header class="sticky top-0 left-0 z-[10]">
    <div class="py-[8px] lg:py-0 bg-background-white-light dark:bg-background-white-dark flex items-center">
      <div class="container items-center grid grid-cols-4 lg:grid-cols-12 lg:gap-[20px]">
        <!-- btn show menu responsive -->
        <div class="col-span-1 flex items-center lg:hidden">
          <button @click="openSidebar = 1">
            <i class="icon-Hum-Menu text-[30px] dark:text-text-white-light" id="sidebarMobileIcon"></i>
          </button>
        </div>
        <!-- logo -->
        <div class="col-span-2 xl:col-span-2 grid place-items-center lg:place-items-baseline">
          <router-link to="/">
            <img src="@/assets/images/logo/dark.svg"
              class="w-[162px] lg:w-[200px] lg:max-h-[43px] xl:w-[253px] hidden dark:block" />
            <img src="@/assets/images/logo/light.svg"
              class="w-[162px] lg:w-[200px] lg:max-h-[43px] xl:w-[253px] dark:hidden block" />
          </router-link>
        </div>
        <!-- menu -->
        <MenuDesktop :menuItem="menuItem" />
        <!--  -->
        <div
          class="col-span-4 xl:col-span-4 hidden lg:grid grid-cols-8 self-stretch items-center justify-end gap-[20px]">
          <!-- message and alert icons -->
          <div class="col-span-2 flex self-stretch justify-end">
            <HeaderChat />
            <NotificationDesktop :user-notification-count="userNotificationCount" :userNotifications="userNotifications"
              :hasActionNotification="hasActionNotification" />
          </div>

          <!-- wallet -->
          <button
            class="overflow-hidden col-span-5 grid grid-cols-2 transition-all items-center gap-[20px] bg-tertiary-light dark:bg-tertiary-dark rounded px-[12px] group relative hover:bg-background-white-light dark:hover:bg-background-white-dark">
            <div class="flex items-center gap-[12px]">
              <i class="icon-Wallet-Stroke text-[30px]"></i>
              <span class="font-medium text-lg">
                <span>$</span>
                <span>{{ walletAmount }}</span>
              </span>
            </div>
            <div class="flex items-center gap-[12px]">
              <svg class="h-[43px] transition-all" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4812_36200)">
                  <path
                    d="M12.627 16.892C12.595 17.02 12.563 17.196 12.531 17.42L3.09904 16.412H12.699L12.627 16.892ZM12.363 18.716C12.315 19.084 12.291 19.42 12.291 19.724L2.83504 18.716H12.363ZM13.275 14.108L12.987 15.092L3.67504 14.108H13.275ZM12.315 21.02C12.379 21.468 12.435 21.804 12.483 22.028L3.14704 21.02H12.315ZM14.259 11.804C14.035 12.22 13.875 12.54 13.779 12.764L4.80304 11.804H14.259ZM13.035 23.348C13.243 23.716 13.483 24.012 13.755 24.236L5.45104 23.348H13.035ZM16.539 9.5C16.075 9.788 15.715 10.052 15.459 10.292L8.11504 9.5H16.539ZM18.651 24.86C16.635 24.86 15.187 24.468 14.307 23.684C13.443 22.9 13.011 21.692 13.011 20.06C13.011 19.372 13.123 18.356 13.347 17.012C13.827 14.26 14.651 12.26 15.819 11.012C17.003 9.764 18.867 9.14 21.411 9.14C22.003 9.14 22.675 9.204 23.427 9.332C24.195 9.444 24.755 9.588 25.107 9.764L23.763 13.436C22.819 13.212 21.931 13.1 21.099 13.1C20.283 13.1 19.699 13.18 19.347 13.34C18.995 13.5 18.779 13.82 18.699 14.3L17.595 20.588C18.171 20.716 18.763 20.78 19.371 20.78C20.667 20.78 21.851 20.588 22.923 20.204L22.539 24.236C21.515 24.652 20.219 24.86 18.651 24.86Z"
                    class="fill-text-primary-light dark:fill-text-primary-dark" />
                </g>
                <defs>
                  <clipPath id="clip0_4812_36200">
                    <rect width="32" height="32" fill="white" transform="translate(0.210449)" />
                  </clipPath>
                </defs>
              </svg>

              <span class="font-medium text-lg">{{ bonusAmount }}</span>
            </div>
            <!-- hover -->
            <span @click="$vfm.show('DepositModal')"
              class="transition-[backdrop,opacity] group-hover:backdrop-blur-md opacity-0 group-hover:opacity-100 group-hover:shadow-sm dark:group-hover:shadow-darkSm absolute top-0 left-0 w-full h-full grid place-items-center bg-transparent">
              <span class="flex items-center gap-[12px]">
                <i class="icon-Add-2-Stroke text-3xl"></i>
                <span class="font-medium text-lg">Deposit</span>
              </span>
            </span>
          </button>
          <!-- btn change mode -->
          <div class="col-span-1">
            <ChangeModeBtnVue />
          </div>
        </div>
        <!-- alert icon for PWA -->
        <div class="col-span-1 lg:hidden grid place-items-center">
          <router-link :to="{ name: 'notification' }"
            class="btn-hover rounded  group-hover:btn-active justify-self-end p-[5px]">
            <i class="icon-Notification-Stroke text-[26px] relative h-[25px] block">
              <span v-show="userNotificationCount"
                class="absolute bottom-0 right-0 bg-alert text-text-white-light rounded w-[12px] h-[12px] text-xs grid place-items-center">
                {{ userNotificationCount }}
              </span>
            </i>
          </router-link>
        </div>

        <!-- mobile sidebar nav -->

      </div>
    </div>
    <!-- floating Navbar -->
    <div
      class="lg:hidden border-t border-border-light dark:border-border-dark z-[-1] fixed bottom-0 left-0 w-full py-[8px] px-[20px] bg-background-white-light dark:bg-background-white-dark">
      <div class="grid grid-cols-4 items-center gap-[12px] max-w-[350px] mx-auto">
        <router-link v-for="item in floatMenu" :to="item.link" :key="item" class="flex flex-col gap-[2px] items-center">
          <i :class="{
            [item.icon]: item.icon,
            'btn-active': $route.name == item.linkName
          }" class="text-3xl btn-hover p-[5px] rounded relative">
            <span v-show="item.linkName == 'chats' && chatCount"
              class="absolute bottom-[3px] right-[3px] bg-alert text-text-white-light rounded-[2px] w-[12px] h-[12px] text-xs grid place-items-center">
              {{ chatCount }}
            </span>
            <span v-show="item.linkName == 'open-matches' && openMatchCounts"
              class="absolute bottom-[3px] right-[3px] bg-alert text-text-white-light rounded-[2px] w-[12px] h-[12px] text-xs grid place-items-center">
              {{ openMatchCounts }}
            </span>
          </i>
          <span
            :class="{ '!text-primary dark:!text-secondary': $route.name == item.linkName || $route.name.includes(item.linkName) }"
            class="text-xs">{{ item.name }}</span>
        </router-link>
      </div>
    </div>

    <DepositWallet />
    <PersonalInfoIsNotCompletedModal />
    <BannedModal />
    <SuspendedModal />
    <EmailIsNotVeriffiedModal />
    <GamerTagModal />
    <Teleport to="body">
      <VueFinalModal name="LogoutConfirmModal" v-model="LogoutConfirmModal">
        <ModalLayout :hasBtnOnBottom="true" modalName="LogoutConfirmModal"
          class="!z-[15] text-text-white-dark dark:text-text-white-light">
          <template #title>
            <div class="flex items-center gap-2">
              <i class="icon-Logout-Stroke text-2xl"></i>
              <div class="text-md !font-[500]">Logout</div>
            </div>
          </template>
          <p class="pb-[30px]">
            Are you sure you want to leave?
          </p>
          <div
            class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark flex justify-end gap-[12px]">
            <ButtonPrimary size="sm" @click="$vfm.hide('LogoutConfirmModal')">
              Cancel
            </ButtonPrimary>
            <ButtonBorder size="sm" @click="doLogout">
              <div class="flex gap-1">
                <span>Logout</span>
              </div>
            </ButtonBorder>
          </div>
        </ModalLayout>
      </VueFinalModal>
    </Teleport>
  </header>
  <SidebarMoblie :open="openSidebar" id="sidebarMobile" @onClose="onClose" v-click-outside="onClose">
    <div class="grid grid-cols-1 gap-[20px]">
      <div class="grid grid-cols-3">
        <router-link :to="{ name: 'personal-info' }" @click="onClose"
          class="col-span-1 block relative overflow-hidden w-[48px] h-[50px] bg-background-main-light dark:bg-background-main-dark p-[2.5px] rounded">
          <img :src="photo" class="object-cover rounded w-full h-full" />

          <span
            class="absolute right-0 bottom-0 w-[15px] h-[15px] bg-background-main-light dark:bg-background-main-dark rounded-tl grid place-items-center">
            <i class="icon-Edit-Stroke text-[10px]" />
          </span>
        </router-link>

        <div class="col-span-2 flex flex-col">
          <span class="font-medium text-sm">
            {{ fullName }}
          </span>
          <span class="font-thin text-[10px] text-text-secondary-light dark:text-text-secondary-dark">
            {{ slogan }}
          </span>
        </div>
      </div>

      <button
        class="overflow-hidden grid grid-cols-1 items-center gap-[12px] bg-tertiary-light dark:bg-tertiary-dark rounded p-[12px] transition-all group relative hover:bg-background-white-light dark:hover:bg-background-white-dark">
        <span class="text-text-secondary-light dark:text-text-secondary-dark text-sm text-left">
          Wallet Detail:
        </span>
        <div
          class="flex items-center rounded gap-[12px] px-[12px] py-[8px] bg-background-white-light dark:bg-background-white-dark">
          <i class="icon-Wallet-Stroke text-[30px]"></i>
          <span class="font-medium text-lg">
            <span>$</span>
            <span>
              {{ walletAmount }}
            </span>
          </span>
        </div>
        <div
          class="flex items-center rounded gap-[5px] px-[12px] bg-background-white-light dark:bg-background-white-dark">
          <svg class="h-[40px] transition-all" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4812_36200)">
              <path
                d="M12.627 16.892C12.595 17.02 12.563 17.196 12.531 17.42L3.09904 16.412H12.699L12.627 16.892ZM12.363 18.716C12.315 19.084 12.291 19.42 12.291 19.724L2.83504 18.716H12.363ZM13.275 14.108L12.987 15.092L3.67504 14.108H13.275ZM12.315 21.02C12.379 21.468 12.435 21.804 12.483 22.028L3.14704 21.02H12.315ZM14.259 11.804C14.035 12.22 13.875 12.54 13.779 12.764L4.80304 11.804H14.259ZM13.035 23.348C13.243 23.716 13.483 24.012 13.755 24.236L5.45104 23.348H13.035ZM16.539 9.5C16.075 9.788 15.715 10.052 15.459 10.292L8.11504 9.5H16.539ZM18.651 24.86C16.635 24.86 15.187 24.468 14.307 23.684C13.443 22.9 13.011 21.692 13.011 20.06C13.011 19.372 13.123 18.356 13.347 17.012C13.827 14.26 14.651 12.26 15.819 11.012C17.003 9.764 18.867 9.14 21.411 9.14C22.003 9.14 22.675 9.204 23.427 9.332C24.195 9.444 24.755 9.588 25.107 9.764L23.763 13.436C22.819 13.212 21.931 13.1 21.099 13.1C20.283 13.1 19.699 13.18 19.347 13.34C18.995 13.5 18.779 13.82 18.699 14.3L17.595 20.588C18.171 20.716 18.763 20.78 19.371 20.78C20.667 20.78 21.851 20.588 22.923 20.204L22.539 24.236C21.515 24.652 20.219 24.86 18.651 24.86Z"
                class="fill-text-primary-light dark:fill-text-primary-dark" />
            </g>
            <defs>
              <clipPath id="clip0_4812_36200">
                <rect width="32" height="32" fill="white" transform="translate(0.210449)" />
              </clipPath>
            </defs>
          </svg>

          <span class="font-medium text-lg">
            {{ bonusAmount }}
          </span>
        </div>
        <!-- hover -->
        <span @click="$vfm.show('DepositModal')"
          class="transition-all group-hover:backdrop-blur-md opacity-0 group-hover:opacity-100 group-hover:shadow-sm dark:group-hover:shadow-darkSm  absolute top-0 left-0 w-full h-full grid place-items-center">
          <span class="flex items-center gap-[12px]">
            <i class="icon-Add-2-Stroke text-3xl"></i>
            <span class="font-medium text-lg">Deposit</span>
          </span>
        </span>
      </button>
      <MobileNav :menuItem="SidebarMenuItem" @close-sidebar="onClose" />

      <div class="flex items-center justify-between">
        <span>theme mode:</span>
        <ChangeModeBtnVue :horizental="true" />
      </div>

      <div class="grid grid-cols-4 gap-[12px]">
        <router-link v-for="Icon in socialMedia" :to="Icon.link" :key="Icon.link">
          <img :src="Icon.icon" />
        </router-link>
      </div>
    </div>
  </SidebarMoblie>
</template>

<script>
import { ref, watch, computed, inject, onMounted, onUnmounted } from "vue";
import MenuDesktop from "./desktop/MenuDesktop.vue";
import ChangeModeBtnVue from "@/components/layout/common/header/ChangeModeBtn.vue";
import SidebarMoblie from "@/components/layout/mobile/SidebarMoblie.vue";
import MobileNav from "./mobile/MobileNav.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import NotificationDesktop from "./desktop/NotificationDesktop.vue";
import HeaderChat from "./desktop/HeaderChat.vue";
import DepositWallet from "@/components/pages/panel/Wallet/Deposit.vue"
import GamerTagModal from "@/components/pages/panel/GamerTagModal.vue";
import { askPushNotificationPermission, sendPushNotification } from "@/helper";
import { actionNotifications } from "@/helper/constant";
import {
  UpdateUserWalletEvent,
  broadcastNotificationEvent,
  OpenMatchesCountChangedEvent
} from "@/service/channels";
import { OPEN_MATCH_NOTIFICATIONS, RELOAD_USER_NOTIFICATIONS } from "@/helper/constant";
import { initChat } from "@/service/Ws";
import { getRoomsUnreadMessagesApi } from "@/service/api/ChatApi";
import PersonalInfoIsNotCompletedModal from "@/components/pages/panel/PersonalInfoIsNotCompletedModal.vue";
import EmailIsNotVeriffiedModal from "@/components/pages/panel/EmailIsNotVeriffiedModal.vue";
import BannedModal from "@/components/pages/panel/BannedModal.vue";
import SuspendedModal from "@/components/pages/panel/SuspendedModal.vue";
import ModalLayout from "@/components/modal/ModalLayout.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import ButtonBorder from "@/components/form-component/buttons/ButtonBorder.vue";
import { $vfm } from "vue-final-modal";
import {
  MOBILE_SIDEBAR_MENU_WHEN_AUTH,
  SOCIAL_MEDIA,
  HEADER_MENU_WHEN_AUTH,
  MOBILE_BOTTOM_MENU_WHEN_AUTH
} from "@/helper/constant";
import { getOpenMatchCountApi } from "@/service/api/OpenMatchApi";

export default {
  name: "PublicHeaderLayout",
  props: ["showchat"],
  components: {
    MenuDesktop,
    ChangeModeBtnVue,
    SidebarMoblie,
    MobileNav,
    NotificationDesktop,
    HeaderChat,
    DepositWallet,
    PersonalInfoIsNotCompletedModal,
    EmailIsNotVeriffiedModal,
    ModalLayout,
    ButtonPrimary,
    ButtonBorder,
    BannedModal,
    SuspendedModal,
    GamerTagModal
  },

  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const userNotificationCount = computed(() => store.state.User.notificationCount);
    const SidebarMenuItem = ref(MOBILE_SIDEBAR_MENU_WHEN_AUTH);
    const socialMedia = ref(SOCIAL_MEDIA);
    const floatMenu = ref(MOBILE_BOTTOM_MENU_WHEN_AUTH);
    const menuItem = ref(HEADER_MENU_WHEN_AUTH);
    const $pusher = inject("$pusher");
    const openSidebar = ref(0);
    const itemFocus = ref(true);
    const showChat = ref(false);
    const initWs = ref();
    const routeName = computed(() => route.name);
    const routeSection = computed(() => route.meta?.SectionName);
    const authToken = computed(() => store.getters.authToken);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const chatCount = computed(() => store.getters.chatUnreadCount);
    const fullName = computed(() => store.getters.userFullName);
    const slogan = computed(() => store.getters.userSlogan);
    const photo = computed(() => store.getters.userPhoto);
    const walletAmount = computed(() => store.getters.userWalletAmount);
    const bonusAmount = computed(() => store.getters.userBonusAmount);
    const user = computed(() => store.state.User.user);
    const unreadsArchive = computed(() => store.state.Chat.unreadArchive);
    const openMatchCounts = computed(() => store.state.User.openMatchCounts);
    const userId = computed(() => user.value?.id);
    const userNotifications = computed(() => store.state.User.unreadNotifications);
    const hasActionNotification = computed(() =>
      userNotifications.value?.filter((notification) =>
        actionNotifications.includes(notification.target_action)
      ).length > 0
    );
    const wsIsOpened = ref(false);
    const wsInterval = ref(null);
    const LogoutConfirmModal = ref(false);


    onMounted(() => {
      if (userId.value) {
        initiatePusherNotification();
        initiatePusherOpenMatchCount();
        initiateWs();
        initUpdateUserWallet();
        checkWsIsOpen();
        askPushNotificationPermission();
        getUserNotificationsCount();
        getUserOpenMatchCount();
        getNotifications();
        getRoomsUnreadMessages();
      }
      // TODO call unread room api
      // sendPushNotification([
      //   {
      //     title: 'hi'
      //   }
      // ])

    });

    onUnmounted(() => {
      initWs.value.close();
      $pusher.leave(`notifications-${user.value?.id}`);
      $pusher.leave(`open-matches-count-user-id-${user.value?.id}`);
      $pusher.leave(`updated-wallet-user-id-${user.value?.id}`);
    });

    watch(userId, () => {
      if (userId.value) {
        initiatePusherNotification();
        initiatePusherOpenMatchCount();
        initiateWs();
        initUpdateUserWallet();
        checkWsIsOpen();
      }
    });

    const initiatePusherNotification = () => {
      $pusher.private(
        `notifications-${user.value?.id}`
      )
        .listen(broadcastNotificationEvent, async (data) => {
          if (data.notification.target_action == 'BATTLE_HAS_BEEN_DONE') {
            setMatchChatToRead(data.notification)
          }
          if (OPEN_MATCH_NOTIFICATIONS.filter(action => action == data.notification?.target_action).length) {
            sendPushNotification(data.notification?.title)
          }
          getUserNotificationsCount();
          setHasNewNotification();
          getNotifications();

          if (RELOAD_USER_NOTIFICATIONS.filter(action => action == data.notification?.target_action).length) {
            store.dispatch("getProfile");
          }
        });
    };

    const initiatePusherOpenMatchCount = () => {
      $pusher.private(
        `open-matches-count-user-id-${user.value?.id}`
      )
        .listen(OpenMatchesCountChangedEvent, async (data) => {
          getUserOpenMatchCount();
        });
    };

    const initUpdateUserWallet = () => {
      $pusher.private(
        `updated-wallet-user-id-${user.value?.id}`
      )
        .listen(UpdateUserWalletEvent, async (data) => {
          store.dispatch("getProfile");
        });
    };

    const initiateWs = () => {
      if (initWs.value) initWs.value.close();
      initWs.value = initChat();
      initWs.value.addEventListener('open', (event) => {
        onWebsocketOpen();
      });
      initWs.value.addEventListener('message', (event) => {
        handleWebSocketMessage(event);
      });
    };

    const setMatchChatToRead = (notification) => {
      store.dispatch(
        "setChatUnreadArchive",
        unreadsArchive.value.filter((r) => r.room_uuid !== notification.data.room_uuid)
      );
    };

    const checkWsIsOpen = () => {
      wsInterval.value = setInterval(() => {
        if (!wsIsOpened.value && userId.value) {
          if (initWs.value) initWs.value.close();
          initWs.value = initChat();
          initiateWs();
        } else {
          clearInterval(wsInterval.value)
        }

      }, 15000);
    };

    const onWebsocketOpen = () => {
      wsIsOpened.value = true;
      const authMessage = JSON.stringify({
        authHeader: authToken.value,
      });
      initWs.value.send(authMessage);
    };

    const handleWebSocketMessage = (event) => {
      try {
        let data = JSON.parse(event.data);

        if (parseInt(data?.unread_detail?.unread_count) > 0) {
          store.dispatch('setChatUnreadArchiveItem', {
            unread_detail: data?.unread_detail,
            message_detail: data.message_detail
          });
        }
        // if (filled(data.message_detail)) {
        //   store.dispatch('setNewMessage', data.message_detail);
        //   store.dispatch('setUnreadMessageArchiveItem', data.message_detail);
        // }

      } catch (error) {
        // console.error('Error parsing JSON:', error);
      }
    };

    const setHasNewNotification = () => {
      if (routeName.value === 'notification') {
        store.dispatch('setHasNewNotification', true);
      }
    }


    const onClose = (e) => {
      if (openSidebar.value && e?.target?.id !== 'sidebarMobileIcon') {
        openSidebar.value = 0;
      }
    };

    const getNotifications = async () => {
      store.dispatch('UpdateUserUnreadNotifications');
      // hasActionNotification.value = userNotifications.value?.filter((notification) =>
      //   actionNotifications.includes(notification.target_action)
      // ).length > 0
    };

    const getUserNotificationsCount = async () => {
      store.dispatch('UpdateUserNotificationsCount');
    };

    const getUserOpenMatchCount = async () => {
      const response = await getOpenMatchCountApi();
      if (response) {
        store.dispatch('setOpenMatchCounts', response.data.open_matches_count);
      }
    };

    const getRoomsUnreadMessages = async () => {
      const response = await getRoomsUnreadMessagesApi();
      store.dispatch('setChatUnreadArchive', response);
    };

    const doLogout = async () => {
      $vfm.hide('LogoutConfirmModal');
      store.dispatch("logout");
    };



    return {
      LogoutConfirmModal,
      itemFocus,
      showChat,
      socialMedia,
      floatMenu,
      menuItem,
      SidebarMenuItem,
      walletAmount,
      bonusAmount,
      openSidebar,
      routeName,
      fullName,
      slogan,
      photo,
      isAuthenticated,
      routeSection,
      userNotifications,
      hasActionNotification,
      userNotificationCount,
      chatCount,
      openMatchCounts,
      doLogout,
      onClose,
      getUserNotificationsCount
    };
  },
};
</script>
