<template>
  <button
    :class="{ [`btn-${size}`]: size, [ClassName]: ClassName }"
    class="bg-tertiary-light dark:bg-tertiary-dark text-text-primary-light
     dark:text-text-primary-dark rounded transition-all
      hover:shadow-sm dark:hover:shadow-darkSm flex items-center justify-center gap-[8px]
       hover:bg-background-main-light dark:hover:bg-background-main-dark"
  >
    <span :class="spanClass">
      <slot />
    </span>

    <slot name="icon" />
  </button>
</template>

<script>
import { computed } from "vue";
export default {
  name: "ButtonTertiary",
  props: ["size", "class", "spanClass"],

  setup(props) {
    const ClassName = computed(() => props.class);

    return {
      ClassName,
    };
  },
};
</script>
