import toaster, { toast } from "@/utilities/Toaster";
import moment from "moment";

let inputErrorShowed = false;

export const pluck = (arr, key) => arr.map((i) => i[key]);

export const countDownByDHMS = (fromDate, toDate) => {
  var timeleft = fromDate - toDate;

  if (timeleft < 0) {
    return 0;
  } else {
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    if (days > 0) {
      return days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
    } else {
      return hours + "h " + minutes + "m " + seconds + "s ";
    }
  }
};

export const countDownByDHM = (fromDate, toDate) => {
  var timeleft = fromDate - toDate;

  if (timeleft < 0) {
    return 0;
  } else {
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    return days + "d " + hours + "h " + minutes + "m ";
  }
};

export const countDownByHMS = (fromDate, toDate) => {
  var timeleft = fromDate - toDate;

  if (timeleft < 0) {
    return 0;
  } else {
    var hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

    var hoursPrettified = hours < 10 ? "0" + hours : hours;
    var minutesPrettified = minutes < 10 ? "0" + minutes : minutes;
    var secondsPrettified = seconds < 10 ? "0" + seconds : seconds;

    return hoursPrettified + ":" + minutesPrettified + ":" + secondsPrettified;
  }
};

export const isInteger = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 96 && charCode < 106) {
    return true;
  } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
    return false;
  } else {
    return true;
  }
};

export const gamerTagValidation = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode == 32) {
    evt.preventDefault();
  } else {
    return true;
  }
};

export const fileAddress = (media) => {
  return (
    "http://127.0.0.1:8000/storage/" +
    media.path +
    media.prefix +
    media.separator +
    media.name +
    "." +
    media.extension
  );
};

export const maxLength = (e, max = 1) => {
  if (e.target.value.length > max) {
    e.preventDefault();
  } else {
    return true;
  }
};

export const FirstLetterCap = (string) => {
  return string.replaceAll("-", " ");
};

export const strReplace = (string, replaceFrom, replaceTo) => {
  return string.replaceAll(replaceFrom, replaceTo);
};

export const getUtcTime = (string) => {
  return new Date(Date.now() + new Date().getTimezoneOffset() * 60000);
};

export const filled = (data) => {
  return !!(data && data !== "" && data !== null);
};

export const photoValidation = (event) => {
  const file = event.target?.files[0];
  if (!file) return false;
  if (file.size > 1024 * 1024 * 6) {
    event.preventDefault();
    toast("File too big (> 6MB)", "error");
    return false;
  }
  return true;
};

export const PhotoExtensions = (event, extensions) => {
  const file = event.target?.files[0];
  const extenstion = file.name.split(".").pop();
  if (extensions.includes(extenstion?.toLowerCase())) {
    return true;
  } else {
    event.preventDefault();
    toast(`${extensions.toString()} files allowed`, "error");
    return false;
  }
};

export const arraySum = (array) => {
  if (array.length > 0) {
    const sum = array?.reduce(function (a, b) {
      return a + b;
    });

    return sum;
  }
  return 0;
};

export const isArray = (array) => {
  return typeof array == "object" || typeof array == "array";
};

export const changeToarray = (data) => {
  if (filled(data)) {
    return isArray(data) ? data : [data];
  }
  return [];
};

export const converUtcTimezoneToDesiredTimezone = (
  date,
  timeZone,
  format = "YYYY MM DD HH:mm:ss"
) => {
  if (date) {
    let utcCutoff = moment.utc(date, "YYYYMMDD HH:mm:ss");
    let displayCutoff = utcCutoff.clone().tz(timeZone).format(format);
    return displayCutoff;
  }
};

export const convertToFromNow = (
  date,
  timeZone,
  format = "YYYY MM DD HH:mm:ss"
) => {
  if (date) {
    const userTime = converUtcTimezoneToDesiredTimezone(date, timeZone, format);
    return moment.tz(userTime, timeZone).fromNow();
  }
};

export const converDesiredTimeZoneTimezoneToUtcTimezone = (
  date,
  timeZone,
  format = "YYYY MM DD HH:mm:ss"
) => {
  if (date) {
    let displayCutoff = moment
      .tz(
        moment(date).format("YYYY-MM-DD HH:mm:ss"),
        "YYYY-MM-DD HH:mm:ss",
        timeZone
      )
      .utc()
      .format(format);
    return displayCutoff;
  }
};

export const dragingSlide = () => {
  const slider = document.querySelector(".draggingSlide");
  let isDown = false;
  let startX;
  let scrollLeft;

  slider.addEventListener("mousedown", (e) => {
    isDown = true;
    slider.classList.add("!cursor-grabbing");
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener("mouseleave", () => {
    isDown = false;
    slider.classList.remove("!cursor-grabbing");
  });
  slider.addEventListener("mouseup", () => {
    isDown = false;
    slider.classList.remove("!cursor-grabbing");
  });
  slider.addEventListener("mousemove", (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  });
};

export const prepareOpenMatchTime = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  return moment(date).subtract(10, "seconds").format(format);
};

export const getHoursLeftToNow = (date) => {
  return moment(date).diff(moment(), "hours");
};

export const getSecondsLeftToNow = (date) => {
  return moment(date).diff(moment(), "seconds");
};
export const isToday = (date) => {
  return moment(date).isSame(moment(), "day");
};
export const isTomorrow = (date) => {
  return moment(date).isSame(moment().add(1, "days"), "day");
};
export const isInWeek = (date) => {
  return moment(date).isSame(moment(), "week");
};

export const inputsLang = (event) => {
  var ew = event?.which;
  var key = event?.key;
  if (key.match(/^[a-z]+$/i)?.length) {
    return true;
  }
  if (key.match(/^[0-9]+$/)?.length) {
    return true;
  }
  if (ew === 32) return true;
  if (key === "'") return true;
  if (key === ";") return true;
  if (key === "^") return true;
  if (key === "-") return true;
  if (key === "_") return true;
  if (key === "`") return true;
  if (key === "~") return true;
  if (key === ":") return true;
  if (key === '"') return true;
  if (key === "\\") return true;
  if (key === "/") return true;
  if (key === "|") return true;
  if (key === "<") return true;
  if (key === ">") return true;
  if (key === ".") return true;
  if (key === "!") return true;
  if (key === "@") return true;
  if (key === "$") return true;
  if (key === "#") return true;
  if (key === "%") return true;
  if (key === "&") return true;
  if (key === "*") return true;
  if (key === "(") return true;
  if (key === ")") return true;
  if (key === "?") return true;
  if (key === "shift") return true;
  if (key === "alt") return true;
  if (key === "enter") return true;

  // if (ew === 191) return true;
  // if (ew === 190) return true;
  // if (ew === 16) return true;
  // if (ew === 18) return true;
  // if (ew === 8) return true;
  // if (ew === 95) return true;
  // if (ew === 13) return true;
  // if (32 <= ew && ew <= 39) return true;
  // if (40 <= ew && ew <= 58) return true;
  // if (63 <= ew && ew <= 90) return true;
  // if (97 <= ew && ew <= 122) return true;
  event.preventDefault();
  event.stopPropagation();

  if (!inputErrorShowed) {
    inputErrorShowed = true;
    toast("just english language is allowed", "error");
    setTimeout(() => {
      inputErrorShowed = false;
    }, 5000);
  }
  return false;
};

export const sendPushNotification = (
  title = [],
  href = window.location.origin
) => {
  let titleConcat = "";
  title.forEach((t) => {
    titleConcat = `${titleConcat}  ${t.title}`;
  });
  if (process.env.NODE_ENV === "production") {
    if (Notification.permission == "granted") {
      if ("serviceWorker" in navigator) {
        const options = {
          body: titleConcat,
          // icon:
          //   window.location.origin + "/img/icons/apple-touch-icon-152x152.png",
          // image: "src/images/main-image-sm.jpg",
          dir: "ltr",
          lang: "en-US",
          icon:
            window.location.origin + "/img/icons/apple-touch-icon-152x152.png",
          data: {
            url: href,
          },
          actions: [{ action: "open_url", title: "Read Now" }],

          // tag: "confirm-notification",
          // actions: [
          //   {
          //     action: "confirm",
          //     title: "Okay",
          //     icon: "src/images/icons/app-icon-96x96.png",
          //   },
          //   {
          //     action: "cancel",
          //     title: "Cancel",
          //     icon: "src/images/icons/app-icon-96x96.png",
          //   },
          // ],
        };

        navigator.serviceWorker.ready.then((sw) =>
          sw.showNotification("GameClude", options)
        );
      }
    }
  } else {
    const notification = new Notification("GameClude", {
      body: titleConcat,
      icon: window.location.origin + "/img/icons/apple-touch-icon-152x152.png",
    });
    notification.onclick = (e) => {
      window.location.href = href;
    };
  }
};

export const askPushNotificationPermission = (notification, href) => {
  if (Notification.permission == "default") {
    Notification.requestPermission().then((permission) => {});
  }
};

export const cluderCustomAvatar = () => {
  return require("@/assets/images/panel/dashboard/UserAvatar.png");
};

export const squadCustomAvatar = () => {
  return require("@/assets/images/panel/dashboard/SquadAvatar.png");
};

export const FixFloatMatches = (amount) => {
  if (parseInt(amount) === amount) {
    return amount;
  }
  return amount.toFixed(2);
};

// addEventListener(
//   "notificationclick",
//   (event) => {
//     console.log("On notification click: ", event.notification.data.url);
//     event.notification.close();
//     event.waitUntil(
//       clients
//         .matchAll({
//           type: "window",
//         })
//         .then((clientList) => {
//           for (const client of clientList) {
//             if (client.url === "/" && "focus" in client) return client.focus();
//           }
//           if (clients.openWindow) return clients.openWindow("/");
//         })
//     );
//   },
//   false
// );
