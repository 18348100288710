import { authApi } from "@/service/Config";

const prefix = "/notifications";

export const getNotificationsApi = async (
  squad_notification = "",
  is_read = "",
  page = 1,
  per_page
) => {
  return authApi
    .get(`${prefix}`, {
      params: {
        squad_notification,
        is_read,
        page,
        per_page,
      },
    })
    .then((res) => res.data);
};

export const updateNotificationSettingsApi = async (data) => {
  return authApi.put(`${prefix}/setting/update`, data).then((res) => res.data);
};

export const updateNotificationApi = async (data) => {
  return authApi.put(`${prefix}/update`, data).then((res) => res.data);
};

export const getNotificationsCountApi = async (
  squad_notification = "",
  is_read = ""
) => {
  return authApi
    .get(`${prefix}/count`, {
      params: {
        squad_notification,
        is_read,
      },
    })
    .then((res) => res.data);
};

export const updateUserNotificationSettingApi = async (data) => {
  return authApi.put(`${prefix}`, data).then((res) => res.data);
};

export const setNotificationsToReadApi = async (data) => {
  return authApi.put(`${prefix}/read`, data).then((res) => res.data);
};

export const setNotificationsReadAllApi = async (data) => {
  return authApi.put(`${prefix}/read-all`, data).then((res) => res.data);
};
