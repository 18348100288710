<template>
  <VueFinalModal name="suspendedModal" v-model="suspendedModal">
    <ModalLayout modalName="suspendedModal" :has-btn-on-bottom="true">
      <template #title>
        <div class="flex items-center gap-2">
          <LimitAccessIcon light-color="#0c0e1a" dark-color="#fff" />
          <div class="text-md font-[500] text-text-primary-light dark:text-text-primary-dark">Limited access</div>
        </div>
      </template>
      <div class="text-md text-text-primary-light dark:text-text-primary-dark">Fine-based user access limitation.
      </div>
      <div
        class="bg-background-main-light dark:bg-background-main-dark rounded-[4px] border border-tertiary-light dark:border-tertiary-dark px-[12px] py-[8px] mt-[12px]">
        <div class="text-md text-text-primary-light dark:text-text-primary-dark">
          We regret to inform you that your access to Gameclude services has been restricted due to a ${{ fineAmount }}
          fine.
          Until the fines are settled, you are unable to:
        </div>
        <div class="flex items-center gap-2">
          <div class="w-[3px] min-w-[3px] h-[3px] rounded-full bg-text-primary-light dark:bg-text-primary-dark"></div>
          <div class="ext-md text-text-primary-light dark:text-text-primary-dark">Create/Join matches</div>
        </div>
        <div class="flex items-center gap-2">
          <div class="w-[3px] min-w-[3px] h-[3px] rounded-full bg-text-primary-light dark:bg-text-primary-dark"></div>
          <div class="ext-md text-text-primary-light dark:text-text-primary-dark">Create/Join tournaments</div>
        </div>
        <div class="flex items-center gap-2">
          <div class="w-[3px] min-w-[3px] h-[3px] rounded-full bg-text-primary-light dark:bg-text-primary-dark"></div>
          <div class="ext-md text-text-primary-light dark:text-text-primary-dark">Chat rooms</div>
        </div>
      </div>
      <div
        class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
        <div class="grid grid-cols-2 gap-[8px] sm:flex sm:items-center sm:justify-end">
          <ButtonTertiary @click="showOnlineSupport" size="sm" class="max-sm:!w-full suportChatBtn"
            span-class="suportChatBtn">
            Contact support
          </ButtonTertiary>
          <ButtonPrimary @click="showDeposit" size="sm" class="max-sm:!w-full">
            Deposit
          </ButtonPrimary>
        </div>
      </div>
    </ModalLayout>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed } from "vue";
import { $vfm } from 'vue-final-modal'
import ModalLayout from "@/components/modal/ModalLayout.vue";
import { useStore } from "vuex";
import LimitAccessIcon from "@/components/Icons/LimitAccessIcon.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";

// data
const suspendedModal = ref(false)
const store = useStore();
const fineAmount = computed(() => store.getters.fineAmount)
const userWalletAmount = computed(() => store.getters.userWalletAmount)


const showOnlineSupport = () => {
  $vfm.hideAll();
  store.dispatch('openChat', true);
};

const showDeposit = () => {
  $vfm.hideAll();
  const amountToDeposit = Math.ceil(
    fineAmount.value - userWalletAmount.value
  );
  store.dispatch("setDefaultDeposit", amountToDeposit);
  return $vfm.show("DepositModal");
};

</script>
