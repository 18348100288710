<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12C22 17.5225 17.5225 22 12 22C6.4775 22 2 17.5225 2 12C2 6.4775 6.4775 2 12 2C17.5225 2 22 6.4775 22 12Z" fill="url(#paint0_linear_9572_100101)"/>
    <path opacity="0.05" d="M16.0859 8.08975L10.9999 13.1757L8.41387 10.5898C8.02337 10.1992 7.39037 10.1992 6.99987 10.5898L6.29287 11.2967C5.90237 11.6872 5.90237 12.3202 6.29287 12.7107L10.2929 16.7108C10.6834 17.1013 11.3164 17.1013 11.7069 16.7108L18.2069 10.2107C18.5974 9.82025 18.5974 9.18725 18.2069 8.79675L17.4999 8.08975C17.1094 7.69925 16.4764 7.69925 16.0859 8.08975Z" fill="black"/>
    <path opacity="0.07" d="M10.4697 16.5341L6.46975 12.5341C6.17675 12.2411 6.17675 11.7661 6.46975 11.4736L7.17675 10.7666C7.46975 10.4736 7.94475 10.4736 8.23725 10.7666L11.0002 13.5291L16.2628 8.26663C16.5558 7.97363 17.0308 7.97363 17.3232 8.26663L18.0303 8.97363C18.3233 9.26663 18.3233 9.74163 18.0303 10.0341L11.5302 16.5341C11.2377 16.8266 10.7627 16.8266 10.4697 16.5341Z" fill="black"/>
    <path d="M10.6466 16.3497L6.64662 12.3497C6.45112 12.1542 6.45112 11.8377 6.64662 11.6427L7.35362 10.9357C7.54912 10.7402 7.86563 10.7402 8.06063 10.9357L11.0001 13.8752L16.4396 8.43569C16.6351 8.24019 16.9516 8.24019 17.1466 8.43569L17.8536 9.14269C18.0491 9.33819 18.0491 9.65469 17.8536 9.84969L11.3536 16.3497C11.1586 16.5452 10.8416 16.5452 10.6466 16.3497Z" fill="white"/>
    <defs>
      <linearGradient id="paint0_linear_9572_100101" x1="4.929" y1="4.929" x2="19.071" y2="19.071" gradientUnits="userSpaceOnUse">
        <stop stop-color="#21AD64"/>
        <stop offset="1" stop-color="#088242"/>
      </linearGradient>
    </defs>
  </svg>
</template>
