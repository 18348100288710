<template>
  <Card size="md" :class="{
    '!bg-background-main-light dark:!bg-background-main-dark !border-tertiary-light dark:!border-tertiary-dark': notificationIsRead,
    'bg-background-white-light dark:!bg-background-white-dark border-lowBlue hover:!bg-background-tertiary-light dark:hover:!bg-background-tertiary-dark': !notificationIsRead,
    'hidden': hiddenNotification
  }" class="transition-all !border flex items-center justify-between btn-hover-tertiary !mb-0 !p-[12px]">
    <div class="flex-grow grid gap-[12px]">
      <div class="grid grid-cols-2 items-center justify-between md:hidden">
        <i :class="icon" class="text-3xl" />
        <span class="text-text-secondary-light dark:text-text-secondary-dark text-right">
          {{ notification.created_at_for_humans }}
        </span>
      </div>
      <div class="grid grid-cols-9 items-between">
        <div class="col-span-7 md:col-span-7 flex items-start gap-[8px] font-medium">
          <i :class="icon" class="text-3xl hidden md:block" />
          <p>
            <span v-for="(item, index) in notification.title" :key="item.title" :class="[
              item.dark_color,
              item.light_color,
              item.weight,
              {
                '!lowercase': index !== 0
              }
            ]">
              <router-link @click="setNotificationToRead" :to="notificationRedirectTo" v-if="item.type == 'text'">
                {{ item.title }}
              </router-link>
              <span @click="getNotificationRedirectionName" v-if="item.type == 'modal'" class="cursor-pointer">
                {{ item.title }}
              </span>
              <router-link v-if="item.type == 'user_profile' || item.type == 'squad_profile'" :to="{
                name: item.type == 'user_profile' ? 'cluder-profile' : 'squad-profile',
                params: {
                  id: item.id
                }
              }" class="text-primary dark:text-secondary capitalize">
                {{ item.title }}
              </router-link>
            </span>
          </p>
        </div>
        <span
          class="hidden md:block col-span-2 md:col-span-2 text-text-secondary-light dark:text-text-secondary-dark text-right">
          {{ notification.created_at_for_humans }}
        </span>
      </div>

      <div v-if="notificationHasActionOrSubtitle" :class="{
        'md:grid-cols-5': !isInHeader
      }" class="grid gap-[10px] items-center">
        <div :class="{
          'md:col-span-3': !isInHeader
        }">
          <div v-if="notificationHasSubtitle" class="flex items-center gap-[8px]">
            <span v-for="(item, index) in notification.sub_title" :key="item.title">
              <div v-if="item.type == 'prize_button'" size="sm" class="!py-0 px-[8px] flex items-center justify-center gap-[8px] rounded text-[14px] font-medium text-primary dark:text-white
                bg-background-white-light dark:bg-background-white-dark border border-solid border-secondaryBorder">
                <div class="!py-[4px] pr-[8px] border-r border-solid border-secondaryBorder">
                  prize
                </div>
                <span> {{ item.title }}</span>
              </div>
              <ButtonPrimary v-if="item.type == 'button'" size="sm"
                class="!py-[4px] bg-background-white-light dark:bg-background-white-dark text-primary dark:text-white">
                {{ item.title }}
              </ButtonPrimary>
              <router-link v-if="item.type == 'text'" :to="notificationRedirectTo"
                class="text-lg text-text-secondary-light dark:text-text-secondary-dark">
                <span class="hover:text-primary dark:hover:text-secondary text-center">
                  <span class="inline-block mx-[4px]">
                    {{ item.title }}
                  </span>
                  <span v-if="index + 1 != notification.sub_title.length">|</span>
                </span>
              </router-link>
              <div v-if="item.type == 'modal'" @click="getNotificationRedirectionName"
                class="text-lg text-text-secondary-light dark:text-text-secondary-dark cursor-pointer">
                <span class="hover:text-primary dark:hover:text-secondary text-center">
                  <span class="inline-block mx-[4px]">
                    {{ item.title }}
                  </span>
                  <span v-if="index + 1 != notification.sub_title.length">|</span>
                </span>
              </div>
            </span>

          </div>
        </div>
        <div v-if="notificationHasAction" :class="{
          'md:col-span-2': !isInHeader
        }">
          <transition name="fade" mode="out-in">
            <div v-if="showMainAction" class="grid md:flex gap-[10px] justify-end"
              :class="`grid-cols-${mainActions.length}`">
              <div class="flex justify-end" v-for="(action, index) in mainActions" :key="index">
                <ButtonAlert v-if="action.button?.type == 'deny'" size="sm" @click="deny" :disabled="disabled"
                  class="!py-[4px] w-full md:w-[80px] h-full">
                  <div class="flex gap-1 justify-center items-center">
                    <span class="icon-Close-Filled"></span>
                    <span>Deny</span>
                  </div>
                </ButtonAlert>
                <ButtonSuccess v-if="action.button?.type == 'accept'" size="sm" @click="accept" :disabled="disabled"
                  class="!py-[4px] !px-[8px] border-2 border-transparent w-full md:w-[120px]  h-full">
                  <div class="flex gap-1 justify-center items-center">
                    <span class="icon-Ok-2"></span>
                    <span>Accept</span>
                    <div v-if="expireAt && !isExpired">
                      <SpinLoading v-show="!countDownStarted && showExpireAt" />
                      <span v-show="countDownStarted && showExpireAt" class="flex">
                        (
                        <CountDown :from="expireAt" :showIfUnderHours="1" @expired="(data) => (isExpired = data)"
                          showFormat="MS" @countDownStarted="(data) => countDownStarted = data"
                          @realTimeShowExpireAtStarted="(data) => realTimeShowExpireAt = data" />
                        )
                      </span>
                    </div>
                  </div>
                </ButtonSuccess>
                <div v-if="action.button?.type == 'alternative'" @click="alter">
                  <button v-if="action.action_type == 'link'"
                    class="block text-primary dark:text-secondary text-right w-full">
                    {{ action.button?.title }}
                  </button>
                  <ButtonBorder v-else class="block text-primary dark:text-secondary px-6 md:py-2 rounded">
                    {{ action.button?.title }}
                  </ButtonBorder>
                </div>

                <button v-if="action.button?.type == 'info'" @click="info"
                  class="transition-all block text-primary dark:text-secondary md:py-2 hover:!text-secondary dark:hover:!text-primary">
                  {{ action.button?.title }}
                </button>

                <button v-if="action.button?.type == 'challenge'" @click="info"
                  class="transition-all block text-primary dark:text-secondary md:py-2 hover:!text-secondary dark:hover:!text-primary">
                  {{ action.button?.title }}
                </button>
              </div>
            </div>
            <div v-else-if="showAlterAction" class="flex justify-end gap-[20px] items-center ">
              <div v-if="isExpired" class="bg-background-white-light dark:bg-background-white-dark text-text-tertiary-light dark:text-text-tertiary-dark
              rounded text-center py-1 px-3 w-fit justify-self-end">
                <span>Expired</span>
              </div>
              <div v-for="(action, index) in alterActions" :key="index">
                <ButtonSuccess v-if="action.button?.type == 'accept'" size="sm" @click="accept" :disabled="disabled"
                  class="!p-[4px] !px-[8px]  border-2 border-transparent w-full h-full basis-1/2 ">
                  <div class="flex gap-1 justify-center items-center">
                    <span class="icon-Ok-2"></span>
                    <span>Accept</span>
                    <div v-if="expireAt && !isExpired" class="flex">
                      <SpinLoading v-show="!countDownStarted && showExpireAt" />
                      <span v-show="countDownStarted && showExpireAt" class="flex">
                        (
                        <CountDown :from="expireAt" :showIfUnderHours="1" showFormat="MS"
                          @expired="(data) => (isExpired = data)"
                          @count-down-started="(data) => countDownStarted = data"
                          @realTimeShowExpireAtStarted="(data) => realTimeShowExpireAt = data" />
                        )
                      </span>
                    </div>
                  </div>
                </ButtonSuccess>
                <ButtonAlert v-if="action.button?.type == 'deny'" size="sm" @click="deny" :disabled="disabled"
                  class="!py-[4px] w-[80px] h-full basis-1/2">
                  <div class="flex gap-1 justify-center items-center">
                    <span class="icon-Close-Filled"></span>
                    <span>Deny</span>
                  </div>
                </ButtonAlert>
                <button v-if="action.button?.type == 'alternative'" @click="alter"
                  class="transition-all block text-primary dark:text-secondary text-right w-full hover:!text-secondary dark:hover:!text-primary">
                  {{ action.button?.title }}
                </button>
              </div>
            </div>
            <div v-else-if="isAccepted || isDenied" class="w-full flex justify-end">
              <div class="flex gap-1 justify-center items-center py-[4px] px-[8px] w-full md:w-fit rounded" :class="{
                'bg-blurSuccess text-success': isAccepted,
                'bg-blurAlert text-alert ': isDenied
              }">
                <span v-if="isDenied" class="icon-Close-Filled"></span>
                <span v-if="isAccepted" class="icon-Ok-2"></span>
                <span>
                  {{ isAccepted ? 'Accepted' : 'Denied' }}
                </span>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { computed, ref, inject, watch } from "vue";
import Card from "@/components/card";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import ButtonSuccess from "@/components/form-component/buttons/ButtonSuccess.vue";
import ButtonBorder from "@/components/form-component/buttons/ButtonBorder.vue";
import ButtonAlert from "@/components/form-component/buttons/ButtonAlert.vue";
import CountDown from "@/components/layout/common/utility/CountDown.vue";
import { filled, isToday, prepareOpenMatchTime } from "@/helper";
import { acceptBattleApi, acceptBattleDirectChallengeApi, acceptBattleInviteApi, joinBattleApi, rejectBattleApi, rejectBattleDirectChallengeApi } from "@/service/api/BattleApi";
import { setNotificationsToReadApi, updateNotificationApi } from "@/service/api/NotificationApi";
import { $vfm } from "vue-final-modal";
import { useRouter } from "vue-router";
import SpinLoading from "@/components/layout/common/utility/SpinLoading.vue";
import { acceptClanWarDirectChallengeApi, rejectClanWarDirectChallengeApi } from "@/service/api/ClanWarApi";
import { joinTournamentApi } from "@/service/api/TournamentApi";
import { useStore } from "vuex";
import { getUserTagsApi } from "@/service/api/UserTagApi";

export default {
  name: "NotificationBuilder",
  components: {
    Card,
    ButtonPrimary,
    ButtonSuccess,
    ButtonAlert,
    CountDown,
    ButtonBorder,
    SpinLoading
  },
  props: {
    notification: {
      type: Object
    },
    isInHeader: {},
    clickedOnReadAll: {},
    isInOpenMatch: {}
  },
  emits: [
    'setChallengableId',
    'showClanWarDetail',
    'refreshMatch',
  ],
  setup(props, { emit }) {
    const store = useStore();
    const userWalletAmount = computed(() => store.getters.userWalletAmount);
    const isEmailVerified = computed(() => store.getters.isEmailVerified);
    const personalInfoIsCompeleted = computed(
      () => store.getters.personalInfoIsCompeleted
    );
    const moment = inject('moment');
    const router = useRouter();
    const notificationSetup = computed(() => props.notification);
    const notificationHasAction = computed(() => Object.keys(notificationSetup.value.actions ?? {}).length);
    const notificationHasSubtitle = computed(() => notificationSetup.value.sub_title?.length);
    const notificationHasActionOrSubtitle = computed(() => notificationHasAction.value || notificationHasSubtitle.value);
    const notificationRedirectTo = computed(() => getNotificationRedirectionName());
    const expireAt = computed(() => {
      if (notificationSetup.value?.actions?.expire_at) {
        return prepareOpenMatchTime(notificationSetup.value?.actions?.expire_at.date);
      }
      return false;
    });
    const notificationIsExpired = computed(() => {
      if (notificationSetup.value?.actions?.expire_at) {
        let timeToSeconds = moment(notificationSetup.value?.actions?.expire_at.date).diff(moment().utc().format('YYYY-MM-DD HH:mm:ss'), "seconds");
        return timeToSeconds < 0;
      }
      return false;
    });
    const realTimeShowExpireAt = ref(false);
    const showExpireAt = computed(() => {
      return isToday(expireAt.value) && realTimeShowExpireAt.value;
    });
    const isExpired = ref(notificationIsExpired.value);
    const showMainAction = computed(() => !isExpired.value && !isAccepted.value && !isDenied.value);
    const mainActions = computed(() => {
      if ((notificationSetup.value?.actions.main)) {
        const actions = notificationSetup.value?.actions.main
        return actions.reverse()
      }
      return [];
    });
    const alterActions = computed(() => {
      if ((notificationSetup.value?.actions.alter)) {
        const actions = notificationSetup.value?.actions.alter
        return actions.reverse()
      }
      return [];
    });
    const showAlterAction = computed(() => isExpired.value && !isAccepted.value && !isDenied.value);
    const disabled = ref(false);
    const acceptLoading = ref(false);
    const denyLoading = ref(false);
    const isAccepted = ref(false);
    const isDenied = ref(false);
    const challengeableId = ref();
    const countDownStarted = ref(false);
    const icon = computed(() => {
      switch (notificationSetup.value?.icon) {
        case 'battle':
          return 'icon-Battle-Stroke';
        case "wallet":
          return "icon-Wallet-Stroke"
        default:
          return notificationSetup.value?.icon;
      }
    });
    const hiddenNotification = ref(false);
    const serviceId = ref(null);
    const userTags = computed(() => store.state.Common.userTagsServiceId);
    const userHasTag = computed(() => {
      return (userTags.value?.filter(s => s === serviceId.value).length);
    });
    const notificationIsRead = computed(() => {
      return (notificationSetup.value.is_read && !props.isInOpenMatch) || props.clickedOnReadAll;
    });





    watch(notificationIsExpired, () => {
      isExpired.value = notificationIsExpired.value
    });



    const getNotificationRedirectionName = () => {
      const data = props.notification.data;
      const name = props.notification?.target_action;
      switch (name) {
        case 'WALLET':
          return { name: 'wallet' };
        case 'BONUS_CONVERSION':
          return { name: 'wallet' };
        case 'REQUEST_TO_JOIN_BATTLE':
          return bettleOpenMatchRedirection(data.match_id);
        case 'MATCH_REQUEST_HAS_BEEN_REJECTED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'MATCH_REQUEST_HAS_BEEN_ACCEPTED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_DIRECT_CHALLENGE_ACCEPTED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'INVITE_CLUDER_TO_PLAY_BATTLE':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_CANCELED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_EXPIRED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_MEMBER_CHANGED_TO_READY':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_SCORE_ENTERED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_SCORE_RESUBMITTED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_CANCEL_REQUESTED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_DIRECT_CHALLENGE':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_CALLED_FOR_DISPUTE':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_HAS_BEEN_EXPIRED':
          return bettleOpenMatchRedirection(data.match_id);
        case 'BATTLE_HAS_BEEN_DONE':
          return bettleOpenMatchRedirection(data.match_id);
        case 'CLANWAR_DIRECT_CHALLENGE':
          return clanWarMatchModal(data.clan_war_id);
        case 'CLANWAR_DIRECT_CHALLENGE_ACCEPTED':
          return clanWarMatchModal(data.clan_war_id);
        case 'CLANWAR_DIRECT_CHALLENGE_REJECTED':
          return clanWarMatchModal(data.clan_war_id);
        case 'SQUAD_INVITE_CLUDER_TO_SQUAD':
          return squadInviteRedirection(data.squad_id);
        case 'USER_WON_PRIZE':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'CREATED_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'INVITE_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'USER_JOINED_TO_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'USER_CANCELED_JOIN_TO_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'CANCELED_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'STARTED_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'REMAINED_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'NEW_USER_JOINED_TO_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'RETURNED_SPLIT_PRIZES':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'INVITE_TOURNAMENT':
          return redirectToTournamentDetailName(data.tournament_id);
        case 'TOURNAMENT_MATCH_MEMBER_CHANGED_TO_READY':
          return redirectToTournamentMatchName(data.match_id);
        case 'TOURNAMENT_SCORE_ENTERED':
          return redirectToTournamentMatchName(data.match_id);
        case 'TOURNAMENT_DETERMINATION':
          return redirectToTournamentMatchName(data.match_id);
        case 'STARTED_TOURNAMENT_MATCH':
          return redirectToTournamentMatchName(data.match_id);
        case 'TOURNAMENT_SCORE_RESUBMITTED':
          return redirectToTournamentMatchName(data.match_id);
        default:
          return "#"
      }
    };

    const bettleOpenMatchRedirection = (match_id) => {
      return filled(match_id) ? { name: 'open-match-1v1', params: { id: match_id } } : '#';
    };

    const clanWarOpenMatchRedirection = (match_id) => {
      return filled(match_id) ? { name: 'open-match-clan-war', params: { id: match_id } } : '#';
    };

    const squadInviteRedirection = (id) => {
      return filled(id) ? { name: 'squad-profile', params: { id: id } } : '#';
    };

    const clanWarMatchModal = (id) => {
      return filled(id) ? emit('showClanWarDetail', id) : '#';
    };

    const accept = () => {
      const data = props.notification.data;
      const name = props.notification?.target_action;
      switch (name) {
        case 'REQUEST_TO_JOIN_BATTLE':
          return acceptBattleRequest(data.battle_id, data.match_request_id);
        case 'BATTLE_DIRECT_CHALLENGE':
          return acceptBattleDirectChallengeRequest(data);
        case 'CLANWAR_DIRECT_CHALLENGE':
          return acceptClanWarDirectChallengeRequest(data.clan_war_id);
        case 'SQUAD_INVITE_CLUDER_TO_SQUAD':
          return acceptInviteSquadRequest(data.squad_id);
        case 'INVITE_CLUDER_TO_PLAY_BATTLE':
          return acceptInviteBattle(data);
        case 'INVITE_TOURNAMENT':
          return acceptInviteTournament(data);
        default:
          return "#"
      }
    };
    const deny = () => {
      const data = props.notification.data;
      const name = props.notification?.target_action;
      switch (name) {
        case 'REQUEST_TO_JOIN_BATTLE':
          return denyBattleRequest(data.battle_id, data.match_request_id);
        case 'BATTLE_DIRECT_CHALLENGE':
          return denyBattleDirectChallengeRequest(data.battle_id);
        case 'INVITE_CLUDER_TO_PLAY_BATTLE':
          return denyInviteBattle(data.battle_id);
        case 'CLANWAR_DIRECT_CHALLENGE':
          return denyClanWarDirectChallengeRequest(data.clan_war_id);
        case 'SQUAD_INVITE_CLUDER_TO_SQUAD':
          return denyInviteSquadRequest(data.squad_id);
        case 'INVITE_TOURNAMENT':
          return denyInviteTournament();
        default:
          return "#"
      }
    };

    const alter = () => {
      const data = props.notification.data;
      const name = props.notification?.target_action;
      switch (name) {
        case 'REQUEST_TO_JOIN_BATTLE':
          return setCluderDirectChallenge(data)
        case 'BATTLE_DIRECT_CHALLENGE':
          return setCluderDirectChallenge(data)
        case 'CLANWAR_DIRECT_CHALLENGE':
          challengeableId.value = data.requester_id;
          emit('setChallengableId', challengeableId.value)
          return $vfm.show('SquadDirectChallengeModal');
        case 'INVITE_TOURNAMENT':
          return redirectToTournamentDetail(data.tournament_id);
        default:
          return "#"
      }
    };

    const info = () => {
      const data = props.notification.data;
      const name = props.notification?.target_action;
      switch (name) {
        case 'BATTLE_SCORE_RESUBMITTED':
          return redirectToBattleMatch(data.match_id);
        case 'CREATED_TOURNAMENT':
          return redirectToTournamentDetail(data.tournament_id);
        case 'STARTED_TOURNAMENT_MATCH':
          return redirectToTournamentMatch(data.match_id);
        case 'RETURNED_SPLIT_PRIZES':
          return redirectToTournamentDetail(data.tournament_id);
        case 'USER_JOINED_TO_TOURNAMENT':
          return redirectToTournamentDetail(data.tournament_id);
        case 'USER_CANCELED_JOIN_TO_TOURNAMENT':
          return redirectToTournamentDetail(data.tournament_id);
        case 'CANCELED_TOURNAMENT':
          return redirectToTournamentDetail(data.tournament_id);
        case 'STARTED_TOURNAMENT':
          return redirectToTournamentDetail(data.tournament_id);
        case 'REMAINED_TOURNAMENT':
          return redirectToTournamentDetail(data.tournament_id);
        case 'NEW_USER_JOINED_TO_TOURNAMENT':
          return redirectToTournamentDetail(data.tournament_id);
        case 'REQUEST_TO_JOIN_BATTLE':
          return setCluderDirectChallenge(data)
        case 'BATTLE_DIRECT_CHALLENGE':
          return setCluderDirectChallenge(data)
        case 'BATTLE_CANCELED':
          return setCluderDirectChallenge(data)
        case 'MATCH_REQUEST_HAS_BEEN_REJECTED':
          return setCluderDirectChallenge(data)
        case 'BATTLE_HAS_BEEN_EXPIRED':
          return redirectToBattleMatch(data.match_id);
        case 'BATTLE_CALLED_FOR_DISPUTE':
          return redirectToBattleMatch(data.match_id);
        case 'BATTLE_HAS_BEEN_DONE':
          return setCluderDirectChallenge(data)
        case 'CLANWAR_DIRECT_CHALLENGE':
          challengeableId.value = data.requester_id;
          emit('setChallengableId', challengeableId.value)
          return $vfm.show('SquadDirectChallengeModal');
        default:
          return "#"
      }
    };

    const setCluderDirectChallenge = (data) => {
      challengeableId.value = data.target_cluder_id;
      emit('setChallengableId', challengeableId.value)
      $vfm.show('CluderDirectChallengeModal')
    };

    const getUserTags = async () => {
      await store.dispatch('setUserTags');
    };

    const checkUserDataBefoteJoin = async (data) => {
      const entryFee = data.entry_fee;
      serviceId.value = data.service_id;
      await getUserTags();

      if (!isEmailVerified.value) {
        $vfm.show('EmailIsNotVeriffiedModal');
        return false;
      }
      if (!personalInfoIsCompeleted.value) {
        $vfm.show("PersonalInfoNotCompeletedModal");
        return false;
      }
      if (parseFloat(userWalletAmount.value) < parseFloat(entryFee)) {
        const amountToDeposit = Math.ceil(
          entryFee - userWalletAmount.value
        );
        store.dispatch("setDefaultDeposit", amountToDeposit);
        $vfm.show('DepositModal');
        return false;
      }
      if (!userHasTag.value) {
        store.dispatch('setServiceIdForUserTag', serviceId.value);
        $vfm.show('GamerTagModal');
        return false;
      }
      return true;
    };

    const acceptBattleRequest = async (battle_id, match_request_id) => {
      disabled.value = true;
      acceptLoading.value = true;
      const response = await acceptBattleApi(
        battle_id,
        match_request_id
      );
      disabled.value = false;
      acceptLoading.value = false;
      if (response) {
        emit('refreshMatch')
        isAccepted.value = true;
        updateNotification('accept');
      }
    };

    const acceptBattleDirectChallengeRequest = async (data) => {
      const battle_id = data.battle_id;
      serviceId.value = data.service_id;
      disabled.value = true;
      acceptLoading.value = true;
      const res = await checkUserDataBefoteJoin(data);

      if (!res) {
        disabled.value = false;
        acceptLoading.value = false;
        return;
      }
      const response = await acceptBattleDirectChallengeApi(
        battle_id
      );
      disabled.value = false;
      acceptLoading.value = false;
      if (response) {
        store.dispatch("getProfile");
        emit('refreshMatch')
        isAccepted.value = true;
        updateNotification('accept');
      }
    };

    const acceptInviteBattle = async (data) => {
      const battle_id = data.battle_id;
      const match_id = data.match_id;
      disabled.value = true;
      acceptLoading.value = true;
      const res = await checkUserDataBefoteJoin(data);

      if (!res) {
        disabled.value = false;
        acceptLoading.value = false;
        return;
      }
      const response = await acceptBattleInviteApi(battle_id);

      if (response) {
        store.dispatch("getProfile");
        isAccepted.value = true;
        updateNotification('accept');
        router.push({
          name: 'open-match-1v1',
          params: {
            id: match_id
          }

        })
      }
      disabled.value = false;
      acceptLoading.value = false;
    };

    const acceptInviteTournament = async (data) => {
      const tournament_id = data.tournament_id;
      const unit = data.tournament_unit;
      disabled.value = true;
      acceptLoading.value = true;
      const res = await checkUserDataBefoteJoin(data);

      if (!res) {
        disabled.value = false;
        acceptLoading.value = false;
        return;
      }

      const response = await joinTournamentApi(
        tournament_id,
        {
          unit
        }
      );
      disabled.value = false;
      acceptLoading.value = false;
      if (response) {
        isAccepted.value = true;
        updateNotification('accept');
      }
    };

    const acceptClanWarDirectChallengeRequest = async (clan_war_id) => {
      disabled.value = true;
      acceptLoading.value = true;
      const response = await acceptClanWarDirectChallengeApi(
        clan_war_id
      );
      disabled.value = false;
      acceptLoading.value = false;
      if (response) {
        isAccepted.value = true;
        updateNotification('accept');
      }
    };

    const acceptInviteSquadRequest = async (squad_id) => {
      disabled.value = true;
      acceptLoading.value = true;
      const response = await acceptInviteApi(
        squad_id
      );
      disabled.value = false;
      acceptLoading.value = false;
      if (response) {
        isAccepted.value = true;
        updateNotification('accept');
      }
    };

    const denyBattleRequest = async (battle_id, match_request_id) => {
      disabled.value = true;
      denyLoading.value = true;
      const response = await rejectBattleApi(
        battle_id,
        match_request_id
      );
      if (response) {
        isDenied.value = true;
        updateNotification('deny');
        if (props.isInOpenMatch) {
          hiddenNotification.value = true;
        }
      }
      disabled.value = false;
      denyLoading.value = false;
    };

    const denyBattleDirectChallengeRequest = async (battle_id) => {
      disabled.value = true;
      denyLoading.value = true;
      const response = await rejectBattleDirectChallengeApi(
        battle_id
      );
      if (response) {
        emit('refreshMatch')
        isDenied.value = true;
        updateNotification('deny');
      }
      disabled.value = false;
      denyLoading.value = false;
    };

    const denyClanWarDirectChallengeRequest = async (clan_war_id) => {
      disabled.value = true;
      denyLoading.value = true;
      const response = await rejectClanWarDirectChallengeApi(
        clan_war_id
      );
      if (response) {
        isDenied.value = true;
        updateNotification('deny');
      }
      disabled.value = false;
      denyLoading.value = false;
    };

    const denyInviteSquadRequest = async (squad_id) => {
      disabled.value = true;
      denyLoading.value = true;
      const response = await rejectBattleApi(
        squad_id
      );
      if (response) {
        isDenied.value = true;
        updateNotification('deny');
      }
      disabled.value = false;
      denyLoading.value = false;
    };

    const denyInviteTournament = async () => {
      isDenied.value = true;
      updateNotification('deny');
    };

    const denyInviteBattle = async () => {
      isDenied.value = true;
      updateNotification('deny');
    };

    const updateNotification = async (action) => {
      updateNotificationApi({
        notification_id: notificationSetup.value?.id,
        action: action
      });
    };

    const redirectToBattleMatch = async (matchId) => {
      router.push({
        name: 'open-match-1v1',
        params: {
          id: matchId
        }
      })
    };

    const redirectToTournamentDetail = async (tournamentId) => {
      router.push({
        name: 'tournament-details',
        params: {
          id: tournamentId
        }
      })
    };

    const redirectToTournamentMatch = async (matchId) => {
      router.push({
        name: 'open-match-tournament',
        params: {
          id: matchId
        }
      })
    };

    const redirectToTournamentDetailName = (tournamentId) => {
      return filled(tournamentId) ? {
        name: 'tournament-details',
        params: {
          id: tournamentId
        }
      } : '#'
    };

    const redirectToTournamentMatchName = (tournamentMatchId, unit) => {
      return filled(tournamentMatchId) ? {
        name: 'open-match-tournament',
        params: {
          id: tournamentMatchId
        }
      } : '#'
    };

    const setNotificationToRead = async () => {
      if (props.isInHeader) {
        const respone = await setNotificationsToReadApi({
          notification_ids: [notificationSetup.value?.id],
        });
        if (respone) {
          store.dispatch('UpdateUserUnreadNotifications');
          store.dispatch('UpdateUserNotificationsCount');
        }
      }
    };


    return {
      icon,
      isExpired,
      expireAt,
      notificationRedirectTo,
      notificationHasAction,
      notificationHasSubtitle,
      notificationHasActionOrSubtitle,
      mainActions,
      showMainAction,
      disabled,
      showAlterAction,
      alterActions,
      isAccepted,
      isDenied,
      challengeableId,
      countDownStarted,
      hiddenNotification,
      showExpireAt,
      realTimeShowExpireAt,
      notificationIsRead,
      getNotificationRedirectionName,
      accept,
      deny,
      alter,
      info,
      setNotificationToRead
    }
  }
}
</script>
