import { useToast, TYPE } from "vue-toastification";
import ToastSuccessIcon from "@/components/Icons/ToastSuccessIcon.vue";
import ToastErrorIcon from "@/components/Icons/ToastErrorIcon.vue";

const toaster = useToast({
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      toastClassName: "toast-bg-error",
    },
    [TYPE.SUCCESS]: {
      toastClassName: "toast-bg-success",
    },
  },
});

export const toast = (msg, type) => {
  switch (type) {
    case "success":
      toaster.success(msg, {
        position: "bottom-left",
        icon: ToastSuccessIcon,
      });
      break;
    case "error":
      toaster.error(msg, {
        position: "bottom-left",
        icon: ToastErrorIcon,
      });
      break;
    case "info":
      toaster.info(msg);
      break;
    case "show":
      toaster.error(msg);
      break;
    case "warning":
      toaster.warning(msg);
      break;
    default:
      toaster.info(msg);
      break;
  }
};

export const notificationToast = (msg) => {
  return toaster.info(msg, {
    timeout: false,
    closeOnClick: true,
  });
};
export default toaster;
