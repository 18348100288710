<template>
  <svg class=" animate-spin  h-5 w-5 " viewBox="0 0 32 32" width="512" height="512">
    <path
      d="M16.03125 4.5C15.203125 4.5 14.53125 5.171875 14.53125 6C14.53125 6.828125 15.203125 7.5 16.03125 7.5C16.859375 7.5 17.53125 6.828125 17.53125 6C17.53125 5.171875 16.859375 4.5 16.03125 4.5 Z M 23.09375 7.1875C22.128906 7.1875 21.34375 7.972656 21.34375 8.9375C21.34375 9.902344 22.128906 10.6875 23.09375 10.6875C24.058594 10.6875 24.84375 9.902344 24.84375 8.9375C24.84375 7.972656 24.058594 7.1875 23.09375 7.1875 Z M 8.9375 7.65625C8.246094 7.65625 7.6875 8.214844 7.6875 8.90625C7.6875 9.597656 8.246094 10.15625 8.9375 10.15625C9.628906 10.15625 10.1875 9.597656 10.1875 8.90625C10.1875 8.214844 9.628906 7.65625 8.9375 7.65625 Z M 26 14.03125C24.894531 14.03125 24 14.925781 24 16.03125C24 17.136719 24.894531 18.03125 26 18.03125C27.105469 18.03125 28 17.136719 28 16.03125C28 14.925781 27.105469 14.03125 26 14.03125 Z M 6 14.96875C5.449219 14.96875 5 15.417969 5 15.96875C5 16.519531 5.449219 16.96875 6 16.96875C6.550781 16.96875 7 16.519531 7 15.96875C7 15.417969 6.550781 14.96875 6 14.96875 Z M 8.90625 20.0625C7.25 20.0625 5.90625 21.40625 5.90625 23.0625C5.90625 24.71875 7.25 26.0625 8.90625 26.0625C10.5625 26.0625 11.90625 24.71875 11.90625 23.0625C11.90625 21.40625 10.5625 20.0625 8.90625 20.0625 Z M 23.0625 20.84375C21.820313 20.84375 20.8125 21.851563 20.8125 23.09375C20.8125 24.335938 21.820313 25.34375 23.0625 25.34375C24.304688 25.34375 25.3125 24.335938 25.3125 23.09375C25.3125 21.851563 24.304688 20.84375 23.0625 20.84375 Z M 15.96875 23.5C14.589844 23.5 13.46875 24.621094 13.46875 26C13.46875 27.378906 14.589844 28.5 15.96875 28.5C17.347656 28.5 18.46875 27.378906 18.46875 26C18.46875 24.621094 17.347656 23.5 15.96875 23.5Z"
      :fill="color" />
  </svg>
</template>
<script>
export default {
  name: "SpinLoading",
  props: {
    color: {
      typ: String,
      required: false,
      default: "#5B5B5B"
    }
  }
}

</script>
