<template>

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 12C22 17.5225 17.5225 22 12 22C6.4775 22 2 17.5225 2 12C2 6.4775 6.4775 2 12 2C17.5225 2 22 6.4775 22 12Z"
      fill="url(#paint0_linear_9572_100096)"/>
    <path opacity="0.05"
          d="M16.5943 14.4738L14.1198 11.9987L16.5948 9.52375C16.9853 9.13325 16.9853 8.50025 16.5948 8.10975L15.8877 7.40275C15.4972 7.01225 14.8643 7.01225 14.4738 7.40275L11.9983 9.87725L9.52325 7.40225C9.13275 7.01175 8.49975 7.01175 8.10925 7.40225L7.40225 8.10925C7.01175 8.49975 7.01175 9.13275 7.40225 9.52325L9.87725 11.9983L7.40225 14.4732C7.01175 14.8637 7.01175 15.4967 7.40225 15.8872L8.10925 16.5943C8.49975 16.9848 9.13275 16.9848 9.52325 16.5943L11.9983 14.1192L14.4733 16.5943C14.8638 16.9848 15.4967 16.9848 15.8872 16.5943L16.5943 15.8872C16.9848 15.4972 16.9848 14.8643 16.5943 14.4738Z"
          fill="black"/>
    <path opacity="0.07"
          d="M16.4181 14.6501L13.7666 11.9986L16.4181 9.34713C16.7111 9.05413 16.7111 8.57912 16.4181 8.28662L15.7111 7.57962C15.4181 7.28662 14.9431 7.28662 14.6506 7.57962L11.9986 10.2306L9.34713 7.57913C9.05413 7.28613 8.57912 7.28613 8.28662 7.57913L7.57962 8.28613C7.28662 8.57913 7.28662 9.05412 7.57962 9.34662L10.2306 11.9986L7.57913 14.6501C7.28613 14.9431 7.28613 15.4181 7.57913 15.7106L8.28613 16.4176C8.57913 16.7106 9.05412 16.7106 9.34662 16.4176L11.9986 13.7666L14.6501 16.4181C14.9431 16.7111 15.4181 16.7111 15.7106 16.4181L16.4176 15.7111C16.7111 15.4181 16.7111 14.9431 16.4181 14.6501Z"
          fill="black"/>
    <path
      d="M15.534 7.756L16.241 8.463C16.4365 8.6585 16.4365 8.975 16.241 9.17L9.17 16.241C8.9745 16.4365 8.658 16.4365 8.463 16.241L7.756 15.534C7.5605 15.3385 7.5605 15.022 7.756 14.827L14.827 7.756C15.022 7.5605 15.339 7.5605 15.534 7.756Z"
      fill="white"/>
    <path
      d="M16.241 15.534L15.534 16.241C15.3385 16.4365 15.022 16.4365 14.827 16.241L7.756 9.17C7.5605 8.9745 7.5605 8.658 7.756 8.463L8.463 7.756C8.6585 7.5605 8.975 7.5605 9.17 7.756L16.241 14.827C16.4365 15.022 16.4365 15.339 16.241 15.534Z"
      fill="white"/>
    <defs>
      <linearGradient id="paint0_linear_9572_100096" x1="4.929" y1="4.929" x2="19.071" y2="19.071"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#F44F5A"/>
        <stop offset="0.443" stop-color="#EE3D4A"/>
        <stop offset="1" stop-color="#E52030"/>
      </linearGradient>
    </defs>
  </svg>

</template>
