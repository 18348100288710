import Twitter from "@/assets/icon-svg/twitter.svg";
import Discord from "@/assets/icon-svg/discord.svg";
import Instagram from "@/assets/icon-svg/instagram.svg";
import Linkedin from "@/assets/icon-svg/linkedin.svg";

export const actionNotifications = [
  "REQUEST_TO_BATTLE",
  "REQUEST_TO_JOIN_BATTLE",
  "BATTLE_DIRECT_CHALLENGE",
  "CLANWAR_DIRECT_CHALLENGE",
  "INVITE_CLUDER_TO_PLAY_BATTLE",
  "MATCH_REQUEST_HAS_BEEN_ACCEPTED",
  "INVITE_TOURNAMENT",
  //BATTLE
  "INVITE_CLUDER_TO_PLAY_BATTLE",
  "BATTLE_HAS_BEEN_EXPIRED",
  "BATTLE_HAS_BEEN_DONE",
  "BATTLE_SCORE_ENTERED",
  "BATTLE_HAS_BEEN_CANCELED",
  "BATTLE_CANCEL_REQUESTED",
  "BATTLE_CANCEL_REQUEST_ACCEPTED",
  "BATTLE_CANCEL_REQUEST_REJECTED",
  "BATTLE_FORFEIT_REQUESTED",
  "BATTLE_SCORE_RESUBMITTED",
  "BATTLE_CALLED_FOR_DISPUTE",
  "BATTLE_DISPUTE_WAITING_FOR_ADMIN_RESPONSE",
  "BATTLE_MEMBER_CHANGED_TO_READY",
  //TOURNAMNET
  "STARTED_TOURNAMENT",
  "TOURNAMENT_MATCH_MEMBER_CHANGED_TO_READY",
  "TOURNAMENT_SCORE_ENTERED",
  "TOURNAMENT_SCORE_RESUBMITTED",
];

export const OPEN_MATCH_NOTIFICATIONS = [
  //BATTLE
  "INVITE_CLUDER_TO_PLAY_BATTLE",
  "BATTLE_HAS_BEEN_EXPIRED",
  "BATTLE_HAS_BEEN_DONE",
  "BATTLE_SCORE_ENTERED",
  "BATTLE_HAS_BEEN_CANCELED",
  "BATTLE_CANCEL_REQUESTED",
  "BATTLE_CANCEL_REQUEST_ACCEPTED",
  "BATTLE_CANCEL_REQUEST_REJECTED",
  "BATTLE_FORFEIT_REQUESTED",
  "BATTLE_SCORE_RESUBMITTED",
  "BATTLE_CALLED_FOR_DISPUTE",
  "BATTLE_DISPUTE_WAITING_FOR_ADMIN_RESPONSE",
  "BATTLE_MEMBER_CHANGED_TO_READY",
  //TOURNAMNET
  "STARTED_TOURNAMENT",
  "TOURNAMENT_MATCH_MEMBER_CHANGED_TO_READY",
  "TOURNAMENT_SCORE_ENTERED",
  "TOURNAMENT_SCORE_RESUBMITTED",
];

export const RELOAD_USER_NOTIFICATIONS = [
  "CLUDER_DONATE_CASH",
  "MATCH_REQUEST_HAS_BEEN_REJECTED",
  "BATTLE_DIRECT_CHALLENGE_REJECTED",
  "BATTLE_HAS_BEEN_CANCELED",
  "BATTLE_HAS_BEEN_DONE",
  "BATTLE_CANCEL_REQUEST_ACCEPTED",
  "BATTLE_HAS_BEEN_EXPIRED",
  "BATTLE_FORFEIT_REQUESTED",
  "CLANWAR_HAS_BEEN_DONE",
  "CLANWAR_CANCEL_REQUEST_ACCEPTED",
  "CLANWAR_DIRECT_CHALLENGE_REJECTED",
  "TEAMUP_CLUDER_CANCELED",
  "NEW_USER_JOINED_TO_TOURNAMENT",
  "USER_JOINED_TO_TOURNAMENT",
  "USER_CANCELED_JOIN_TO_TOURNAMENT",
  "FINE",
];

export const MOBILE_SIDEBAR_MENU_WHEN_AUTH = [
  {
    name: "Trophies",
    linkName: "trophies",
    icon: "icon-Trophies-Stroke",
    active: true,
  },
  { name: "Followings", linkName: "friends", icon: "icon-Friend-Stroke" },
  {
    name: "Account setting",
    linkName: "account-setting",
    icon: "icon-Setting-Stroke",
  },
  {
    name: "Match history",
    linkName: "match-history",
    icon: "icon-History-Stroke",
  },
  {
    name: "more",
    child: [
      { name: "leaderboard", linkName: "leaderboard" },
      { name: "what we do", linkName: "about-us" },
      { name: "F.A.Q", linkName: "faq" },
    ],
  },
];

export const SOCIAL_MEDIA = [
  {
    link: "https://twitter.com/gameclude",
    icon: Twitter,
  },
  {
    link: "https://www.instagram.com/gameclude",
    icon: Instagram,
  },
  {
    link: "https://www.linkedin.com/company/gameclude",
    icon: Linkedin,
  },
  {
    link: "https://discord.gg/KyvgqRFY",
    icon: Discord,
  },
];

export const HEADER_MENU_WHEN_AUTH = [
  { name: "home", link: "/", show: true },
  { name: "F.A.Q", link: "/faq", show: true },
  { name: "About us", link: "/about-us", show: true },
  { name: "leaderboard", link: "/leaderboards" },
  { name: "Dashboard", link: "/panel/dashboard", show: true },
];
export const HEADER_MENU_WHEN_NOT_AUTH = [
  { name: "home", link: "/" },
  {
    name: "arena",
    child: [
      { name: "1V1 Games", link: "/arena/1v1game" },
      { name: "squad Games", link: "/arena/squad-games" },
    ],
  },
  // { name: "squads", link: "/panel/squads/new" },
  { name: "leaderboard", link: "/leaderboards" },
  { name: "what we do", link: "/about-us" },
];

export const MOBILE_BOTTOM_MENU_WHEN_AUTH = [
  {
    icon: "icon-Dashboard-Stroke",
    name: "Dashboard",
    linkName: "dashboard",
    link: "/panel/dashboard",
  },
  {
    icon: "icon-Chat-1",
    name: "Chat",
    linkName: "chats",
    link: "/panel/chats",
  },
  {
    icon: "icon-Find-Match-Stroke",
    name: "Find match",
    linkName: "find-match",
    link: "/panel/find-match",
  },
  {
    icon: "icon-Battle-Stroke",
    name: "Open matches",
    linkName: "open-matches",
    link: "/panel/open-match",
  },
  // {
  //   icon: "icon-Squads-Stroke-Stroke",
  //   name: "Squad",
  //   linkName: "squads",
  //   link: "/panel/squads/new",
  // },
];

export const PANEL_SIDEBAR = [
  {
    name: "Dashboard",
    linkName: "dashboard",
    icon: "icon-Dashboard-Stroke",
    active: true,
  },
  {
    name: "Open matches",
    linkName: "open-matches",
    icon: "icon-Battle-Stroke",
  },
  {
    name: "Find match",
    linkName: "find-match",
    icon: "icon-Find-Match-Stroke",
  },
  // {
  //   name: "Squad",
  //   linkName: "squads",
  //   icon: "icon-Squads-Stroke-Stroke",
  // },
  {
    name: "Chats",
    linkName: "chats",
    icon: "icon-Chat-1",
  },
  {
    name: "Trophies",
    linkName: "trophies",
    icon: "icon-Trophies-Stroke",
  },
  {
    name: "Followings",
    linkName: "friends",
    icon: "icon-Friend-Stroke",
  },
  {
    name: "Account setting",
    linkName: "account-setting",
    icon: "icon-Setting-Stroke",
  },
  {
    name: "Notifications",
    linkName: "notification",
    icon: "icon-Notification-Stroke",
  },
  {
    name: "Match History",
    linkName: "match-history",
    icon: "icon-History-Stroke",
  },
];
