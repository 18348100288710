export const initChat = (app) => {
  return new WebSocket(process.env.VUE_APP_INIT_CHAT_WS_URL);
};

export const sendMessegaChat = (app) => {
  return new WebSocket(process.env.VUE_APP_SEND_MESSAGE_CHAT_WS_URL);
};

export const supportChat = (app) => {
  return new WebSocket(process.env.VUE_APP_SUPPORT_CHAT_WS_URL);
};
