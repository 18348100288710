import { authApi } from "@/service/Config";

const prefix = "/wallet";

export const storeWithdrawApi = async (data) => {
  return authApi.post(`${prefix}/withdraw`, data).then((res) => res.data);
};

export const depositApi = async (data) => {
  return authApi.post(`${prefix}/deposit`, data).then((res) => res.data);
};

export const getWithdrawRequestsApi = async (page = 1) => {
  return authApi
    .get(`${prefix}/withdraw`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const cancelWithdrawRequestsApi = async (code) => {
  return authApi
    .post(`${prefix}/cancel-withdraw`, {
      code: code,
    })
    .then((res) => res.data);
};

export const getWalletsApi = async (type = null, page = 1) => {
  return authApi
    .get(`${prefix}/wallets`, {
      params: {
        type,
        page,
      },
    })
    .then((res) => res.data);
};

export const getTransactionsApi = async (page = 1) => {
  return authApi
    .get(`${prefix}/transactions`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const getBonusesApi = async (page = 1) => {
  return authApi
    .get(`${prefix}/bonus`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const getChartApi = async (tab = 1) => {
  return authApi
    .get(`${prefix}/chart`, {
      params: {
        tab,
      },
    })
    .then((res) => res.data);
};
