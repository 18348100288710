<template>
  <header class="sticky top-0 left-0 z-[2]">
    <div class="py-[8px] lg:py-0 bg-background-white-light dark:bg-background-white-dark flex items-center">
      <div class="container items-center grid grid-cols-4 lg:grid-cols-12 lg:gap-[20px]">
        <!-- btn show menu responsive -->
        <div class="col-span-1 flex items-center lg:hidden">
          <button @click="openSidebar = openSidebar = 1">
            <i class="icon-Hum-Menu text-[30px] dark:text-text-white-light" id="sidebarMobileIcon"></i>
          </button>
        </div>
        <!-- logo -->
        <div class="col-span-2 xl:col-span-2 grid place-items-center lg:place-items-baseline">
          <router-link to="/">
            <img src="@/assets/images/logo/dark.svg"
              class="w-[162px] lg:w-[200px] lg:max-h-[43px] xl:w-[253px] hidden dark:block" />
            <img src="@/assets/images/logo/light.svg"
              class="w-[162px] lg:w-[200px] lg:max-h-[43px] xl:w-[253px] dark:hidden block" />
          </router-link>
        </div>
        <!-- menu -->
        <MenuDesktop :menuItem="menuItem" />
        <!-- auth btn -->
        <div class="col-span-4 xl:col-span-4 hidden lg:flex items-center justify-end gap-[20px]">
          <div class="lg:flex items-center gap-[12px]">
            <button @click="showLogin"
              class="w-[134px] h-[40px] rounded col-span-1 text-lg hover:text-secondary hover:shadow-sm dark:hover:shadow-darkSm text-text-primary-light dark:text-text-primary-dark bg-tertiary-light dark:bg-tertiary-dark leading-[40px] transition-all">
              login
            </button>

            <button @click="showRegister"
              class="w-[134px] h-[40px] rounded col-span-1 text-lg bg-primary hover:bg-secondary text-white hover:shadow-sm leading-[40px] transition-all">
              sign up
            </button>
          </div>
          <!-- btn change mode -->
          <div>
            <ChangeModeBtnVue />
          </div>
        </div>


      </div>
    </div>
    <!-- floating auth btn -->
    <div
      class="lg:hidden z-[-1] fixed bottom-0 left-0 w-full py-[8px] px-[20px] bg-background-white-light dark:bg-background-white-dark">
      <div class="grid grid-cols-2 items-center gap-[12px] max-w-[350px] mx-auto">
        <div class="col-span-1">
          <button @click="showLogin"
            class="rounded w-full text-lg hover:text-secondary hover:shadow-sm text-text-primary-light dark:text-text-primary-dark bg-tertiary-light dark:bg-tertiary-dark h-[40px] leading-[40px] transition-all">
            login
          </button>
        </div>

        <div class="col-span-1">
          <button @click="showRegister"
            class="rounded w-full text-lg bg-primary hover:bg-secondary text-white hover:shadow-sm h-[40px] leading-[40px] transition-all">
            sign up
          </button>
        </div>
      </div>
    </div>
    <RegisterModal />
    <LoginModal />
    <ForgetPassword />
  </header>
  <!-- auth modal -->
  <!-- mobile sidebar nav -->
  <SidebarMoblie :open="openSidebar" id="sidebarMobile" @onClose="onClose" v-click-outside="onClose">
    <div class="grid grid-cols-1 gap-[20px]">
      <MobileNav :menuItem="menuItem" @close-sidebar="onClose" />
      <div class="flex items-center justify-between">
        <span>theme mode:</span>
        <ChangeModeBtnVue :horizental="true" />
      </div>

      <div class="grid grid-cols-4 gap-[12px]">
        <router-link v-for="Icon in socialMedia" :to="Icon.link" :key="Icon.link">
          <img :src="Icon.icon" />
        </router-link>
      </div>
    </div>
  </SidebarMoblie>
</template>

<script>
import { ref, computed, watch } from "vue";
import MenuDesktop from "./desktop/MenuDesktop.vue";
import ChangeModeBtnVue from "@/components/layout/common/header/ChangeModeBtn.vue";
import SidebarMoblie from "@/components/layout/mobile/SidebarMoblie.vue";
import MobileNav from "./mobile/MobileNav.vue";
import RegisterModal from "./auth-modal/RegisterModal.vue";
import LoginModal from "./auth-modal/LoginModal.vue";
import ForgetPassword from "./auth-modal/forgetPassword.vue";
import { toast } from "@/utilities/Toaster";
import { useStore } from "vuex";
import { SOCIAL_MEDIA, HEADER_MENU_WHEN_NOT_AUTH } from "@/helper/constant";
import { $vfm } from "vue-final-modal";
import { useRoute } from "vue-router";
import { filled } from "@/helper";


export default {
  name: "PublicHeaderLayout",
  components: {
    MenuDesktop,
    ChangeModeBtnVue,
    SidebarMoblie,
    MobileNav,
    RegisterModal,
    LoginModal,
    ForgetPassword,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const userIsAgreeToUseCookies = computed(() => store.getters.userIsAgreeToUseCookies);
    const refferalNumber = computed(() => route.query.refferal_token);
    const openSidebar = ref(0);
    const menuItem = ref(HEADER_MENU_WHEN_NOT_AUTH);
    const itemFocus = ref(true);
    const socialMedia = ref(SOCIAL_MEDIA);


    watch(refferalNumber, () => {
      if (filled(refferalNumber.value)) {
        store.dispatch('setRefferaledToken', refferalNumber.value);
      }
    },
      {
        immediate: true
      });



    const onClose = (e) => {
      if (openSidebar.value && e?.target?.id !== 'sidebarMobileIcon') {
        openSidebar.value = 0;
      }
    };

    const showLogin = async () => {
      if (userIsAgreeToUseCookies.value) {
        $vfm.show('login');
      } else {
        $vfm.show('forget-cookie')
      }
    };

    const showRegister = async () => {
      if (userIsAgreeToUseCookies.value) {
        $vfm.show('Register');
      } else {
        $vfm.show('forget-cookie')
      }
    };

    return {
      menuItem,
      itemFocus,
      socialMedia,
      openSidebar,
      userIsAgreeToUseCookies,
      onClose,
      showLogin,
      showRegister,
    }
  }
};
</script>
