<template>
  <div class="relative">
    <div :class="[className]"
      class="max-sm:!top-0 max-sm:!right-0 max-sm:!left-0 max-sm:!bottom-0 max-sm:!w-auto max-sm:!min-w-[auto] max-sm:!translate-y-[unset] max-sm:!translate-x-[auto] max-sm:overflow-y-auto fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] min-w-[364px] sm:w-[450px]">
      <div
        class="bg-background-white-light dark:bg-background-white-dark sm:rounded max-sm:h-full max-sm:overflow-y-auto relative">
        <!-- close btn  -->
        <div v-if="!withoutHeader"
          class="flex items-center justify-between gap-4 p-[12px] border-b border-tertiary-light dark:border-tertiary-dark fixed z-20 inset-x-0 top-0 bg-background-white-light dark:bg-background-white-dark rounded-t">
          <div>
            <slot name="title" />
          </div>
          <button v-if="closable" @click="close" class="lg:opacity-50 transition-all hover:opacity-100">
            <CloseIcon lightColor="#000000" darkColor="#ffffff" />
          </button>
        </div>
        <!-- content -->
        <div :class="[classContent, hasBtnOnBottom && 'pb-[80px]', !withoutHeader && '!pt-[65px]']"
          :style="footerSize > 0 && `padding-bottom : ${footerSize}px !important`"
          class="p-[20px] lg:max-h-[750px] scrollbar-hide overflow-y-scroll">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/Icons/CloseIcon.vue"
import { $vfm } from "vue-final-modal";
import { useStore } from "vuex";
export default {
  name: "ModalLayout",
  components: {
    CloseIcon,
  },
  props: {
    modalName: {},
    class: {},
    classContent: {},
    hasBtnOnBottom: {
      type: Boolean,
      default: false
    },
    footerSize: {
      type: Number,
      default: 0
    },
    closable: {
      type: Boolean,
      default: true
    },
    withoutHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      className: this.class,
    };
  },
  setup(props, { emit }) {
    const store = useStore();

    const close = () => {
      store.dispatch('setApiErrors', null);
      $vfm.hide(props.modalName);
      emit('modalClosed');
    };

    return {
      close
    }
  }

};
</script>
