<template>
  <button :class="{ [`btn-${size}`]: size, [ClassName]: ClassName, [cursor]: cursor }"
    class="bg-primary text-text-white-light dark:text-text-white-light transition-all hover:bg-secondary hover:shadow-sm flex items-center justify-center gap-[8px]">
    <span :class="spanClass">
      <slot />
    </span>

    <slot name="icon" />
  </button>
</template>

<script>
export default {
  name: "ButtonPrimary",
  props: ["size", "class", "cursor", "spanClass"],
  data() {
    return {
      ClassName: this.class,
    };
  },
};
</script>
