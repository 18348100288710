<template>
  <div
    class="flex items-stretch overflow-hidden rounded w-full border-2 border-solid border-primary dark:border-secondary">
    <div class="w-[44px] h-[46px] bg-primary dark:bg-secondary grid place-items-center">
      <i v-if="icon" :class="icon" class="text-white text-3xl" />
      <img v-if="imgIcon" :src="imgIcon" class="w-[24px] h-[24px]" />
    </div>
    <input type="text" :placeholder="placeholder" @keypress="isValidate($event)"
      class="flex-grow block px-[12px] bg-background-white-light dark:bg-background-white-dark" :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)" @keyup="$emit('keyup')" @focusin="$emit('focusin')"
      @focusout="$emit('focusout')" />
  </div>
</template>

<script>
import { gamerTagValidation, inputsLang } from "@/helper";

export default {
  name: "inputIcon",
  props: {
    imgIcon: {},
    icon: {},
    placeholder: {},
    modelValue: {},
    isGamerTag: {
      default: false
    }
  },
  emits: ["update:modelValue", "keyup", "focusin", "focusout"],
  setup(props) {

    const isValidate = (e) => {
      if (props.isGamerTag) {
        gamerTagValidation(e);
      }
    };

    return {
      isValidate
    }

  }
};
</script>
