export default {
  home: {
    title: "Wagering on Online Video Games - Gameclude",
    description:
      "Immerse yourself in the world of online gaming wagering by participating in matches, tournaments, and events to claim victory and real money in games like FC 24,  madden, nba 2k, call of duty & more! - Gameclude",
    keywords:
      "wagering, online video games, cash prizes, gaming champion, tournaments, events, victory, cash rewards, competitive gaming, online wagering game",
  },
  arena1v1Game: {
    title: "Online Games for Wagering Matches - Gameclude",
    description:
      "Discover and play exciting online games on our platform to engage in wagering matches, tournaments, and events for a chance to win real money in games like FC 24,  madden, nba 2k, call of duty & more! - Gameclude",
    keywords:
      "cash prizes, online games, wagering, tournaments, events, win real money, engage, win rewards, online wagering game",
  },
  arenaSquadGame: {
    title: "Online Games for Wagering Squad Matches - Gameclude",
    description:
      "Discover and play exciting online squad games on our platform to engage in wagering matches, tournaments, and events for a chance to win real money in games like FC 24,  madden, nba 2k, call of duty & more! - Gameclude",
    keywords:
      "cash prizes, online games, wagering, tournaments, events, win real money, engage, squad, squad games, win rewards, online wagering game",
  },
  cluderProfile: {
    title: "Wagering Profile of :userName - Gameclude",
    description:
      "Customize your profile to showcase your gaming achievements, connect with other players, and participate in thrilling wagering matches and tournaments - Gameclude",
    keywords:
      "wagering profile, gaming community, gaming achievements, connect with players, wagering matches, tournaments, wagering tournaments",
  },
  squadProfile: {
    title: "Wagering Profile of :squadName Squad - Gameclude",
    description:
      "Customize your squad profile to showcase your gaming achievements, connect with other players, and participate in thrilling wagering matches and tournaments - Gameclude",
    keywords:
      "wagering profile, gaming community, gaming achievements, connect with players, wagering matches, squads, wagering tournaments, clans, alliances, wagering squads",
  },
  leaderboard: {
    title: "Wagerer Champions on the Leaderboard - Gameclude",
    description:
      "Compete against other players for the top spot on the wagering leaderboard, earn cash prizes, and prove your gaming dominance on our platform - Gameclude",
    keywords:
      "top wagerers, leaderboard, join ranks, wagerers leaderboard, showcase expertise, wagering, competition, ranks, online game,  gaming dominance",
  },
  blogs: {
    title: "Latest Wagering Games News - Gameclude",
    description:
      "Stay updated with the latest news and updates in the world of wagering games, including tips, strategies, and insights to enhance your gaming experience on our platform - Gameclude",
    keywords: "wagering news, wagering updates",
  },
  blog: {
    title: ":blogTitle - Gameclude",
    description: ":blogBriefDescription - Gameclude",
    keywords: ":blogKeywords",
  },
  tournamentDetail: {
    title: ":tournamentName Wagering Tournament - Gameclude",
    description:
      "Show off your gaming skills and strategic prowess in the :tournamentName tournament to outplay opponents and claim victory, cash prizes, and the admiration of your peers - Gameclude",
    keywords:
      "show off skills, wagering tournament, strategic prowess, outplay opponents, claim victory, cash prizes, admiration, gaming community, wagering, online wagering game",
  },
  findMatch: {
    title: "Online Games for Wagering Matches - Gameclude",
    description:
      "Discover and play exciting online games on our platform to engage in wagering matches, tournaments, and events for a chance to win real money - Gameclude",
    keywords:
      "cash prizes, online games, wagering, tournaments, events, win real money, engage, win rewards, online wagering game",
  },
  gameMatches: {
    title: ":gameName Wagering Matches and Tournaments - Gameclude",
    description:
      "Create or join :gameName wagering matches and tournaments on our platform to start making money while showcasing your gaming skills and competing for cash prizes - Gameclude",
    keywords:
      "find :gameName, wagering :gameName, :gameName tournaments, wagering :gameName tournaments, online :gameName tournaments, online :gameName matches, online :gameName wagering matches, :gameName",
  },
  openMatch1v1: {
    title: "1v1 :gameName Wager Match Details - Gameclude",
    description:
      "Get a glimpse into the intense head to head :gameName wager match, revealing their strategies, interactions, and the excitement of competitive gameplay - Gameclude",
    keywords:
      "find :gameName, wagering :gameName, :gameName match, wagering :gameName details, 1v1 :gameName, online :gameName matches, :gameName wagering matches, :gameName wagering duels, head to head :gameName, :gameName",
  },
  openMatchTournament: {
    title:
      "1v1 :gameName Wager Match Details of :tournamentName the Wagering Tournament - Gameclude",
    description:
      "Analyze the results of the :gameName Wagering 1v1 match of :tournamentName Tournament, where every move and decision can change the course of the game - Gameclude",
    keywords:
      "find :gameName, wagering :gameName, :gameName match, wagering :gameName tournament, 1v1 :gameName, online :gameName matches, :gameName wagering tournament, :gameName wagering duels, :gameName tournaments, :gameName",
  },
};
