<template>
  <div class="grid place-items-center relative group px-[8px]">
    <router-link :to="{ name: 'chats' }" class="btn-hover rounded !p-[8px] group-hover:btn-active">
      <i class="icon-Chat-1 text-[26px] relative h-[25px] block">
        <span v-if="chatCount" class="absolute bottom-0 right-0 bg-alert text-text-white-light rounded w-[12px] h-[12px]
                    text-xs grid place-items-center">
          {{ chatCount }}
        </span>
      </i>
    </router-link>
    <div
      class="overflow-y-auto scrollbar-hide absolute top-full left-0 px-[9px] w-[390px] max-h-[300px] min-h-[100px]  hidden group-hover:animate-fade group-hover:flex flex-col ">
      <div class="overflow-y-auto scrollbar-hide w-[372px] max-h-[300px] min-h-[100px] !bg-background-main-light
     dark:!bg-background-main-dark border-[2px] border-t-0 border-solid border-tertiary-light dark:border-tertiary-dark
       rounded-b p-[12px]">
        <div v-if="unreadArchive.length > 0" class="flex flex-col gap-[12px]">
          <router-link :to="{
      name: 'chats',
      query: {
        chat: chat?.room_uuid,
      },
    }" v-for="chat in unreadArchive" :key="chat"
            class="relative btn-hover bg-background-white-light dark:bg-background-white-dark rounded p-[12px] grid grid-cols-12 gap-[4px]">
            <div class="text-md col-span-11">
              <span class="font-medium block whitespace-nowrap text-ellipsis overflow-hidden">
                {{ chat.room_name }}
              </span>
              <span
                class="block whitespace-nowrap text-ellipsis overflow-hidden text-text-secondary-light dark:text-text-secondary-dark">
                {{ chat.message }}
              </span>
            </div>
            <div class="col-span-1 grid place-items-center">
              <i class="icon-Down-Stroke inline-block rotate-[-90deg]" />
            </div>

            <span
              class="absolute top-0 right-0 bg-alert text-text-white-light rounded w-[20px] h-[20px] text-sm grid place-items-center">
              {{ chat.unread_count }}
            </span>
          </router-link>
        </div>
        <div v-else class="flex w-full min-h-[90px] justify-center items-center">
          <NotFound type="headerChat" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import NotFound from "@/components/Common/NotFound.vue"

export default {
  name: "HeaderChat",
  components: {
    NotFound
  },
  setup() {
    const store = useStore();
    const unreadArchive = computed(() => store.state.Chat.unreadArchive);
    const chatCount = computed(() => store.getters.chatUnreadCount);

    return {
      unreadArchive,
      chatCount
    };
  },
};
</script>
