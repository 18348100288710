import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import User from "@/store/modules/User";
import Squad from "@/store/modules/Squad";
import Game from "@/store/modules/Game";
import Match from "@/store/modules/Match";
import Chat from "@/store/modules/Chat";
import Common from "@/store/modules/Common";


const store = createStore({
  plugins: [
    createPersistedState({
      strictMode: true,
      key: "gameClude",
      paths: ["User", "Squad", "Game", "Match"],
    }),
  ],
  modules: {
    User,
    Squad,
    Game,
    Match,
    Chat,
    Common
  },
});

export default store;
