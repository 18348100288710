<template>
  <button @click="changeMode" :class="{
    'w-[15.79px]': !horizental,
    'h-[40px]': !horizental,
    'w-[40px]': horizental,
    'h-[15.79px]': horizental,
  }" class="bg-text-white-light dark:bg-text-tertiary-dark rounded-full changeModeShadow relative">
    <span
      class="changeModeShadowIcon transition-all grid place-items-center absolute rounded-full w-[13.68px] h-[13.68px] bg-text-white-light"
      :class="{
        'left-[50%]': !horizental,
        'left-0': !isDark && horizental,
        'left-[calc(100%-13.68px)]': isDark && horizental,

        'top-[calc(100%-13.68px)]': !isDark && !horizental,
        'top-[1px]': isDark && !horizental,

        'top-[50%]': horizental,

        'translate-y-[-50%]': horizental,
        'translate-x-[-50%]': !horizental,
      }">
      <i class="dark:text-text-primary-light" :class="!isDark ? 'icon-Light-Mode' : 'icon-Night-Mode'"></i>
    </span>
  </button>
</template>

<script>
export default {
  name: "ChangeModeBtn",
  props: ["horizental"],

  data() {
    return {
      isDark: window.matchMedia("(prefers-color-scheme: dark)").matches,
    };
  },

  mounted() {
    this.initTheme();
  },

  computed: {
    isDarkFromStore() {
      return this.$store.getters.themeIsDark;
    },
    theme() {
      return this.$store.state.User.theme;
    }
  },

  methods: {
    removeThemeNameFromBody() {
      document.body.classList.remove("light");
      document.body.classList.remove("dark");
    },

    changeMode() {

      this.isDark = !this.isDark;
      const Mode = this.isDark ? "dark" : "light";
      this.$store.dispatch('changeTheme', { theme: Mode.toUpperCase() });
      this.removeThemeNameFromBody();
      document.body.classList.add(Mode);
    },

    initTheme() {
      const mode = this.theme;
      this.isDark = this.isDarkFromStore;

      this.removeThemeNameFromBody();


      if (!!mode) {
        if (mode === "dark") {
          this.isDark = true;
          document.body.classList.add(mode);
        } else {
          this.isDark = false;
          document.body.classList.add(mode);
        }
        return;
      }

      const MediaIsDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;

      const MediaMode = MediaIsDark ? "dark" : "light";

      this.isDark = MediaIsDark;
      document.body.classList.add(MediaMode);

      this.$store.dispatch('changeTheme', { theme: MediaMode.toUpperCase() });

    }
  },

  watch: {
    theme() {
      this.initTheme();
    }
  }

};
</script>

<style scoped>
.changeModeShadow {
  box-shadow: inset 0px 0px 3px rgba(14, 22, 29, 0.42);
}

.changeModeShadowIcon {
  box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.34);
}
</style>
