<template>
  <div>
    <Transition name="fadeup">
      <div v-if="showCookieBox"
        class="bg-tertiary-light dark:bg-tertiary-dark fixed max-lg:inset-x-0 px-5 py-3 lg:rounded-t-[5px] flex max-lg:flex-col items-center gap-4 z-50 lg:left-[50%] lg:translate-x-[-50%] bottom-0">
        <div class="text-lg font-[400]">
          By continuing to browse GameClude, you agree to our
          <span @click="showCookiesModal = true"
            class="!font-[600] cursor-pointer transition-colors hover:text-primary dark:hover:text-secondary tracking-wide">
            use of cookies
          </span>
        </div>

        <ButtonPrimary @click="acceptCookiesHandler" size="sm" class="!py-1 !px-4 max-sm:!w-full max-sm:!block">
          Accept
        </ButtonPrimary>
      </div>
    </Transition>

    <VueFinalModal name="cookiesModal" v-model="showCookiesModal">
      <ModalLayout modalName="cookiesModal" :hasBtnOnBottom="true" class="sm:!w-[650px]">
        <template #title>
          <div class="text-md !font-[500]">Cookies</div>
        </template>
        <div class="text-lg !font-[400]">
          Cookies are small amounts of data stored in separate files within your
          computer's internet browser. Gameclude uses cookies and others similar
          technologies for the following general purposes:
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6 mb-6">
          <div>
            <div class="flex items-center justify-between gap-4 mb-2">
              <div class="text-lg !font-[600] tracking-wide">Essential</div>
              <SwitchForm @click.prevent v-model="essentialCheck" :checked="true"
                class="pointer-events-none opacity-60" />
            </div>
            <div class="text-md !font-[400]">
              These are cookies that are required for the operation of our
              website. They include, for example, cookies that enable you to
              log-in. They enables us to personalize our content for you and
              deliver relevant experience.
            </div>
          </div>
          <div>
            <div class="flex items-center justify-between gap-4 mb-2">
              <div class="text-lg !font-[600] tracking-wide">Analytical/Performance</div>
              <SwitchForm v-model="analyticalCheck" :checked="true" />
            </div>
            <div class="text-md !font-[400]">
              These cookies record your visit to the website, the pages you have
              visited, and the links you have followed. Gamclude will use this
              information to make platform and the advertising displayed on it
              more relevant to your interests.
            </div>
          </div>
          <div>
            <div class="flex items-center justify-between gap-4 mb-2">
              <div class="text-lg !font-[600] tracking-wide">Targeting/Advertising</div>
              <SwitchForm v-model="targetingCheck" :checked="true" />
            </div>
            <div class="text-md !font-[400]">
              Used to recognize and count the number of visitors and to see how
              visitors move around platform when they are using it. This let
              Gameclude to improve the way the it works, for example by helping
              to ensure that users are easily finding what they are looking for.
            </div>
          </div>
          <div>
            <div class="flex items-center justify-between gap-4 mb-2">
              <div class="text-lg !font-[600] tracking-wide">Third Party</div>
              <SwitchForm v-model="thirdPartyCheck" :checked="true" />
            </div>
            <div class="text-md !font-[400]">
              Gameclude may allow business partners to use cookies or other
              similar technologies on or outside the website for the same
              purposes identified above, including collecting information about
              your online activities over time and across different websites,
              applications, and ....
            </div>
          </div>
        </div>

        <div class="rounded-[4px] bg-tertiary-light dark:bg-tertiary-dark p-4 text-lg">
          You can opt-out to these cookies by deleting them from your browser
          setting.
        </div>

        <div
          class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
          <ButtonPrimary @click="acceptCookiesHandler" size="sm"
            class="!py-1 !px-4 max-sm:!w-full max-sm:!block sm:ml-auto">
            Save changes
          </ButtonPrimary>
        </div>
      </ModalLayout>
    </VueFinalModal>

    <!--forget cookie modal-->
      <VueFinalModal name="forget-cookie" v-model="showForgetCookies">
        <ModalLayout modalName="forget-cookie" :has-btn-on-bottom="true"  class="xl:!min-w-[900px] !z-[9999999]">
          <div class="text-xl !font-[400]">
            Using Gameclude requires the acceptance of our cookies, If you wish not to accept them please close the page.
          </div>
          <div class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
            <div class="sm:flex sm:items-center sm:justify-end max-sm:grid max-sm:grid-cols-2 gap-[8px]">
              <ButtonTertiary @click="showCookiesModalHandler" size="sm">
                Change setting
              </ButtonTertiary>
              <ButtonPrimary @click="acceptCookiesHandler" size="sm">
                Accept
              </ButtonPrimary>
            </div>
          </div>
        </ModalLayout>
      </VueFinalModal>

  </div>
</template>
<script >
// imports
import { ref, onMounted, computed } from "vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import ModalLayout from "@/components/modal/ModalLayout.vue";
import SwitchForm from "@/components/form-component/switch.vue";
import { useStore } from "vuex";
import { $vfm } from "vue-final-modal";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
// data
export default {
  components: {
    ButtonTertiary,
    ButtonPrimary,
    ModalLayout,
    SwitchForm
  },
  setup() {
    const store = useStore();
    const showCookieBox = ref(false);
    const showCookiesModal = ref(false);
    const essentialCheck = ref(true);
    const analyticalCheck = ref(true);
    const targetingCheck = ref(true);
    const thirdPartyCheck = ref(true);
    const showForgetCookies = ref(false)

    onMounted(() => {
      showCookieBox.value = !store.getters.userIsAgreeToUseCookies;
    });

    const acceptCookiesHandler = () => {
      store.dispatch('setAgreeToCookies', {
        essentialCheck: true,
        analyticalCheck: analyticalCheck.value,
        targetingCheck: targetingCheck.value,
        thirdPartyCheck: thirdPartyCheck.value
      });

      showCookieBox.value = false;
      showForgetCookies.value = false
      $vfm.hide('cookiesModal');
      $vfm.show('Register');
    };

    const showCookiesModalHandler = () => {
      showForgetCookies.value = false
      showCookiesModal.value = true
    }


    return {
      store,
      showCookieBox,
      showCookiesModal,
      essentialCheck,
      analyticalCheck,
      targetingCheck,
      thirdPartyCheck,
      showForgetCookies,
      acceptCookiesHandler,
      showCookiesModalHandler,
    }
  }
}
</script>
<style scoped>
.fadeup-enter-active,
.fadeup-leave-active {
  transition: all 0.5s ease;
}

.fadeup-enter-from,
.fadeup-leave-to {
  opacity: 0;
  bottom: -50px !important;
}
</style>
