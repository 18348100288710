<template>
  <div class="grid place-items-center relative group px-[8px]">
    <router-link :to="{ name: 'notification' }" class="btn-hover rounded !p-[8px] group-hover:btn-active">
      <i class="icon-Notification-Stroke text-[26px] relative h-[25px] block">
        <span v-if="userNotificationCount > 0" :class="{
          'animate-customBounce ': hasActionNotification,
        }" class="absolute bottom-0 right-0 bg-alert text-text-white-light rounded w-[12px] h-[12px] text-xs grid place-items-center">
          {{ userNotificationCount }}
        </span>
      </i>
    </router-link>
    <div
      class="overflow-y-auto scrollbar-hide absolute top-full left-0 px-[9px] w-[390px] max-h-[300px] min-h-[100px]  hidden group-hover:animate-fade group-hover:flex flex-col ">
      <div class="overflow-y-auto scrollbar-hide w-[372px] max-h-[300px] min-h-[100px] !bg-background-main-light
     dark:!bg-background-main-dark border-[2px] border-t-0 border-solid border-tertiary-light dark:border-tertiary-dark
       rounded-b p-[12px]">
        <div v-if="userNotificationCount > 0" class="flex flex-col gap-[10px]">
          <div class="flex justify-between">
            <button @click="setAllNotificationsToRead"
              class="flex justify-end text-primary dark:text-secondary hover:text-secondary dark:hover:!text-primary transition-all ">
              <span class="relative">
                <span class="absolute left-[50%] -translate-x-[50%]" v-if="readAllLoading">
                  <SpinLoading />
                </span>
                Mark all as read
              </span>
            </button>
            <router-link :to="{ name: 'notification' }"
              class="text-primary dark:text-secondary hover:text-secondary dark:hover:!text-primary text-sm flex items-center gap-[8px]">
              <span> See All </span>
              <i class="icon-Arrow-Stroke-1 rotate-180 block"></i>
            </router-link>
          </div>
          <NotificationBuilder v-for="notification in userNotifications" :key="notification.id"
            :notification="notification" :isInHeader="true" />
        </div>
        <div v-else class="flex w-full min-h-[90px] justify-center items-center">
          <NotFound type="headerNotificationS" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NotificationBuilder from "@/components/pages/panel/notification/NotificationBuilder.vue";
import {
  setNotificationsReadAllApi
} from "@/service/api/NotificationApi";
import SpinLoading from "@/components/layout/common/utility/SpinLoading.vue";
import NotFound from "@/components/Common/NotFound.vue"

export default {
  name: "NotificationDesktop",
  components: {
    NotificationBuilder,
    SpinLoading,
    NotFound
  },
  props: {
    userNotificationCount: {
      default: 0,
    },
    hasActionNotification: {
      default: false
    },
    userNotifications: {
      default: []
    }
  },
  emits: ["refreshNotificationCount"],
  setup() {
    const route = useRoute();
    const store = useStore();
    const user = computed(() => store.state.User.user);
    const readAllLoading = ref(false);


    const setAllNotificationsToRead = async () => {
      readAllLoading.value = true;
      const response = await setNotificationsReadAllApi();
      readAllLoading.value = false;
      if (response) {
        getUserNotificationsCount();
      }
    };

    const getUserNotificationsCount = async () => {
      store.dispatch('UpdateUserNotificationsCount');
    };

    return {
      user,
      readAllLoading,
      setAllNotificationsToRead
    };
  },
};
</script>
