const state = {
  platformId: null,
  serviceId: null,
  game: null,
  service: null,
};

const getters = {};

const actions = {
  async setMatchItems({ commit, dispatch }, data) {
    commit("_setMatchItems", data);
  },
  async setGame({ commit, dispatch }, data) {
    commit("_setGame", data);
  },
  async clearGame({ commit, dispatch }, data) {
    commit("_clearGame", data);
  },
  async setService({ commit, dispatch }, data) {
    commit("_setService", data);
  },
  async clearService({ commit, dispatch }, data) {
    commit("_clearService", data);
  },
};

const mutations = {
  _setMatchItems: (state, data) => {
    state.serviceId = data.serviceId;
  },
  _setGame: (state, data) => {
    state.game = data;
  },
  _clearGame: (state, data) => {
    state.game = null;
  },
  _setService: (state, data) => {
    state.service = data;
  },
  _clearService: (state, data) => {
    state.service = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
