<template>
  <div class="w-full">
    <div v-if="type == 'BattleFindMatch'" class="flex flex-col gap-2 justify-center items-center">
      <div :class="customClass">
        <NotFoundAnimation />
      </div>
      <span>
        No match found
      </span>
      <ButtonPrimary size="sm" class="text-sm justify-self-center w-[133px]" @click="showCreateBattle">
        Create one
      </ButtonPrimary>
    </div>
    <div v-if="type == 'TournamentFindMatch'" class="flex flex-col gap-2 justify-center items-center">
      <div :class="customClass">
        <NotFoundAnimation />
      </div>
      <span>
        No tournamet found
      </span>
      <ButtonPrimary size="sm" class="text-sm justify-self-center  w-[133px]" @click="showCreateTournament">
        Create one
      </ButtonPrimary>
    </div>
    <div v-if="type == 'chat'">

    </div>
    <div v-if="type == 'headerChat'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[55px]">
        <NotFoundAnimation />
      </div>
      <span>
        You are up to date!
      </span>
      <div class="flex justify-center items-center gap-[4px]">
        <RouterLink :to="{ name: 'leaderboard' }" class="grid">
          <ButtonTertiary size="sm" class="text-sm w-fit justify-self-center">
            leaderboard
          </ButtonTertiary>
        </RouterLink>
        <RouterLink :to="{ name: 'find-match' }" class="grid">
          <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
            Find match
          </ButtonPrimary>
        </RouterLink>
      </div>

    </div>
    <div v-if="type == 'notificationS'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[55px]">
        <NotFoundAnimation />
      </div>
      <span>
        There is no notification made yet
      </span>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>
    </div>
    <div v-if="type == 'headerNotificationS'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[55px]">
        <NotFoundAnimation />
      </div>
      <span>
        You are up to date!
      </span>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>
    </div>
    <div v-if="type == 'dashboardOnlineFriends'" class="flex justify-between items-center py-[15px]">
      <div class="flex gap-2 justify-center items-center">
        <div class="w-[55px]">
          <NotFoundAnimation />
        </div>
        <span>
          Start Following cluders
        </span>
      </div>
      <RouterLink :to="{ name: 'leaderboard' }" class="grid">
        <ButtonTertiary size="sm" class="text-sm w-fit justify-self-center">
          leaderboard
        </ButtonTertiary>
      </RouterLink>
    </div>
    <div v-if="type == 'dashboardNotifications'" class="flex justify-between items-center py-[15px]">
      <div class="flex gap-2 justify-center items-center">
        <div class="w-[55px]">
          <NotFoundAnimation />
        </div>
        <span>You are up to date!</span>
      </div>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>
    </div>
    <div v-if="type == 'dashboardQuickAccess'">

    </div>
    <div v-if="type == 'dashboardGameRecords'" class="flex justify-between items-center py-[15px]">
      <div class="flex gap-2 justify-center items-center">
        <div class="w-[55px]">
          <NotFoundAnimation />
        </div>
        <span>
          You haven’t yet
        </span>
      </div>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>

    </div>
    <div v-if="type == 'dashboardOpenMatch'" class="flex flex-col gap-2 justify-center items-center ">
      <div class="w-[55px]">
        <NotFoundAnimation />
      </div>
      <span>
        You have No open match
      </span>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>

    </div>
    <div v-if="type == 'trophiesLastAchievements'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[55px]">
        <NotFoundAnimation />
      </div>
      <span>
        Play more to achieve
      </span>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>
    </div>
    <div v-if="type == 'friends'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[70px]">
        <NotFoundAnimation />
      </div>
      <span>
        Start Following cluders
      </span>
      <RouterLink :to="{ name: 'leaderboard' }" class="grid">
        <ButtonTertiary size="sm" class="text-sm w-fit justify-self-center">
          leaderboard
        </ButtonTertiary>
      </RouterLink>
    </div>
    <div v-if="type == 'wallet'" class="flex justify-center items-center">
      <WalletDetailBox />
    </div>
    <div v-if="type == 'walletBonus'" class="flex justify-center items-center">
      <WalletDetailBox />
    </div>
    <div v-if="type == 'walletDeposit'" class="flex justify-center items-center">
      <WalletDetailBox />
    </div>
    <div v-if="type == 'walletWithdraw'" class="flex justify-center items-center">
      <WalletDetailBox />
    </div>
    <div v-if="type == 'battleMatchHistory'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[110px]">
        <NotFoundAnimation />
      </div>
      <span>
        You have not play a match yet
      </span>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>
    </div>
    <div v-if="type == 'tournamentMatchHistory'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[110px]">
        <NotFoundAnimation />
      </div>
      <span>
        You have not play a match yet
      </span>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>
    </div>
    <div v-if="type == 'topTen'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[70px]">
        <NotFoundAnimation />
      </div> <span>
        No one is in top 10 yet, Jump in.
      </span>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>
    </div>
    <div v-if="type == 'refferal'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[70px]">
        <NotFoundAnimation />
      </div>
      <span>
      </span>
      <!-- <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink> -->
    </div>
    <div v-if="type == 'top10'" class="flex flex-col gap-2 justify-center items-center">
      <div class="w-[70px]">
        <NotFoundAnimation />
      </div>
      <span>
      </span>
      <!-- <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink> -->
    </div>
    <div v-if="type == 'homeLatestMatch'" class="flex flex-col gap-2 justify-center items-center">
      <div :class="customClass">
        <NotFoundAnimation />
      </div>
      <span>
        No match found
      </span>
      <RouterLink :to="{ name: 'find-match' }" class="grid">
        <ButtonPrimary size="sm" class="text-sm w-fit justify-self-center">
          Find match
        </ButtonPrimary>
      </RouterLink>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import { useRoute, useRouter } from "vue-router";
import WalletDetailBox from "@/components/pages/panel/Wallet/WalletDetailBox.vue"
import NotFoundAnimation from "./NotFoundAnimation.vue"

export default {
  name: 'NotFound',
  components: {
    ButtonPrimary,
    ButtonTertiary,
    WalletDetailBox,
    NotFoundAnimation
  },
  props: {
    customClass: {
      default: 'w-[110px]'
    },
    type: {}
  },
  setup() {
    const router = useRouter();
    const route = useRoute();


    const showCreateBattle = () => {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          active: '#create-battle',
          form: new Date().getTime()
        }
      })
    };

    const showCreateTournament = () => {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          active: '#create-tournament',
          form: new Date().getTime()
        }
      })
    };

    return {
      showCreateBattle,
      showCreateTournament
    }
  }
}
</script>
