<template>
  <div class="col-span-1 lg:col-span-6">
    <ul
      class="dark:text-text-white-light hidden lg:flex items-center text-md xl:text-lg"
    >
      <li
        v-for="item in menuItem"
        :key="item.name"
        class="lg:py-[12px]"
        :class="item.child && 'relative'"
        @mouseleave="item.focus = false"
      >
        <div v-if="item.child">
          <button
            class="flex items-center gap-2 btn-hover-menu"
            :class="item.focus ? 'btn-active-menu ' : ''"
            @mouseenter="item.focus = true"
          >
            <span class="">
              {{ item.name }}
            </span>
            <i
              class="icon-Down-Stroke transition"
              :class="item.focus && 'rotate-180'"
            ></i>
          </button>

          <div
            class="absolute top-full left-0 bg-tertiary-light dark:bg-background-white-dark min-w-[200px] rounded-b overflow-hidden"
            v-show="item.focus"
          >
            <div
              class="py-[7px] px-[8px]"
              v-for="c in item.child"
              :key="c.name"
            >
              <router-link
                :to="c.link"
                class="block py-[5px] px-[10px] btn-hover-menu"
              >
                {{ c.name }}
              </router-link>
            </div>
          </div>
        </div>
        <span v-else>
          <span v-if="item.link">
            <router-link
              :to="item.link"
              class="btn-hover-menu block text-center"
            >
              {{ item.name }}
            </router-link>
          </span>
          <span v-else>{{ item.name }}</span>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MenuDesktop",
  props: ["menuItem"],
};
</script>
<style>
.btn-hover-menu {
  @apply hover:bg-background-main-light
  hover:text-secondary
  px-[15px]
  py-[10px]
  rounded
  hover:shadow-sm
  dark:hover:shadow-darkSm 
  transition-all;
}
.btn-active-menu {
  @apply bg-background-main-light
  text-secondary
  px-[15px]
  py-[10px]
  rounded
  shadow-sm
  transition-all;
}
</style>
