<template>
  <div :class="customClass" class=" border-background-main-light dark:border-background-main-dark"
    v-lazy:background-image="avatarLink" :style="{
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }">
    <slot />
  </div>
</template>
<script>
export default {
  name: "AvatarFrame",
  props: {
    avatarLink: {},
    customClass: {}
  }
}
</script>
