import { authApi } from "@/service/Config";

const prefix = "/profile/tags";

export const getUserTagsApi = async () => {
    return authApi.get(`${prefix}`).then((res) => res.data);
};

export const storeUserTagApi = async (data) => {
    return authApi.post(`${prefix}`, data).then((res) => res.data);
};
