<template>
  <Teleport to="body">
    <VueFinalModal name="PersonalInfoNotCompeletedModal" v-model="PersonalInfoNotCompeletedModal"
      class="!z-[99999] text-text-white-dark dark:text-text-white-light">
      <ModalLayout modalName="PersonalInfoNotCompeletedModal" :hasBtnOnBottom="true">
        <template #title>
          <div class="text-md !font-[500]">
            Compelete your personal info </div>
        </template>
        <div class="grid gap-[20px]">
          <p>
            Before you can play, you have to complete your personal info for
            this game.
          </p>
          <div
            class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark grid grid-cols-12 gap-[12px]">
            <ButtonPrimary size="md" class="col-span-9" @click="redirectToDashboard">
              <div class="flex gap-1">
                <span>
                  Redirect to personal info
                </span>
              </div>
            </ButtonPrimary>
            <ButtonTertiary size="md" class="col-span-3" @click=" $vfm.hide('PersonalInfoNotCompeletedModal')">
              back
            </ButtonTertiary>
          </div>
        </div>
      </ModalLayout>
    </VueFinalModal>
  </Teleport>
</template>
<script>
import { ref } from "vue";
import ModalLayout from "@/components/modal/ModalLayout.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import { useRouter } from "vue-router";
import { $vfm } from "vue-final-modal";

export default {
  name: "PersonalInfoIsNotCompletedModal",
  components: {
    ModalLayout,
    ButtonPrimary,
    ButtonTertiary
  },
  setup() {
    const router = useRouter();
    const PersonalInfoNotCompeletedModal = ref(null);


    const redirectToDashboard = () => {
      $vfm.hideAll();
      router.push({
        name: "dashboard"
      }, {
        replace: true
      });
    };

    return {
      PersonalInfoNotCompeletedModal,
      redirectToDashboard
    }
  }
}
</script>

