<template>
  <!-- TODO put width dynamic  -->
  <div class='pac-man' />
</template>
<script>
export default {
  name: "PacManLoading"
}
</script>
<style lang="scss">
.pac-man {
  border-radius: 50%;
  margin: 0 auto;
  // margin-top: 5em;
  border-radius: 100em 100em 0 0;
  background: #f00;
  transform-origin: bottom;
  animation: eating-top .5s infinite;

  &,
  &::before {
    width: 80px;
    height: calc(80px/2);
   @apply bg-primary;
  }

  &::before {
    content: '';
    display: block;
    margin-top: calc(80px/2);
    position: absolute;
    transform-origin: top;
    border-radius: 0 0 100em 100em;
    transform: rotate(80deg);
    animation: eating-bottom .5s infinite;
  }

  &::after {
    position: absolute;
    border-radius: 100em;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    margin-top: calc((80px / 2) - 10px);
    margin-left: calc((80px / 2) - 10px);
    transform-origin: center;
    animation:
      center .5s infinite,
      ball .5s -.33s infinite linear;
  }
}

@keyframes eating-top {
  0% {
    transform: rotate(-40deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-40deg);
  }
}

@keyframes eating-bottom {
  0% {
    transform: rotate(80deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(80deg);
  }
}

@keyframes center {
  0% {
    transform: rotate(40deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(40deg);
  }
}

@keyframes ball {
  0% {
    opacity: .7;
    box-shadow:
      70px 0 0 0 #e77c12,
      120px 0 0 0 #e77c12,
      170px 0 0 0 #e77c12,
      220px 0 0 0 #e77c12;
  }

  100% {
    box-shadow:
      20px 0 0 0 #e77c12,
      70px 0 0 0 #e77c12,
      120px 0 0 0 #e77c12,
      170px 0 0 0 #e77c12;
  }

}
</style>
