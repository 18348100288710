<template>
  <div class="hidden lg:block lg:col-span-6">

    <ul class="dark:text-text-white-light hidden lg:flex items-center text-md xl:text-lg">
      <li v-for="item in menuItem" :key="item.name" :class="{
        'lg:py-[12px]': item.name !== 'Dashboard',
      }" class="relative " @mouseleave="item.focus = false">
        <div v-if="item.child">
          <button class="flex items-center gap-2 btn-hover" :class="item.focus ? 'btn-active-menu ' : ''"
            @mouseenter="item.focus = true">
            <div class="flex gap-1 justify-center items-center " v-if="item.name == 'Dashboard' && !isInPanle">
              <AvatarFrame :avatarLink="userPhoto"
                customClass="min-w-[30px] h-[30px]  col-span-2 block relative overflow-hidden border-2 border-solid border-background-main-light dark:border-background-main-dark object-cover rounded bg-background-main-light dark:bg-background-main-dark p-[2.5px]" />
              <span>{{ user.username ?? item.name }}</span>
            </div>
            <span v-if="item.name !== 'Dashboard'">
              {{ item.name }}
            </span>
            <i class="icon-Down-Stroke transition" :class="item.focus && 'rotate-180'"></i>
          </button>

          <div
            class="absolute top-full left-0 bg-tertiary-light dark:bg-background-white-dark min-w-[200px] rounded-b overflow-hidden"
            v-show="item.focus">
            <div class="py-[7px] px-[8px]" v-for="c in item.child" :key="c.name">
              <router-link :to="c.link" class="block py-[5px] px-[10px] btn-hover-menu">
                {{ c.name }}
              </router-link>
            </div>
          </div>
        </div>
        <span v-else>
          <span v-if="item.link">
            <router-link :to="item.link" v-if="item.name == 'Dashboard' && !isInPanle"
              class="btn-hover-menu block text-center">
              <div class="flex gap-1 justify-center items-center ">
                <AvatarFrame :avatarLink="userPhoto"
                  customClass="min-w-[30px] h-[30px] col-span-2 block relative overflow-hidden border-2 border-solid border-background-main-light dark:border-background-main-dark object-cover rounded bg-background-main-light dark:bg-background-main-dark p-[2.5px]" />
                <span>{{ user.username ?? item.name }}</span>
              </div>
            </router-link>
            <router-link :to="item.link" v-if="item.name !== 'Dashboard'" class="btn-hover-menu block text-center">
              <span>
                {{ item.name }}
              </span>
            </router-link>
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import {
  filled,
  cluderCustomAvatar
} from '@/helper';
import {
  computed,
  ref
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import AvatarFrame from "@/components/Common/AvatarFrame.vue";

export default {
  name: "MenuDesktop",
  components: {
    AvatarFrame
  },
  props: ["menuItem"],
  setup() {
    const route = useRoute();
    const routeName = computed(() => route.name);
    const store = useStore();
    const user = computed(() => store.state.User.user);
    const customAvatar = ref(cluderCustomAvatar());
    const userPhoto = computed(() => filled(store.getters.userPhoto) ? store.getters.userPhoto : customAvatar.value);
    const isInPanle = computed(() => route.meta.isPanel);


    return {
      routeName,
      user,
      userPhoto,
      isInPanle
    }

  }
};
</script>
<style>
.btn-hover-menu {
  @apply hover:bg-background-main-light hover:text-secondary px-[15px] py-[10px] rounded hover:shadow-sm dark:hover:shadow-darkSm transition-all;
}

.btn-active-menu {
  @apply bg-background-main-light text-secondary px-[15px] py-[10px] rounded shadow-sm transition-all;
}
</style>
