export const UpdateUserWalletEvent = ".App\\Events\\UpdateUserWalletEvent";

export const BattleJoinRequestEvent = ".App\\Events\\BattleJoinRequestEvent";

export const BattleMatchChangedEvent = ".App\\Events\\BattleMatchChangedEvent";

export const broadcastNotificationEvent =
  ".App\\Events\\BroadcastNotificationEvent";

export const ClanWarMatchChangedEvent = ".App\\Events\\ClanWarMatchChangedEvent";

export const TeamupChangedEvent = ".App\\Events\\TeamupChangedEvent";

export const TournamentMatchChangedEvent =
".App\\Events\\TournamentMatchChangedEvent";

export const TournamentChangedEvent =
".App\\Events\\TournamentChangedEvent";

export const LatestMatchesEvent = ".App\\Events\\BroadcastLatestMatchesNotificationEvent";


export const newBattleChannelName = (gameId, serviceId) => {
  return `new-battle-${gameId}-${serviceId}`;
};
export const battleCreatedEvent = ".App\\Events\\BattleCreatedEvent";

export const newClanWarChannelName = (gameId, serviceId) => {
  return `new-clan-war-${gameId}-${serviceId}`;
};
export const clanWarCreatedEvent = "ClanWarCreatorTeamUpedEvetnt";

export const newTournamentChannelName = (gameId, serviceId) => {
  return `new-tournament-${gameId}-${serviceId}`;
};
export const tournamentCreatedEvent = ".App\\Events\\TournamentCreatedEvent";

export const OpenMatchesCountChangedEvent = ".App\\Events\\OpenMatchesCountChangedEvent";

export const TransactionIsDoneEvent = ".App\\Events\\TransactionIsDoneEvent";
