<template>
  <aside
    class="hidden lg:grid grid-cols-1 gap-[20px] bg-background-white-light dark:bg-background-white-dark sticky top-[80px] rounded pb-[20px]">
    <div class="grid grid-cols-5 gap-[8px] p-[12px]">
      <router-link :to="{ name: 'personal-info' }" class="col-span-2">
        <AvatarFrame :avatarLink="photo"
          customClass=" block relative overflow-hidden border-2 border-solid border-background-main-light dark:border-background-main-dark object-cover rounded min-w-[75px] w-[75px] h-[75px] bg-background-main-light dark:bg-background-main-dark p-[2.5px]">
          <span
            class="absolute right-0 bottom-0 w-[22px] h-[22px] bg-background-main-light dark:bg-background-main-dark rounded-tl grid place-items-center">
            <i class="icon-Edit-Stroke text-md" />
          </span>
        </AvatarFrame>
      </router-link>

      <div class="col-span-3 flex flex-col">
        <span class="font-medium text-md truncate"> {{ fullName }} </span>
        <span class="font-thin text-sm text-text-secondary-light dark:text-text-secondary-dark">
          {{ slogan }}
        </span>
      </div>
    </div>
    <!-- =========nav======== -->
    <ul class="grid grid-cols-1 gap-[20px] pl-[12px] relative">
      <li v-for="item in nav" :key="item.name" :title="!isAuthenticated ? 'You should login to access ' : ''">
        <span v-if="!isAuthenticated && routeName !== item.linkName"
          class="cursor-not-allowed absolute w-full h-full"></span>
        <router-link :to="{ name: item.linkName }" @click="refreshTheSame(item)"
          class="flex items-center relative hover:text-secondary transition-all" :class="{
            'text-primary dark:text-secondary':
              routeName === item.linkName ||
              routeSection?.includes(item.linkName),
            disabled: !isAuthenticated && routeName !== item.linkName,
          }">
          <div @mouseover="() => {
            if (item.linkName === 'friends') {
              isHovered = true;
            }
          }
            " @mouseleave="() => {
          isHovered = false;
        }
          " class="flex-grow flex items-center gap-[12px]">
            <FollowingIcon v-if="item.linkName === 'friends'" :light-color="followLightColor"
              :dark-color="followDarkColor" />
            <i :class="item.icon" v-else class="text-[26px]" />
            <span class="text-2xl">{{ item.name }}</span>
          </div>
          <span v-if="unreadChatCount && item.linkName == 'chats'"
            class="grid place-items-center w-[20px] h-[20px] rounded bg-alert text-sm text-background-white-light mr-[20px]">
            {{ unreadChatCount }}
          </span>
          <span v-if="unreadNotificationCount && item.linkName == 'notification'"
            class="grid place-items-center w-[20px] h-[20px] rounded bg-alert text-sm text-background-white-light mr-[20px]">
            {{ unreadNotificationCount }}
          </span>
          <span v-if="openMatchCounts && item.linkName == 'open-matches'"
            class="grid place-items-center w-[20px] h-[20px] rounded bg-alert text-sm text-background-white-light mr-[20px]">
            {{ openMatchCounts }}
          </span>

          <span v-if="routeName === item.linkName ||
            routeSection?.includes(item.linkName)
          " class="absolute top-0 right-0 h-full w-[8px] rounded-l-[8px] bg-primary dark:bg-secondary" />
        </router-link>
      </li>
      <li>
        <a class="flex items-center relative transition-all" :class="{
          'hover:text-secondary': isAuthenticated,
        }" @click="$vfm.show('LogoutConfirmModal')">
          <button class="flex-grow flex items-center gap-[12px] disabled:opacity-60 disabled:cursor-not-allowed"
            :disabled="!isAuthenticated">
            <i class="icon-Logout-Stroke text-[26px]" />
            <span class="text-2xl">Logout</span>
          </button>
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { PANEL_SIDEBAR } from "@/helper/constant";
import FollowingIcon from "@/components/Icons/FollowingIcon.vue";
import AvatarFrame from "@/components/Common/AvatarFrame.vue";

export default {
  name: "AsidePanel",
  components: {
    FollowingIcon,
    AvatarFrame
  },
  props: {
    userNotificationCount: {
      default: 0,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const nav = ref(PANEL_SIDEBAR);
    const routeName = computed(() => route.name);
    const routeSection = computed(() => route.meta?.SectionName);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const fullName = computed(() => store.getters.userFullName);
    const slogan = computed(() => store.getters.userSlogan);
    const photo = computed(() => store.getters.userPhoto);
    const unreadChatCount = computed(() => store.getters.chatUnreadCount);
    const openMatchCounts = computed(() => store.state.User.openMatchCounts);
    const unreadNotificationCount = computed(() => props.userNotificationCount);
    const isHovered = ref(false);
    const followLightColor = computed(() => {
      if (isHovered.value || "friends" === routeName.value) {
        return "#1778ae";
      }
      return "#000000";
    });
    const followDarkColor = computed(() => {
      if (isHovered.value || "friends" === routeName.value) {
        return "#1778ae";
      }
      return "#ffffff";
    });

    const refreshTheSame = (nav) => {
      if (routeName.value == nav.linkName) {
        router.go(0);
      }
    };

    return {
      followLightColor,
      followDarkColor,
      unreadChatCount,
      unreadNotificationCount,
      nav,
      routeName,
      fullName,
      slogan,
      photo,
      isAuthenticated,
      routeSection,
      openMatchCounts,
      isHovered,
      refreshTheSame,
    };
  },
};
</script>
<style>
.disabled {
  opacity: 0.6;
  pointer-events: none;
}
</style>
