<template>
  <VueFinalModal name="forgetPass" v-model="showForgetPass">
    <ModalLayout modalName="forgetPass" :has-btn-on-bottom="true">
      <template #title>
        {{ !emailSent ? 'Forget password' : 'Link sent' }}
      </template>
      <div v-if="!emailSent" class="flex flex-col gap-[20px]">
        <div>
          <span class="text-text-secondary-light dark:text-text-secondary-dark block text-sm md:text-md">
            Assisting you in password recovery
          </span>
        </div>
        <!--  -->
        <form @submit.prevent="forgotPassword">
          <Input placeholder="email" name="email" :validation="'required|email'" v-model="email" :show-error="showError"
            @has-error="(data) => storeError(data)" @change-show-error="(data) => { showError = data }" :tab-index="1"
            :showErrorInInput="true" />
          <input type="submit" hidden :disabled="loading" />
        </form>

        <!--descriptions-->
        <div
          class="rounded p-[8px] text-text-secondary-light dark:text-text-secondary-dark bg-background-main-light dark:bg-background-main-dark">
          By submitting your email address, you’ll receive a link to reset your password.
        </div>

        <!--  -->
        <div
          class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
          <div class="max-sm:grid max-sm:grid-cols-2 gap-[8px] sm:flex sm:items-center sm:justify-end">
            <ButtonPrimary size="sm" class="max-sm:w-full sm:min-w-[82px]" type="submit" :disabled="loading" @click="forgotPassword">
              <div class="flex gap-1 ">
                <SpinLoading v-if="loading" />
                <span>
                  Send Link
                </span>
              </div>
            </ButtonPrimary>
            <ButtonTertiary :disabled="loading" @click="() => {
              $vfm.hide('forgetPass');
              $vfm.show('login');
            }
              "   class="max-sm:w-full min-w-[82px]" size="sm" type="button">Back</ButtonTertiary>
          </div>
        </div>
      </div>
      <!--  -->
      <div v-else>
        <circle-check-icon class="mx-auto mb-[12px]" light-color="#17B569" dark-color="#17B569" />
        <p class="mb-[12px] font-medium text-lg text-text-primary-light dark:text-text-primary-dark">
          Password recovery link sent! Please check your email inbox.
        </p>
        <p class="text-md text-text-secondary-light dark:text-text-secondary-dark">
          If you didnt find it, Make sure to check
          <strong> Junk </strong>
          or
          <strong> Spam </strong>
          folder
        </p>

        <div
          class="p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
          <div class="sm:flex sm:items-center sm:justify-end">
            <ButtonTertiary @click="() => { $vfm.hide('forgetPass') }" class="max-sm:w-full" size="sm" type="button">
              Close
            </ButtonTertiary>
          </div>
        </div>

      </div>
    </ModalLayout>
  </VueFinalModal>
</template>
<script>
import { ref } from "vue";
import Input from "@/components/form-component/input.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import CircleCheckIcon from "@/components/Icons/CircleCheckIcon.vue";

import { VueFinalModal } from "vue-final-modal";
import ModalLayout from "@/components/modal/ModalLayout.vue";
import { forgotPasswordApi } from "@/service/api/AuthApi";
import SpinLoading from "@/components/layout/common/utility/SpinLoading.vue";

export default {
  name: "forgetPassword",
  components: {
    Input,
    ButtonTertiary,
    ButtonPrimary,
    VueFinalModal,
    ModalLayout,
    CircleCheckIcon,
    SpinLoading
  },
  emits: ["onBack"],
  setup() {
    const showForgetPass = ref(null);
    const email = ref(null);
    const showError = ref(false);
    const inputErrors = ref([]);
    const loading = ref(false);
    const emailSent = ref(false);


    const storeError = (data) => {
      inputErrors.value = inputErrors.value.filter(err => err?.id !== data.id);
      inputErrors.value.push(data);
    };

    const checkError = () => {
      return inputErrors.value.filter(err => err.value === true).length !== 0;
    };

    const forgotPassword = async (e) => {
      if (checkError()) {
        return showError.value = true;
      }
      loading.value = true;
      const response = await forgotPasswordApi({
        email: email.value
      });
      if (response) {
        emailSent.value = true;
      }
      loading.value = false;
    };

    return {
      email,
      showError,
      inputErrors,
      loading,
      emailSent,
      showForgetPass,
      storeError,
      forgotPassword
    }
  }
};
</script>
