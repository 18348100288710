import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PublicRoutes from "@/router/PublicRoutes";
import PanelRoutes from "@/router/panelRoutes";
import store from "@/store";
import NotFound from "@/views/error/NotFound.vue";
import { useHead } from "@/composables/useHead";
import metaHeads from "@/utilities/metaHeads";

const routes = [
  ...PublicRoutes,
  ...PanelRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    meta: {
      layout: NotFound,
    },
  },
];
let router;

export function useRouter() {
  if (!router) {
    router = createRouter({
      history: createWebHistory(process.env.BASE_URL),
      routes,
      scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({ el: to.hash, behavior: "smooth" });
            }, 500);
          });
        }
        if (to.name == "wallet") {
          return;
        }
        return { top: 0 };
      },
    });

    router.beforeEach((to, from, next) => {
      if (to.meta.useStaticMetaData) {
        const routename = to?.meta.seoName;
        const meta = metaHeads[routename] ?? {};
        meta.href = window.location.origin + to.href;
        useHead(meta);
      }

      const requiresAuth = to.matched.some(
        (record) => record.meta?.requiresAuth
      );
      const hideWhenAuthenticated = to.matched.some(
        (record) => record.meta?.hideWhenAuthenticated
      );
      const isAuthenticated = store.getters.isAuthenticated;

      if (requiresAuth && !isAuthenticated) {
        next("/");
      }
      if (hideWhenAuthenticated && isAuthenticated) {
        return next({ name: "dashboard" });
      }

      next();
    });
  }

  return router;
}
