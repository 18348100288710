<template>
  <div class="w-[250px] grid grid-cols-1 items-center gap-[12px]">
    <div
      class="overflow-hidden w-[250px] grid grid-cols-1 items-center gap-[12px] bg-tertiary-light dark:bg-tertiary-dark rounded p-[8px]">
      <span class="text-text-secondary-light dark:text-text-secondary-dark text-sm text-left">
        Wallet Detail:
      </span>
      <div class="grid grid-cols-2 gap-[4px]">
        <div
          class="flex items-center rounded gap-[12px] p-[8px] py-[2px]  bg-background-white-light dark:bg-background-white-dark">
          <i class="icon-Wallet-Stroke text-[30px]"></i>
          <span class="font-medium text-lg">
            <span>$</span>
            <span>
              {{ walletAmount }}
            </span>
          </span>
        </div>
        <div
          class="flex items-center rounded gap-[5px] px-[8px] bg-background-white-light dark:bg-background-white-dark">
          <svg class="h-[40px] transition-all" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4812_36200)">
              <path
                d="M12.627 16.892C12.595 17.02 12.563 17.196 12.531 17.42L3.09904 16.412H12.699L12.627 16.892ZM12.363 18.716C12.315 19.084 12.291 19.42 12.291 19.724L2.83504 18.716H12.363ZM13.275 14.108L12.987 15.092L3.67504 14.108H13.275ZM12.315 21.02C12.379 21.468 12.435 21.804 12.483 22.028L3.14704 21.02H12.315ZM14.259 11.804C14.035 12.22 13.875 12.54 13.779 12.764L4.80304 11.804H14.259ZM13.035 23.348C13.243 23.716 13.483 24.012 13.755 24.236L5.45104 23.348H13.035ZM16.539 9.5C16.075 9.788 15.715 10.052 15.459 10.292L8.11504 9.5H16.539ZM18.651 24.86C16.635 24.86 15.187 24.468 14.307 23.684C13.443 22.9 13.011 21.692 13.011 20.06C13.011 19.372 13.123 18.356 13.347 17.012C13.827 14.26 14.651 12.26 15.819 11.012C17.003 9.764 18.867 9.14 21.411 9.14C22.003 9.14 22.675 9.204 23.427 9.332C24.195 9.444 24.755 9.588 25.107 9.764L23.763 13.436C22.819 13.212 21.931 13.1 21.099 13.1C20.283 13.1 19.699 13.18 19.347 13.34C18.995 13.5 18.779 13.82 18.699 14.3L17.595 20.588C18.171 20.716 18.763 20.78 19.371 20.78C20.667 20.78 21.851 20.588 22.923 20.204L22.539 24.236C21.515 24.652 20.219 24.86 18.651 24.86Z"
                class="fill-text-primary-light dark:fill-text-primary-dark" />
            </g>
            <defs>
              <clipPath id="clip0_4812_36200">
                <rect width="32" height="32" fill="white" transform="translate(0.210449)" />
              </clipPath>
            </defs>
          </svg>

          <span class="font-medium text-lg">
            {{ bonusAmount }}
          </span>
        </div>

      </div>

    </div>
    <ButtonPrimary size="sm" class="flex-row-reverse w-full" @click="$vfm.show('DepositModal')">
      Deposit
      <template #icon>
        <i class="icon-Deposit text-3xl" />
      </template>
    </ButtonPrimary>
  </div>
</template>
<script>
import { computed } from "vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import { useStore } from "vuex";

export default {
  name: 'WalletDetailBox',
  components: {
    ButtonPrimary
  },
  setup() {
    const store = useStore();
    const walletAmount = computed(() => store.getters.userWalletAmount);
    const bonusAmount = computed(() => store.getters.userBonusAmount);

    return {
      walletAmount,
      bonusAmount
    }
  }
}
</script>
