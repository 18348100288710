import { googleLoginApi, loginApi, signupApi } from "@/service/api/AuthApi";
import { updateProfileThemeApi, getProfileApi } from "@/service/api/ProfileApi";
import { useRouter } from "@/router";
import {
  getNotificationsApi,
  getNotificationsCountApi,
} from "@/service/api/NotificationApi";
import { cluderCustomAvatar, filled, getUtcTime } from "@/helper";
import { getSupportExistApi } from "@/service/api/ChatApi";

const router = useRouter();

const state = {
  user: null,
  token: null,
  theme: null,
  notificationCount: 0,
  unreadNotifications: [],
  defaultDeposit: null,
  emailVerificationTime: null,
  hasNewNotification: false,
  authToken: null,
  onlineSupport: null,
  suggestionChatUseded: null,
  onlineSupportUnreadCount: 0,
  onlineSupportHasAdmin: false,
  agreeToUseCookies: null,
  openMatchCounts: 0,
  refferaledToken: null,
  returnUrl: null,
};

const getters = {
  isAuthenticated: (state) => state.token !== null,
  personalInfoIsCompeleted: (state) =>
    state.user?.personal_info_is_completed ?? false,
  themeIsDark: (state) => state.theme === "dark",
  userWalletAmount: (state) =>
    state.user !== null ? state.user.wallet_amount.toFixed(1) : 0,
  userBonusAmount: (state) =>
    state.user !== null ? state.user.bonus_amount : 0,
  userFullName: (state) =>
    state.user !== null
      ? state.user.username === ""
        ? "Please fill your info"
        : state.user.username
      : "guest",
  userSlogan: (state) => (state.user !== null ? state.user.slogan : "guest"),
  userPhoto: (state) =>
    state.user !== null && state.user?.media?.avatar_small
      ? state.user?.media?.avatar_small
      : cluderCustomAvatar(),
  userTimeZone: (state) =>
    state.user !== null
      ? state.user.timezone?.title ??
        Intl.DateTimeFormat().resolvedOptions().timeZone
      : Intl.DateTimeFormat().resolvedOptions().timeZone,
  isEmailVerified: (state) =>
    state.user !== null && state.user.is_email_verified,
  authToken: (state) => state?.authToken,
  onlineSupport: (state) => state?.onlineSupport,
  userIsAgreeToUseCookies: (state) => filled(state.agreeToUseCookies),
  isBanned: (state) => state.user?.is_banned,
  isSuspended: (state) => state.user?.is_suspended,
  fineAmount: (state) => state.user?.fine_amount ?? 0,
};

const actions = {
  async register({ commit, dispatch, getters }, data) {
    if (getters.onlineSupport) {
      data = {
        ...data,
        support_token: getters.onlineSupport.support_token,
      };
    }
    const response = await signupApi(
      data,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    if (response) {
      commit("_updateAuthenticate", response);
      let time = getUtcTime();
      time = time.setMinutes(time.getMinutes() + 3);
      dispatch("setEmailVerificationTime", time);
      dispatch("getUserSquadInfo", { root: true });
      dispatch("getUserSquadMemberInfo", { root: true });
      dispatch("checkOnlineSupportExist");
    }
  },

  async login({ commit, dispatch, getters }, data) {
    if (getters.onlineSupport) {
      data = {
        ...data,
        support_token: getters.onlineSupport.support_token,
      };
    }
    const response = await loginApi(data);
    if (response) {
      commit("_updateAuthenticate", response);
      dispatch("getUserSquadInfo");
      dispatch("getUserSquadMemberInfo");
      dispatch("checkOnlineSupportExist");
    }
  },

  async googleLogin({ commit, dispatch, getters }, data) {
    if (getters.onlineSupport) {
      data = {
        ...data,
        support_token: getters.onlineSupport.support_token,
      };
    }
    const response = await googleLoginApi(
      data,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    if (response) {
      commit("_updateAuthenticate", response);
      dispatch("getUserSquadInfo");
      dispatch("getUserSquadMemberInfo");
      dispatch("checkOnlineSupportExist");
    }
  },

  async logout({ commit, dispatch }) {
    commit("_logout");
    dispatch("emptySquad", null, { root: true });
    dispatch("removeSupport");
  },

  async checkOnlineSupportExist({ dispatch }) {
    const response = await getSupportExistApi();
    if (response) {
      dispatch("setOnlineSupportDetail", response.data);
    }
  },

  async changeTheme({ commit, getters }, data) {
    if (getters.isAuthenticated) {
      const response = await updateProfileThemeApi(data);
    }
    commit("_changeTheme", data);
  },

  async getProfile({ commit, getters }) {
    if (!getters.isAuthenticated) return;
    const response = await getProfileApi();
    if (response) {
      commit("_updateUserInfo", response);
    }
  },

  async UpdateUserNotificationsCount({ commit, getters }) {
    if (getters.isAuthenticated) {
      const response = await getNotificationsCountApi(null, false);
      if (response) {
        commit("_setNotificationCount", response.data.count);
      }
    }
  },

  async UpdateUserUnreadNotifications({ commit, getters }) {
    if (getters.isAuthenticated) {
      const response = await getNotificationsApi(null, false, 1, 10);
      if (response) {
        commit("_UpdateUserUnreadNotifications", response.data);
      }
    }
  },

  async setDefaultDeposit({ commit, getters }, data) {
    if (getters.isAuthenticated) {
      commit("_setDefaultDeposit", data);
    }
  },

  async clearDefaultDeposit({ commit, getters }) {
    if (getters.isAuthenticated) {
      commit("_clearDefaultDeposit");
    }
  },

  async setEmailVerificationTime({ commit, getters }, data) {
    if (getters.isAuthenticated) {
      commit("_setEmailVerificationTime", data);
    }
  },

  async clearEmailVerificationTime({ commit, getters }) {
    if (getters.isAuthenticated) {
      commit("_clearEmailVerificationTime");
    }
  },

  async setHasNewNotification({ commit }, data) {
    commit("_setHasNewNotification", data);
  },

  async setOnlineSupportDetail({ commit }, data) {
    commit("_setOnlineSupportDetail", data);
  },

  async setSuggestionChatUsdedTime({ commit }, data) {
    commit("_setSuggestionChatUsdedTime", data);
  },

  async setOnlineSupportUnreadCount({ commit }, data) {
    commit("_setOnlineSupportUnreadCount", data);
  },

  async setonlineSupportHasAdmin({ commit }, data) {
    commit("_setonlineSupportHasAdmin", data);
  },

  async setAgreeToCookies({ commit }, data) {
    commit("_setAgreeToCookies", data);
  },

  async setOpenMatchCounts({ commit }, data) {
    commit("_setOpenMatchCounts", data);
  },

  async setRefferaledToken({ commit }, data) {
    commit("_setRefferaledToken", data);
  },

  async setReturnUrl({ commit }, data) {
    commit("_setReturnUrl", data);
  },
};

const mutations = {
  _updateUserInfo: (state, data) => {
    state.user = data.data;
  },
  _updateAuthenticate: async (state, data) => {
    state.user = data.data;
    state.token = data.data.token;
    state.theme = data.data.theme.toLowerCase();
    state.authToken = data.data?.upload_token;
    state.refferaledToken = null;
    afterLoginRediraction(data);
  },
  _logout: async (state) => {
    await afterLogoutRediraction();
    state.user = null;
    state.token = null;
  },
  _changeTheme: (state, data) => {
    state.theme = data.theme.toLowerCase();
  },
  _setNotificationCount: (state, data) => {
    state.notificationCount = data > 9 ? "+9" : data;
  },
  _setDefaultDeposit: (state, data) => {
    state.defaultDeposit = data;
  },
  _clearDefaultDeposit: (state, data) => {
    state.defaultDeposit = null;
  },
  _setEmailVerificationTime: (state, data) => {
    state.emailVerificationTime = data;
  },
  _clearEmailVerificationTime: (state) => {
    state.emailVerificationTime = null;
  },
  _setHasNewNotification: (state, data) => {
    state.hasNewNotification = data;
  },
  _setOnlineSupportDetail: (state, data) => {
    state.onlineSupport = data;
    state.onlineSupportHasAdmin = data?.has_supporter;
  },
  _setonlineSupportHasAdmin: (state, data) => {
    state.onlineSupportHasAdmin = data;
  },
  _setSuggestionChatUsdedTime: (state, data) => {
    state.suggestionChatUseded = data;
  },
  _setOnlineSupportUnreadCount: (state, data) => {
    state.onlineSupportUnreadCount = data;
  },
  _setAgreeToCookies: (state, data) => {
    state.agreeToUseCookies = data;
  },
  _setOpenMatchCounts: (state, data) => {
    state.openMatchCounts = data;
  },
  _UpdateUserUnreadNotifications: (state, data) => {
    state.unreadNotifications = data;
  },
  _setRefferaledToken: (state, data) => {
    state.refferaledToken = data;
  },
  _setReturnUrl: (state, data) => {
    state.returnUrl = data;
  },
};

const afterLoginRediraction = async (data) => {
  if (
    router.currentRoute.value.name == "tournament-details" ||
    router.currentRoute.value.meta.isPanel
  ) {
    return;
  }
  const personal_info_is_completed = data.data.personal_info_is_completed;
  await router?.push(
    { name: personal_info_is_completed ? "dashboard" : "find-match" },
    {
      replace: true,
    }
  );
};

const afterLogoutRediraction = async () => {
  await router?.push(
    { name: "home" },
    {
      replace: true,
    }
  );
};

export default {
  state,
  getters,
  actions,
  mutations,
};
