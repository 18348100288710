<template>
  <footer class="md:mt-[20px] bg-background-white-light dark:bg-background-white-dark py-[20px]">
    <div class="container">
      <div class="grid grid-cols-1 md:grid-cols-12 gap-[20px] items-start">

        <div class="max-sm:hidden md:col-span-4 lg:col-span-2 grid grid-cols-1 gap-[12px]">
          <h3 class="font-medium text-xl">Competitions</h3>
          <router-link :to="{
            name: 'Arena-1v1-game'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            1v1 Battles
          </router-link>
          <router-link :to="{
            name: 'Arena-1v1-game'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            Tournament
          </router-link>
          <router-link :to="{
            name: 'Arena-1v1-game'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            League Wars (Coming soon)
          </router-link>
          <router-link :to="{
            name: 'arena-squad-games'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            Clan Wars (Coming soon)
          </router-link>

        </div>
        <!--  -->
        <div class="max-sm:hidden md:col-span-4 lg:col-span-2 grid grid-cols-1 gap-[12px]">
          <h3 class="font-medium text-xl">Who are we?</h3>
          <router-link :to="{
            name: 'about-us'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            About us
          </router-link>
          <a href="https://www.linkedin.com/company/gameclude/jobs"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            Job openings
          </a>
          <router-link :to="{
            name: 'policy'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            Privacy policies</router-link>
        </div>
        <!--  -->
        <div class="max-sm:hidden md:col-span-4 lg:col-span-2 grid grid-cols-1 gap-[12px]">
          <h3 class="font-medium text-xl">Knowledge</h3>
          <router-link :to="{
            name: 'faq'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            FAQ
          </router-link>
          <router-link :to="{
            name: 'service-terms'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            Term of service
          </router-link>
          <router-link :to="{
            name: 'home',
            hash: '#news'
          }"
            class="text-text-secondary-light dark:text-text-secondary-dark transaction-all hover:text-tertiary-dark dark:hover:text-tertiary-light">
            News
          </router-link>
        </div>
        <!-- = -->

        <div class="sm:hidden flex flex-col gap-4">
          <Accordion :footerAccordion="true"
            class="!bg-background-main-light dark:!bg-background-main-dark border border-tertiary-light dark:border-tertiary-dark"
            name="Competitions">
            <div class="grid grid-cols-2 gap-5 p-[12px]">
              <router-link :to="{
                name: 'Arena-1v1-game'
              }" class="text-text-secondary-light dark:text-text-secondary-dark">
                1v1 Battles
              </router-link>
              <router-link :to="{
                name: 'Arena-1v1-game'
              }" class="text-text-secondary-light dark:text-text-secondary-dark">
                Tournament
              </router-link>
              <router-link :to="{
                name: 'Arena-1v1-game'
              }" class="text-text-secondary-light dark:text-text-secondary-dark">
                League Wars (Coming soon)
              </router-link>
              <router-link :to="{
                name: 'arena-squad-games'
              }" class="text-text-secondary-light dark:text-text-secondary-dark">
                Clan Wars (Coming soon)
              </router-link>
            </div>
          </Accordion>

          <Accordion :footerAccordion="true"
            class="!bg-background-main-light dark:!bg-background-main-dark border border-tertiary-light dark:border-tertiary-dark"
            name="Who are we?">
            <div class="grid grid-cols-2 gap-5 p-[12px]">
              <router-link :to="{
                name: 'about-us'
              }" class="text-text-secondary-light dark:text-text-secondary-dark">About us</router-link>
              <a href="https://www.linkedin.com/company/gameclude/jobs"
                class="text-text-secondary-light dark:text-text-secondary-dark">
                Job openings
              </a>
              <router-link :to="{
                name: 'policy'
              }" class="text-text-secondary-light dark:text-text-secondary-dark">
                Privacy policies
              </router-link>
            </div>
          </Accordion>

          <Accordion :footerAccordion="true"
            class="!bg-background-main-light dark:!bg-background-main-dark border border-tertiary-light dark:border-tertiary-dark"
            name="Knowledge">
            <div class="grid grid-cols-2 gap-5 p-[12px]">
              <router-link :to="{
                name: 'faq'
              }" class=" text-text-secondary-light dark:text-text-secondary-dark">
                FAQ
              </router-link>
              <router-link :to="{
                name: 'service-terms'
              }" class="text-text-secondary-light dark:text-text-secondary-dark">
                Term of service
              </router-link>
              <router-link :to="{
                name: 'home',
                hash: '#news'

              }" class="text-text-secondary-light dark:text-text-secondary-dark">
                News
              </router-link>
            </div>
          </Accordion>
        </div>


        <div class="md:col-span-8 lg:col-span-4">
          <div class="grid grid-cols-1 gap-[8px] items-start min-[420px]:max-w-[320px]">
            <!--            <div-->
            <!--              class="relative py-[12px] px-[12px] border-2 border-solid border-text-quaternary-light dark:border-text-quaternary-dark rounded">-->
            <!--              <span-->
            <!--                class="text-xs text-text-secondary-light dark:text-text-secondary-dark absolute top-0 translate-y-[-50%] left-[5px] block px-[3px] bg-background-white-light dark:bg-background-white-dark">Card-->
            <!--                Payments</span>-->
            <!--              &lt;!&ndash;  &ndash;&gt;-->
            <!--              <div class="flex items-center justify-around">-->
            <!--                <img src="@/assets/icon-svg/MasterCard.svg" class="h-[25px]" alt="x" />-->
            <!--                <img src="@/assets/icon-svg/visa.svg" class="h-[25px]" alt="x" />-->
            <!--                <img src="@/assets/icon-svg/PayPal.svg" class="h-[25px]" alt="x" />-->
            <!--              </div>-->
            <!--            </div>-->
            <div
              class="relative py-[12px] px-[8px] max-sm:w-full sm:w-fit text-center border-2 border-solid border-text-quaternary-light dark:border-text-quaternary-dark rounded">
              <span
                class="text-xs text-text-secondary-light dark:text-text-secondary-dark absolute top-0 translate-y-[-50%] left-[5px] block px-[3px] bg-background-white-light dark:bg-background-white-dark">Cryptocurrencies</span>
              <!--  -->
              <div class="flex items-center gap-x-2">
                <!-- <img src="@/assets/icon-svg/new-ethereum.svg" class="h-[25px]" alt="x" /> -->
                <img src="@/assets/icon-svg/new-litecoin.svg" class="h-[25px]" alt="x" />
                <!-- <img src="@/assets/icon-svg/new-bitcoin.svg" class="h-[25px]" alt="x" /> -->
                <span class="text-[14px]">USD Tether</span>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="max-w-[400px] mt-[20px] text-text-secondary-light dark:text-text-secondary-dark">
            <p class="mb-[12px]">
              © 2020-2024 GameClude LLC. All Rights Reserved.
            </p>
            <p class="text-[10px] leading-[180%]">
              Gameclude is not endorsed by, directly affiliated with, maintained or sponsored by Microsoft, Xbox, Sony,
              Playstation, Electronic Arts, Activision Blizzard, Warner Bros, Epic Games or any other game publisher
              and distributor. All content, games titles, trade names and/or trade dress, trademarks, artwork and
              associated imagery are trademarks and/or copyright material of their respective owners. Message us for any
              issues
            </p>
          </div>
          <!--  -->

          <div class="flex items-center justify-between min-[420px]:justify-start gap-[20px] mt-[20px] xl:hidden">
            <a v-for="Icon in socialMedia" target="_blank" :href="Icon.link" :key="Icon.link">
              <img :src="Icon.icon" />
            </a>
          </div>
        </div>
        <!--  -->
        <div class="md:col-span-4 lg:col-span-2 xl:flex xl:flex-col xl:justify-between h-full">
          <div class="flex items-center justify-between min-[420px]:justify-start gap-[20px] mt-[20px] max-xl:hidden">
            <a v-for="Icon in socialMedia" target="_blank" :href="Icon.link" :key="Icon.link"
              class="transition-all hover:opacity-70">
              <img :src="Icon.icon" />
            </a>
          </div>
          <div>
            <span class="text-text-secondary-light dark:text-text-secondary-dark block mb-[8px] max-sm:hidden">Need
              Help?</span>
            <PrimaryButton @click="showOnlineSupport" size="sm" class="max-[420px]:!w-full !px-8 suportChatBtn"
              span-class="suportChatBtn">
              Support
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Accordion from "@/components/Accordion/index.vue";
import { SOCIAL_MEDIA } from "@/helper/constant";
import { ref } from "vue";
import { useStore } from "vuex";
import PrimaryButton from "@/components/form-component/buttons/ButtonPrimary.vue";

export default {
  name: "FooterLayout",
  components: { Accordion, PrimaryButton },
  setup() {
    const store = useStore();
    const socialMedia = ref(SOCIAL_MEDIA);

    const showOnlineSupport = () => {
      store.dispatch('openChat', true);
    };

    return {
      socialMedia,
      showOnlineSupport
    }
  }
};
</script>
