// import Pusher from "pusher-js";
import Echo from "laravel-echo";

window.Pusher = require('pusher-js')

export default (app, options) => {
  // Pusher.logToConsole = true;

  // const pusher = require("pusher-js");

  app.provide(
    "$pusher",
    // , new pusher(apiKey, options)
    new Echo(options)
  );
};
