<template>
  <Teleport to="body">
  <VueFinalModal name="EmailIsNotVeriffiedModal" v-model="EmailIsNotVeriffiedModal" class="!z-[99999]">
    <ModalLayout modalName="EmailIsNotVeriffiedModal">
      <div class="grid gap-[20px]">
        <div class="flex flex-col sm:flex-row items-center justify-between">
          <p class="text-3xl font-bold text-text-white-dark dark:text-text-white-light">verify your email address</p>
        </div>
        <p class="text-text-white-dark dark:text-text-white-light">
          Before you can play, you have to verify your email for
          this game.
        </p>


        <div class="grid gap-[12px] sm:grid-cols-12">
          <ButtonPrimary size="md" class="sm:col-span-9" @click="redirectToDashboard">
            <div class="flex gap-1">
              <span>
                Redirect to dashboard
              </span>
            </div>
          </ButtonPrimary>
          <ButtonTertiary size="md" class="sm:col-span-3" @click=" $vfm.hide('EmailIsNotVeriffiedModal')">
            back
          </ButtonTertiary>
        </div>
      </div>
    </ModalLayout>
  </VueFinalModal>
  </Teleport>
</template>
<script>
import { ref } from "vue";
import ModalLayout from "@/components/modal/ModalLayout.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import { useRouter } from "vue-router";
import { $vfm } from "vue-final-modal";

export default {
  name: "EmailIsNotVeriffiedModal",
  components: {
    ModalLayout,
    ButtonPrimary,
    ButtonTertiary
  },
  setup() {
    const router = useRouter();
    const EmailIsNotVeriffiedModal = ref(null);


    const redirectToDashboard = () => {
      $vfm.hide('EmailIsNotVeriffiedModal')
      router.push({
        name: "dashboard"
      });
    };

    return {
      EmailIsNotVeriffiedModal,
      redirectToDashboard
    }
  }
}
</script>

