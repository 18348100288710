import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { useRouter } from "./router";
import "tw-elements";
import VueSplide from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css/core";
import store from "@/store";
import vueFinalModal from "vue-final-modal";
import Popper from "vue3-popper";
import Hotjar from "vue-hotjar";
import Pusher from "@/service/Pusher";
import moment from "moment-timezone";
import vue3GoogleLogin from "vue3-google-login";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/assets/toaster.scss";
import "v-calendar/style.css";
import "./registerServiceWorker";
import VueLazyload from "vue-lazyload";

const token = store.state.User?.token;

const app = createApp(App);

const router = useRouter();

app.use(router);
app.use(store);
app.use(VueSplide);
app.use(vueFinalModal());
app.use(VueLazyload);

if (process.env.VUE_APP_IS_PRODUCTION) {
  app.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
    isProduction: true,
  });
}
app.use(Pusher, {
  broadcaster: "pusher",
  key: process.env.VUE_APP_WS_APP_KEY,
  wsHost: process.env.VUE_APP_WS_HOST,
  authEndpoint: process.env.VUE_APP_WS_AUTH,
  encrypted: true,
  forceTLS: true,
  wsPort: process.env.VUE_APP_WS_PORT,
  wssPort: process.env.VUE_APP_WSS_PORT,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  cluster: "mt1",
  auth: {
    headers: {
      authorization: "Bearer " + token,
    },
  },
});

app.use(vue3GoogleLogin, {
  clientId:
    "781819401201-29f0hil8u1bvulh0ju7cc76noi3c9eti.apps.googleusercontent.com",
});

const toastOptions = {
  transition: "Vue-Toastification__bounce",
  newestOnTop: true,
  position: "bottom-left",
  timeout: 2702,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.5,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: false,
  rtl: false,
};
app.use(Toast, toastOptions);

app.component("Popper-tooltip", Popper);

app.directive("click-outside", {
  mounted(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

app.directive("observe-visibility", {
  mounted(el, binding, vnode) {
    el.scrollInside = function (event) {
      if (
        Math.ceil(event.target.offsetHeight + event.target.scrollTop) >=
        event.target.scrollHeight
      ) {
        binding.value(event, el);
      }
    };
    el.addEventListener("scroll", el.scrollInside);
  },
  unmounted(el) {
    document.body.removeEventListener("scroll", el.scrollInside);
  },
});

app.directive("observe-visibility-top", {
  mounted(el, binding, vnode) {
    el.scrollInside = function (event) {
      if (event.target.scrollTop == 0) {
        binding.value(event, el);
      }
    };
    el.addEventListener("scroll", el.scrollInside);
  },
  unmounted(el) {
    document.body.removeEventListener("scroll", el.scrollInside);
  },
});

app.directive("focus", {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    el.focus();
  },
});

app.provide("moment", moment);

app.mount("#app");
