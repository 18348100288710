<template>
  <div class="pb-[60px] lg:pb-0">
    <Header v-if="isAuthenticated" :showchat="showChat" />
    <PublicHeaderLayout v-else />
    <div class="container min-h-screen pt-[12px] lg:pt-[20px]">
      <div class="lg:grid grid-cols-12 xl:grid-cols-10 gap-[20px] items-start">
        <Aside class="col-span-3 xl:col-span-2" :user-notification-count="userNotificationCount" />
        <main class="flex-grow col-span-9 xl:col-span-8">
          <slot />
        </main>
      </div>
    </div>
    <Footer />

  </div>
</template>

<script>
import Header from "./header/HeaderLayout.vue";
import PublicHeaderLayout from '@/components/layout/public/header/HeaderLayout.vue'
import Footer from "../Footer.vue";
import Aside from "./aside/asidePanel.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "PanelLayout",
  components: {
    Header,
    Footer,
    Aside,
    PublicHeaderLayout,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const userNotificationCount = computed(() => store.state.User.notificationCount);
    const showChat = computed(() => route.meta?.showChat ?? false);


    return {
      isAuthenticated,
      userNotificationCount,
      showChat,
    }

  }
};
</script>
