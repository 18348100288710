<template>
  <span class="flex gap-2 items-center justify-center">
    <ClockAnimation v-if="showAnimation && timeToSeconds" :timer="timeToSeconds" />
    <span>
      {{ countDown }}
    </span>
  </span>
</template>
<script>
import { ref, onMounted, watch, onUnmounted, inject } from "vue";
import { countDownByHMS, countDownByDHMS } from "@/helper";
import ClockAnimation from "./ClockAnimation.vue";
import { getUtcTime } from "@/helper";

export default {
  name: "CountDown",
  components: {
    ClockAnimation
  },
  props: {
    from: {
      default: Date.now(),
    },
    underMinutesAmount: {
      type: Number,
      required: false,
    },
    showFormat: {
      default: "HMS"
    },
    showAnimation: {
      default: false
    },
    showIfUnderHours: {
      default: 0
    },

  },
  emits: [
    "isUnderLimitMinutes",
    "expired",
    "countDownStarted",
    "realTimeShowExpireAtStarted"
  ],
  setup(props, { emit }) {
    const moment = inject("moment");
    const countDown = ref(null);
    const isUnderMinutes = ref(false);
    const countDownInterval = ref(null);
    const isExpired = ref(false);
    const timeToSeconds = ref(0);
    const countDownStarted = ref(false);

    onMounted(() => {
      calculateCountDown();
    });

    onUnmounted(() => {
      clearInterval(countDownInterval.value);
    });

    watch(isUnderMinutes, () => {
      emit("isUnderLimitMinutes", {
        isUnderLimitMinutes: isUnderMinutes.value,
      });
    });
    watch(isExpired, () => {
      emit("expired", isExpired.value);
    });



    const calculateCountDown = async () => {
      if (props.showAnimation) {
        timeToSeconds.value = moment(props.from).diff(getUtcTime().getTime(), "seconds");
      }
      countDownInterval.value = setInterval(() => {
        var to = getUtcTime().getTime();
        if (props.showFormat == 'DHMS') {
          var amount = countDownByDHMS(new Date(props.from).getTime(), to);
        } else {
          var amount = countDownByHMS(new Date(props.from).getTime(), to);
        }

        if (amount &&
          amount?.split(":")[0] == "00" &&
          props.showFormat == 'MS'
        ) {
          const splitAmount = amount?.split(":");
          checkShowUnderHours(amount, `${splitAmount[1]}:${splitAmount[2]}`)
        } else {
          checkShowUnderHours(amount, amount);
        }
        if (
          amount &&
          props.underMinutesAmount &&
          amount?.split(":")[0] == "00" &&
          amount?.split(":")[1] <= props.underMinutesAmount
        ) {
          isUnderMinutes.value = true;
        } else {
          isUnderMinutes.value = false;
        }
        if (amount == 0) {
          isExpired.value = true;
          countDown.value = "00:00";
          isUnderMinutes.value = true;
          clearInterval(countDownInterval.value);
        }
      }, 1000);
    };

    const checkShowUnderHours = (amount, amountPrettified) => {
      if (props.showIfUnderHours) {
        if (amount?.split(":")[0] < props.showIfUnderHours) {
          countDown.value = amountPrettified;
          emitMatchStarted();
          emit("realTimeShowExpireAtStarted", true);
        }
      } else {
        countDown.value = amountPrettified;
        emitMatchStarted();
      }
    };

    const emitMatchStarted = () => {
      if (!countDownStarted.value) {
        countDownStarted.value = true;
        emit("countDownStarted", true);
      }
    };

    return {
      countDown,
      timeToSeconds,

    };
  },
};
</script>
