<template>
  <nav>
    <ul class="bg-background-main-light dark:bg-background-main-dark rounded px-[10px] text-lg">
      <li v-for="Item in menuItem" :key="Item.name"
        class="border-b-[1px] border-solid border-text-quaternary-light dark:border-text-quaternary-dark last:border-none">
        <span v-if="Item.child">
          <button class="group py-[12px] w-full flex items-center justify-between" type="button" data-te-collapse-init
            data-te-target="#collapseOne" aria-controls="collapseOne" aria-expanded="false" data-te-collapse-collapsed="">
            <span>{{ Item.name }}</span>
            <i class="icon-Down-Stroke transition-all group-[[data-te-collapse-collapsed]]:rotate-0 rotate-[-180deg]"></i>
          </button>

          <ul id="collapseOne" data-te-collapse-item aria-labelledby="headingOne" class="hidden">
            <li :key="I.name" v-for="I in Item.child"
              class="bg-background-white-light dark:bg-background-white-dark rounded mb-[8px]">
              <router-link :to="I.link" @click="$emit('closeSidebar')" class="py-[8px] px-[8px] block">
                <span>{{ I.name }}</span>
              </router-link>
            </li>
          </ul>
        </span>
        <span v-else>
          <router-link :to="Item.link" @click="$emit('closeSidebar')" class="py-[10px] block">
            <span>{{ Item.name }}</span>
          </router-link>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MobileNav",

  props: ["menuItem"],
};
</script>
