import { filled } from "@/helper";

export function useHead(tags) {
  appendTitle(
    filled(tags?.title)
      ? tags?.title
      : "Wagering on Online Video Games - Gameclude"
  );

  appendMetaName(
    "description",
    filled(tags?.description)
      ? tags?.description
      : "Immerse yourself in the world of online gaming wagering by participating in matches, tournaments, and events to claim victory and real money in games like FC 24,  madden, nba 2k, call of duty & more! - Gameclude"
  );
  appendMetaProperty(
    "og:description",
    filled(tags?.description)
      ? tags?.description
      : "Immerse yourself in the world of online gaming wagering by participating in matches, tournaments, and events to claim victory and real money in games like FC 24,  madden, nba 2k, call of duty & more! - Gameclude"
  );

  appendMetaName(
    "keywords",
    filled(tags?.keywords)
      ? tags?.keywords
      : "wagering, online video games, cash prizes, gaming champion, tournaments, events, victory, cash rewards, competitive gaming, online wagering game"
  );
  
  appendMetaProperty("og:url", tags.href ?? window.location.href);
  appendLink("canonical", tags.href ?? window.location.href);
}

const appendTitle = (title) => {
  window.document.title = title;
  appendMetaProperty("og:title", title);
  appendMetaProperty("og:site_name", title);
};

const appendMetaName = (name, value) => {
  let metaName = document.querySelector(`meta[name="${name}"]`);
  let metaNotExist = false;

  if (!metaName) {
    metaName = document.createElement("meta");
    metaNotExist = true;
  }
  metaName.setAttribute("name", name);
  metaName.setAttribute("content", value);
  if (metaNotExist) {
    document.head.appendChild(metaName);
  }
};
const appendMetaProperty = (name, value) => {
  let metaName = document.querySelector(`meta[property="${name}"]`);
  let metaNotExist = false;

  if (!metaName) {
    metaName = document.createElement("meta");
    metaNotExist = true;
  }

  metaName.setAttribute("property", name);
  metaName.setAttribute("content", value);
  if (metaNotExist) {
    document.head.appendChild(metaName);
  }
};
const appendLink = (name, value) => {
  let metaName = document.querySelector(`link[rel="${name}"]`);
  let metaNotExist = false;

  if (!metaName) {
    metaName = document.createElement("meta");
    metaNotExist = true;
  }

  metaName.setAttribute("rel", name);
  metaName.setAttribute("href", value);
  if (metaNotExist) {
    document.head.appendChild(metaName);
  }
};
