import PanelLayout from "@/components/layout/panel/PanelLayout.vue";
import PublicLayout from "@/components/layout/public/PublicLayout.vue";

const DashboardView = async () => import("@/views/panel/dashboard/index.vue");
const FindMatchView = async () => import("@/views/panel/find-match/index.vue");
const FindMatchGameView = async () =>
  import("@/views/panel/find-match/game.vue");
const SquadPanel = async () => import("@/views/panel/squads/SquadPanel.vue");
const SquadsTeamup = async () => import("@/views/panel/squads/Teamup.vue");
const NewSquads = async () => import("@/views/panel/squads/New.vue");
const SquadsMember = async () => import("@/views/panel/squads/Members.vue");
const SquadsTrophies = async () => import("@/views/panel/squads/Trophies.vue");
const SquadsRanking = async () => import("@/views/panel/squads/Ranking");
const SquadMatchHistory = async () =>
  import("@/views/panel/squads/MatchHistory.vue");
const SquadSetting = async () =>
  import("@/views/panel/squads/SquadSetting.vue");
const OpenMatch = async () => import("@/views/panel/open-match");
const BattleDetail = async () =>
  import("@/views/panel/open-match/battle/BattleDetail.vue");
const ClanWarDetail = async () =>
  import("@/views/panel/open-match/clanWar/ClanWarDetail.vue");
const TournamentDetail = async () =>
  import("@/views/panel/open-match/tournament/TournamentDetail.vue");
const Chat = async () => import("@/views/panel/chat");
const Trophies = async () => import("@/views/panel/Trophies");
const Friends = async () => import("@/views/panel/Friends");
const AccountSetting = async () => import("@/views/panel/AccountSetting");
const Wallet = async () => import("@/views/panel/AccountSetting/Wallet.vue");
const GameSetting = async () =>
  import("@/views/panel/AccountSetting/GameSetting.vue");
const Referrals = async () =>
  import("@/views/panel/AccountSetting/Referrals.vue");
const PersonalInfo = async () =>
  import("@/views/panel/AccountSetting/PersonalInfo.vue");
const Notification = async () => import("@/views/panel/Notification");
const MatchHistory = async () => import("@/views/panel/MatchHistory");
const TournamentBracketsDetails = async () =>
  import("@/views/panel/open-match/tournament/TournamentBracketDetails.vue");
// const LeagueWarTable = async () => import("@/views/public/LeagueWarTable");

export default [
  {
    path: "/panel",
    name: "panel",
    redirect: { path: "/panel/dashboard" },
    meta: {
      layout: PanelLayout,
      requiresAuth: true,
      isPanel: true,
    },
    children: [
      {
        path: "dashboard",
        component: DashboardView,
        name: "dashboard",
        meta: {
          SectionName: "dashboard",
          isPanel: true,
          requiresAuth: true,
          useStaticMetaData: true,
        },
      },
    ],
  },
  // find match
  {
    path: "/panel/find-match",
    name: "find-match",
    component: FindMatchView,
    meta: {
      layout: PanelLayout,
      requiresAuth: false,
      SectionName: "find-match",
      showChat: true,
      isPanel: true,
      seoName: "findMatch",
      useStaticMetaData: true,
    },
  },
  {
    path: "/panel/find-match/:game/:service/matches",
    name: "game-matches",
    component: FindMatchGameView,
    meta: {
      SectionName: "find-match",
      layout: PanelLayout,
      requiresAuth: false,
      showChat: true,
      isPanel: true,
      seoName: "gameMatches",
      useStaticMetaData: false,
    },
  },
  // squad panel
  {
    path: "/panel/squads",
    name: "squads",
    component: SquadPanel,
    meta: {
      layout: PanelLayout,
      SectionName: "squads",
      requiresAuth: false,
      isPanel: true,
    },
    redirect: { name: "squads-teamup" },
    children: [
      {
        path: "teamup",
        name: "squads-teamup",
        component: SquadsTeamup,
        meta: {
          isPanel: true,
          requiresAuth: true,
          tabName: "teamup",
          title: "Teamup",
        },
      },
      {
        path: "members",
        name: "squads-members",
        component: SquadsMember,
        meta: {
          isPanel: true,
          requiresAuth: true,
          tabName: "members",
          title: "Members",
        },
      },

      {
        path: "trophies",
        name: "squads-trophies",
        component: SquadsTrophies,
        meta: {
          isPanel: true,
          requiresAuth: true,
          tabName: "trophies",
          title: "Squad trophies",
        },
      },
      {
        path: "ranking",
        name: "squads-ranking",
        component: SquadsRanking,
        meta: {
          isPanel: true,
          requiresAuth: true,
          tabName: "ranking",
          title: "Squad - rankings",
        },
      },
      {
        path: "match-history",
        name: "squads-match-history",
        component: SquadMatchHistory,
        meta: {
          isPanel: true,
          requiresAuth: true,
          tabName: "Match history",
          title: "Squad - match histories",
        },
      },
      {
        path: "squad-setting",
        name: "squads-setting",
        component: SquadSetting,
        meta: {
          isPanel: true,
          requiresAuth: true,
          tabName: "Squad Setting",
          title: "Squad - settings",
        },
      },
      {
        path: "new",
        name: "squads-archives",
        component: NewSquads,
        meta: {
          isPanel: true,
          requiresAuth: false,
          SectionName: "squads-panel",
          title: "Squads",
        },
      },
    ],
  },
  // open match
  {
    path: "/panel/open-match",
    name: "open-matches",
    component: OpenMatch,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: true,
      SectionName: "open-matches",
      useStaticMetaData: true,
    },
  },
  {
    path: "/panel/open-match/1v1/:id",
    name: "open-match-1v1",
    component: BattleDetail,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: false,
      SectionName: "open-matches",
      seoName: "openMatch1v1",
      useStaticMetaData: false,
    },
  },
  {
    path: "/panel/open-match/clan-war/:id",
    name: "open-match-clan-war",
    component: ClanWarDetail,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: false,
      SectionName: "open-matches",
      title: "Clan war wager match",
    },
  },
  {
    path: "/panel/open-match/tournament/:id",
    name: "open-match-tournament",
    component: TournamentDetail,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: false,
      SectionName: "open-matches",
      seoName: "openMatchTournament",
      useStaticMetaData: false,
    },
  },

  // tournament-tables details
  {
    path: "/tournament/:id/details",
    name: "tournament-details",
    component: TournamentBracketsDetails,
    meta: {
      isPanel: false,
      layout: PublicLayout,
      requiresAuth: false,
      seoName: "tournamentDetail",
    },
  },

  //======= chat
  {
    path: "/panel/chats",
    name: "chats",
    component: Chat,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: true,
      SectionName: "chats",
      useStaticMetaData: true,
    },
  },
  //======= Trophies
  {
    path: "/panel/trophies",
    name: "trophies",
    component: Trophies,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: true,
      SectionName: "trophies",
      useStaticMetaData: true,
    },
  },
  //======= Friends
  {
    path: "/panel/followings",
    name: "friends",
    component: Friends,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: true,
      SectionName: "friends",
      useStaticMetaData: true,
    },
  },
  //======= Account Setting
  {
    path: "/panel/account-setting",
    name: "account-setting",
    component: AccountSetting,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: true,
      SectionName: "account-setting",
    },
    redirect: { name: "wallet" },
    children: [
      {
        path: "wallet",
        name: "wallet",
        component: Wallet,
        meta: {
          isPanel: true,
          tabName: "wallet",
          useStaticMetaData: true,
        },
      },
      {
        path: "game-setting",
        name: "game-setting",
        component: GameSetting,
        meta: {
          isPanel: true,
          tabName: "game-setting",
          useStaticMetaData: true,
        },
      },
      {
        path: "referrals",
        name: "referrals",
        component: Referrals,
        meta: {
          isPanel: true,
          tabName: "referrals",
          useStaticMetaData: true,
        },
      },
      {
        path: "personal-info",
        name: "personal-info",
        component: PersonalInfo,
        meta: {
          isPanel: true,
          tabName: "personal-info",
          useStaticMetaData: true,
        },
      },
    ],
  },
  //======= Notification
  {
    path: "/panel/notification",
    name: "notification",
    component: Notification,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: true,
      SectionName: "notification",
      useStaticMetaData: true,
    },
  },
  //======= match history
  {
    path: "/panel/match-history",
    name: "match-history",
    component: MatchHistory,
    meta: {
      isPanel: true,
      layout: PanelLayout,
      requiresAuth: true,
      SectionName: "match-history",
      useStaticMetaData: true,
    },
  },

  // Tournament Brackets

  // {
  //   path: "/LeagueWarTable",
  //   name: "LeagueWarTable",
  //   component: LeagueWarTable,
  //   meta: {
  //     isPanel: true,
  //     layout: PublicLayout,
  //     requiresAuth: false,
  //   },
  // },
];
