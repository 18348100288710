<template>
  <Teleport to="body">
    <VueFinalModal name="GamerTagModal" v-model="GamerTagModal">
      <ModalLayout modalName="GamerTagModal" :has-btn-on-bottom="true"
        class="!z-[18] text-text-white-dark dark:text-text-white-light">
        <template #title>
          <div class="flex flex-col sm:flex-row items-center justify-between">
            <p class="text-3xl font-bold">Enter your Gamer Tags</p>
          </div>
        </template>
        <div class="grid gap-[20px]">
          <p>
            Before you can play, you have to enter your suitable gamertags for
            this game.
          </p>
          <form @submit.prevent="storeUserTag" class="grid gap-[12px]">
            <InputIcon v-for="service in services" :key="service.id"
              :imgIcon="themeIsDark ? service.dark_icon : service.light_icon" :placeholder="service.name"
              :is-gamer-tag="true" v-model="userTag" />
            <input type="submit" hidden />
          </form>

          <div
            class="flex gap-[12px] justify-end p-[12px] fixed bottom-0 inset-x-0 bg-background-white-light rounded-b dark:bg-background-white-dark border-t border-tertiary-light dark:border-tertiary-dark">
            <ButtonTertiary size="sm" @click=" $vfm.hide('GamerTagModal')">back</ButtonTertiary>
            <ButtonPrimary type="buttons" size="sm" @click="storeUserTag">
              <div class="flex gap-1 relative">
                <span v-if="btnLoading" class="absolute left-[50%] -translate-x-[50%]">
                  <SpinLoading />
                </span>
                <span>
                  Submit
                </span>
              </div>
            </ButtonPrimary>
          </div>
        </div>
      </ModalLayout>
    </VueFinalModal>
  </Teleport>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import InputIcon from "@/components/form-component/inputIcon.vue";
import ButtonTertiary from "@/components/form-component/buttons/ButtonTertiary.vue";
import ModalLayout from "@/components/modal/ModalLayout.vue";
import ButtonPrimary from "@/components/form-component/buttons/ButtonPrimary.vue";
import { getServicesApi } from "@/service/api/ServiceApi";
import { storeUserTagApi } from "@/service/api/UserTagApi";
import { $vfm } from "vue-final-modal";
import SpinLoading from "@/components/layout/common/utility/SpinLoading.vue";
import { useStore } from "vuex";

export default {
  name: "GamerTagModal",
  components: {
    InputIcon,
    ButtonTertiary,
    ModalLayout,
    ButtonPrimary,
    SpinLoading
  },
  emits: [
    'gamerTagStored',
    'gamerTagClosed'
  ],
  setup(props, { emit }) {
    const store = useStore();
    const GamerTagModal = ref(null);
    const userTag = ref(null);
    const services = ref([]);
    const serviceIds = computed(() => store.state.Common.serviceIdForUserTag);
    const btnLoading = ref(false);
    const themeIsDark = computed(() => store.getters.themeIsDark)

    onMounted(() => {
      if (serviceIds.value) {
        getServices();
      }
    });

    watch(serviceIds, () => {
      if (serviceIds.value) {
        getServices();
      }
    });

    watch(GamerTagModal, () => {
      if (!GamerTagModal.value) {
        emit('gamerTagClosed')
      }
    });

    const getServices = async () => {
      const response = await getServicesApi([serviceIds.value]);
      services.value = response.data;
    };

    const storeUserTag = async () => {
      btnLoading.value = true;

      const response = await storeUserTagApi({
        tags: [{
          service_id: serviceIds.value,
          tag: userTag.value
        }]
      });
      if (response) {
        store.dispatch('pushUserTags', ...services.value)
        $vfm.hide('GamerTagModal');
        store.dispatch('setServiceIdForUserTag', null)

      }
      btnLoading.value = false;
    };



    return {
      themeIsDark,
      GamerTagModal,
      services,
      userTag,
      btnLoading,
      storeUserTag,
    }
  }
}
</script>
