<template>
  <div v-if="timer" class="base-timer">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed"
          cx="50"
          cy="50"
          r="45"
        ></circle>
        <path
          id="base-timer-path-remaining"
          :stroke-dasharray="circleDasharray"
          :class="{
            '!stroke-tertiary-light dark:!stroke-tertiary-dark': timeLeft == 0,
          }"
          class="base-timer__path-remaining stroke-primary"
          d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
        ></path>
      </g>
    </svg>
    <!-- <span id="base-timer-label" class="base-timer__label">
    ${formatTime(timeLeft)}
  </span> -->
  </div>
</template>
<script>
import { onMounted, reactive, ref, watch , inject } from "vue";

export default {
  name: "ClockAnimation",
  props: {
    timer: {
      type: Number,
    },
  },
  setup(props) {
    const TIME_LIMIT = ref(props.timer);
    const timeLeft = ref(20);
    const timePassed = ref(0);
    // const circleDasharray = ref(283);
    const circleDasharray = ref(`0 283`);

    const step = inject("step")

    let getDashSize =  283 / TIME_LIMIT.value

    let setDashSize =  getDashSize
    let Second = 0

    const CircleInterval = setInterval(()=>{
      if(Second == TIME_LIMIT.value){
        step.value = 1
        return clearInterval(CircleInterval)
      }
      setDashSize = setDashSize + getDashSize
      circleDasharray.value = `${setDashSize} 283`
      Second++
    },1000 )



    // Update the dasharray value as time passes, starting with 283




    return {
      circleDasharray,
      timeLeft,
    };
  },
};
</script>
<style lang="scss">
.base-timer {
  position: relative;
  height: 18px;
  width: 18px;
}

/* Removes SVG styling that would hide the time label */
.base-timer__circle {
  fill: none;
  stroke: none;
}

/* The SVG path that displays the timer's progress */
.base-timer__path-elapsed {
  stroke-width: 11px;
  @apply stroke-tertiary-light dark:stroke-tertiary-dark;
}

.base-timer__path-remaining {
  /* Just as thick as the original ring */
  stroke-width: 11px;

  /* Rounds the line endings to create a seamless circle */
  stroke-linecap: round;

  /* Makes sure the animation starts at the top of the circle */
  transform: rotate(90deg);
  transform-origin: center;

  /* One second aligns with the speed of the countdown timer */
  transition: 1s linear all;

  /* Allows the ring to change color when the color value updates */
  @apply stroke-primary;
}

.base-timer__svg {
  /* Flips the svg and makes the animation to move left-to-right */
  transform: scaleX(-1);
}
</style>
