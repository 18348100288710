<template>
  <Vue3Lottie :animationData="Animation" />
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import Animation from "./notFound.json";

export default {
  name: "HomeAnimationSvg",
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      Animation,
    };
  },
};
</script>
