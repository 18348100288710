import { getUserSquadMemberApi } from "@/service/api/SquadMemberApi";
import { getUserSquadDetailApi } from "@/service/api/SquadApi";
import store from "@/store";
import _ from "lodash";

const state = {
  userSquad: null,
  userSquadRole: null,
  teamupCount: 0,
};

const getters = {
  userIsLeader: (state) =>
    state.userSquadRole !== null && state.userSquadRole?.role == "LEADER",
  userHasSquad: (state) => state.userSquadRole !== null,
};

const actions = {
  async getUserSquadMemberInfo({ commit }) {
    if (store.getters.isAuthenticated) {
      const response = await getUserSquadMemberApi();
      commit("_getUserSquadMemberInfo", response?.data);
    }
  },
  async getUserSquadInfo({ commit }) {
    if (store.getters.isAuthenticated) {
      const response = await getUserSquadDetailApi();
      if(response){
        commit("_getUserSquadInfo", response?.data);
      }
    }
  },
  async emptySquad({ commit }) {
    commit("_emptySquad");
  },
  async setTeamupCount({ commit }, data) {
    commit("_setTeamupCount", data);
  },
};

const mutations = {
  _getUserSquadMemberInfo: (state, data) => {
    state.userSquadRole = data ?? null;
  },
  _getUserSquadInfo: (state, data) => {
    state.userSquad = data ?? null;
  },
  _emptySquad: (state) => {
    state.userSquad = null;
    state.userSquadRole = null;
  },
  _setTeamupCount: (state, data) => {
    state.teamupCount = data ?? 0;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
