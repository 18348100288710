import { authApi, api } from "@/service/Config";

const prefix = "/squads";

export const getUserSquadDetailApi = async () => {
  return authApi.get(`${prefix}/details`).then((res) => res?.data);
};

export const lastAchievementsApi = async (data) => {
  return authApi
    .get(`${prefix}/last-achievements`, data)
    .then((res) => res.data);
};

export const joinSquadApi = async (data) => {
  return authApi.post(`${prefix}/join`, data).then((res) => res.data);
};

export const acceptJoinSquadApi = async (joinSquadId) => {
  return authApi
    .put(`${prefix}/join/${joinSquadId}/acept`)
    .then((res) => res.data);
};

export const denyJoinSquadApi = async (joinSquadId) => {
  return authApi
    .put(`${prefix}/join/${joinSquadId}/deny`)
    .then((res) => res.data);
};

//sort_by  ASC , DESC ,YOUR_PLACE
export const getSquadsApi = async (
  page = 1,
  search = "",
  sort_by = "ASC",
  continents = [],
  games = []
) => {
  return authApi
    .get(`${prefix}`, {
      params: {
        page,
        search,
        sort_by,
        continents,
        games,
      },
    })
    .then((res) => res.data);
};

export const storeSquadApi = async (data) => {
  return authApi
    .post(`${prefix}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};

export const getSquadDetailApi = async (squadId) => {
  return api.get(`${prefix}/${squadId}`).then((res) => res.data);
};

export const updateSquadNameApi = async (data) => {
  return authApi.patch(`${prefix}/name`, data).then((res) => res.data);
};

export const updateSquadSloganApi = async (data) => {
  return authApi.patch(`${prefix}/slogan`, data).then((res) => res.data);
};

export const updateSquadContinentApi = async (data) => {
  return authApi.patch(`${prefix}/continent`, data).then((res) => res.data);
};

export const updateSquadJoinSettingApi = async (data) => {
  return authApi.patch(`${prefix}/join-setting`, data).then((res) => res.data);
};

export const updateSquadDescriptionApi = async (data) => {
  return authApi.patch(`${prefix}/description`, data).then((res) => res.data);
};

export const updateSquadAvatarApi = async (data) => {
  return authApi
    .post(`${prefix}/avatar`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};

export const inviteApi = async (data) => {
  return authApi.post(`${prefix}/invite`, data).then((res) => res.data);
};

export const acceptInviteApi = async (data) => {
  return authApi.post(`${prefix}/invite/accept`, data).then((res) => res.data);
};

export const denyInviteApi = async (data) => {
  return authApi.post(`${prefix}/invite/deny`, data).then((res) => res.data);
};

export const getSquadGameRecordsApi = async (squadId) => {
  return authApi
    .get(`${prefix}/${squadId}/game-records`)
    .then((res) => res.data);
};

export const getSquadGameTrophiesApi = async (squadId) => {
  return authApi
    .get(`${prefix}/${squadId}/game-trophies`)
    .then((res) => res.data);
};

export const getSquadCountTrophiesApi = async (squadId, gameId) => {
  return authApi
    .get(`${prefix}/${squadId}/trophies`, {
      params: {
        game_id: gameId,
      },
    })
    .then((res) => res.data);
};
