import { api, authApi } from "@/service/Config";

const prefix = "/squads/members";

export const getUserSquadMemberApi = async () => {
  return authApi.post(`${prefix}/user`).then((res) => res.data);
};

export const getMembersApi = async (
  page = 1,
  line = "",
  games = [],
  search = ""
) => {
  return authApi
    .get(`${prefix}`, {
      params: {
        page,
        search,
        line,
        games,
      },
    })
    .then((res) => res.data);
};

export const leaveApi = async (data) => {
  return authApi.post(`${prefix}/leave`, data).then((res) => res.data);
};

export const kickOutApi = async (data) => {
  return authApi.post(`${prefix}/kick-out`, data).then((res) => res.data);
};

export const getPublicSquadMembersApi = async (squadId, search = "") => {
  return api.get(`squads/${squadId}/members`).then((res) => res.data);
};
