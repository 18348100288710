import { authApi, api } from "@/service/Config";

const prefix = "/chat";

export const createChatApi = async (member_id) => {
  return authApi
    .post(`${prefix}/create`, {
      member_id,
    })
    .then((res) => res.data);
};

export const getRoomsApi = async (page = 1) => {
  return authApi
    .get(`${prefix}/rooms`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const getMessagesApi = async (chatrRoomId, page = 1) => {
  return authApi
    .get(`${prefix}/messages/${chatrRoomId}`, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const getRoomsUnreadMessagesApi = async () => {
  return authApi.get(`${prefix}/unread-messages`).then((res) => res.data);
};

export const getRoomApi = async (gameServiceId) => {
  return authApi
    .get(`${prefix}/get-find-match-room`, {
      params: {
        id: gameServiceId,
      },
    })
    .then((res) => res.data);
};

export const subscribeApi = async (uuid) => {
  return authApi
    .post(`${prefix}/join`, {
      uuid,
    })
    .then((res) => res.data);
};

export const unubscribeApi = async (uuid) => {
  return authApi
    .post(`${prefix}/left`, {
      uuid,
    })
    .then((res) => res.data);
};

//type:find_match , open_match
export const getSuggestionMessagesApi = async (game_id, type) => {
  return authApi
    .get(`${prefix}/default-texts`, {
      params: {
        game_id,
        type,
      },
    })
    .then((res) => res.data);
};

export const logSuggestionMessagesApi = async (id) => {
  return authApi
    .post(`${prefix}/visit-default-text`, {
      id,
    })
    .then((res) => res.data);
};

export const supportApi = async (support_token) => {
  return authApi
    .post(`/support`, {
      support_token,
    })
    .then((res) => res.data);
};

export const getSupportMessagesApi = async (uuid, page = 1) => {
  return authApi
    .get(`/support/messages/${uuid} `, {
      params: {
        page,
      },
    })
    .then((res) => res.data);
};

export const getSupportExistApi = async () => {
  return authApi
    .get(`/support/exist`)
    .then((res) => res.data);
};
